import React from 'react'
import "../../CSS/Setting/SettingTopNavBar.css"
import { NavLink } from 'react-router-dom'
const SettingTopNavBar = () => {
    return (
        <>
            <nav className='settingNavbar'>
                <ul>
                    {localStorage.getItem("hospitalId") !== "NA" ? "" :

                        <li>
                            <NavLink to={"/user/settings/"} className={({ isActive }) => (isActive ? "topActiveNavbar" : "topInActiveNavbar")}>
                                White Label
                            </NavLink>
                        </li>
                    }
                    {
                        localStorage.getItem("hospitalId") !== "NA" ?
                            <>

                                <li>
                                    <NavLink to={"/user/settings/adminprofile"} className={({ isActive }) => (isActive ? "topActiveNavbar" : "topInActiveNavbar")}>
                                        Profile
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/user/settings/accountslist"} className={({ isActive }) => (isActive ? "topActiveNavbar" : "topInActiveNavbar")}>
                                        Accounts
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/user/settings/profilecreation"} className={({ isActive }) => (isActive ? "topActiveNavbar" : "topInActiveNavbar")}>
                                        Create Accounts
                                    </NavLink>
                                </li>
                                <li>
                                    {/* <NavLink to={"/t"} className={({ isActive }) => (isActive ? "topActiveNavbar" : "topInActiveNavbar")}>
                            Payroll
                        </NavLink> */}
                                </li> </> : ""
                    }

                </ul>
            </nav>


        </>
    )
}

export default SettingTopNavBar