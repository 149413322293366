import React, { useEffect, useState } from 'react'
import "../../../CSS/Profiles/Doctor/AppointmentListTable.css"

import TopBar from '../../Bars/TopBar'
import TopNavBar from './TopNavBar'



import Calendar from 'react-calendar'
import DoctorSideBar from '../../Bars/DoctorSideBar'
import AllAppointmentsListTable from './AllAppointmentsListTable'


const ViewAllAppointments = () => {
    const [ListPreview, SetListPreview] = useState({
        allList: true,
        singleList: false
    })
    const [filterDate, setFilterDate] = useState("")

    const PatientListPreview = () => {
        if (ListPreview.allList === true) {
            return (<AllAppointmentsListTable filterDate={filterDate} />)
        } else {
            return "Test"
        }
    }
    useEffect(() => {
        PatientListPreview()
    })
    return (
        <>

            <section className="ParentRapper">
                <TopBar pageName="Doctor" />

                <div className="pageDetails">
                    <DoctorSideBar />
                    <div className="page">
                        <section className="docProfileMainSection">

                            <TopNavBar />
                            <div className="PatientListAndDateFilter">
                                <div className="doctorprofilePatientList">
                                    <div className="flexRow justify-between">

                                        <h3>All Appointments</h3>
                                        <div className="patientListFilterByBtn">
                                            <input type='radio' name="patientListFilter" onClick={(e) => SetListPreview({ ...ListPreview, allList: true, singleList: false })} checked={ListPreview.allList} value={"All List"} />
                                        </div>
                                    </div>
                                    {
                                        PatientListPreview()
                                    }
                                </div>
                                <div className="docProfilePatientListFilterByDate">
                                    <Calendar onChange={(e) => setFilterDate(e)} />
                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </section >



        </>
    )
}


export default ViewAllAppointments