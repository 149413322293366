

import React from 'react'
import "../../../CSS/Patient/Billing/PatientBilling.css"
import TopBar from '../../Bars/TopBar'
import SideBar from '../../Bars/SideBar'
import {  useLocation } from 'react-router-dom'
import PatientBillingComp from './PatientBillingComp'

const PatientBilling = () => {
    const location = useLocation();

    return (
        <>
            <div className="ParentRapper">
                <TopBar pageName="Billing" />
                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <PatientBillingComp id={location.state.id} />
                    </div>
                </div>
            </div>



        </>
    )
}

export default PatientBilling