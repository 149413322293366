import React from 'react'
import TopNavBar from './TopNavBar'
import DoctorSideBar from '../../Bars/DoctorSideBar'
import TopBar from '../../Bars/TopBar'
import "../../../CSS/Profiles/Doctor/Availability.css"
import Calendar from 'react-calendar'

const Availability = () => {
    return (
        <>
            <section className="ParentRapper">
                <TopBar pageName={window.localStorage.getItem("adminType")} />

                <div className="pageDetails">
                    <DoctorSideBar />
                    <div className="page">
                        <TopNavBar />

                        <section className="aviailibilitySection">
                            <div className="availibilityCalender">
                                <Calendar />
                            </div>

                            <div className="availibilityTime">
                                <div className="availableTimrSection">

                                    <div className="fromTime">
                                        <input type="radio" name="availableFromTime" value="9:30" />
                                        <input type="radio" name="availableFromTime" value="10:30" />
                                        <input type="radio" name="availableFromTime" value="11:30" />
                                        <input type="radio" name="availableFromTime" value="12:30" />
                                        <input type="radio" name="availableFromTime" value="13:30" />
                                    </div>
                                    <div className="toTime">
                                        <input type="radio" name="availableToTime" value="9:30" />
                                        <input type="radio" name="availableToTime" value="10:30" />
                                        <input type="radio" name="availableToTime" value="11:30" />
                                        <input type="radio" name="availableToTime" value="12:30" />
                                        <input type="radio" name="availableToTime" value="13:30" />
                                    </div>
                                </div>
                                <div className="markAvailableBtn">
                                    <button>
                                        Mark As Not Available
                                    </button>
                                </div>
                            </div>
                        </section>



                    </div>
                </div>
            </section>
        </>
    )
}

export default Availability