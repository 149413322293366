import React, { useEffect, useState } from 'react'
import TopBar from '../../Bars/TopBar'
import SideBar from '../../Bars/SideBar'
import SettingTopNavBar from '../../Setting/SettingTopNavBar'
import "../../../CSS/Profiles/SystemAdmin/SystemAdmin.css"
import Logout from "../../../Assets/SVG/Settings/Logout.svg"
import { ReactComponent as EditImg } from "../../../Assets/SVG/Settings/Edit.svg"
import { Link } from 'react-router-dom'
import axios from 'axios'
import Loader from '../../Loader/Loader'
import Avatar from "../../../Assets/Images/Avatar.png"
const SystemAdmin = () => {
    const [AdminData, SetAdminData] = useState(null)


    useEffect(() => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/admin/hospitaladmin/get/info", { id: window.localStorage.getItem("Id") }, {

            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetAdminData(res.data)
        }).catch((err) => {
            console.log(err.response.data)
        })



    }, [])
    return (
        <>

            <div className="ParentRapper">
                <TopBar pageName="Setting" />

                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <SettingTopNavBar />


                        {
                            AdminData &&
                            <div className="adminProfileSetting">
                                <div className="flexCol">
                                    <div className="profilePreview">
                                        <div className="profilePicAndIdName">
                                            <img src={Avatar} alt="" />

                                            <div className='flex-col'>
                                                <h2 value="System Admin" >{AdminData.firstName + " " + AdminData.lastName}</h2>
                                                <span>ID: {AdminData._id.slice(-5)}</span>
                                            </div>
                                        </div>
                                        <div className="logoutBtn">
                                            <Link to={"/user/logout"}>
                                                <img src={Logout} alt="" />
                                                <span>Logout</span>
                                            </Link>
                                        </div>
                                    </div>



                                    <div className="adminBasicDetails g-1 flex-col">
                                        <div className="flexRow justify-between">
                                            <h3>
                                                Personal Information
                                            </h3>
                                            <button className="editbtn" disabled>
                                                <span>Edit</span>
                                                <EditImg stroke='#292D32' />
                                            </button>
                                        </div>

                                        <div className="grid">
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    Title
                                                </label>
                                                <span>
                                                    Mr.
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    First Name
                                                </label>
                                                <span>
                                                    {AdminData.firstName}
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    Last Name
                                                </label>
                                                <span>
                                                    {AdminData.lastName}
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    Phone No.
                                                </label>
                                                <span>
                                                    {AdminData.phone}
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    Email Address
                                                </label>
                                                <span>
                                                    {AdminData.email}
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'> Date of Birth </label>
                                                <span>
                                                    {AdminData.dob}
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'> Age</label>
                                                <span>
                                                    {AdminData.age}
                                                </span>
                                            </div>

                                        </div>


                                    </div>
                                    <div className="adminAddressDetails g-1 flex-col">
                                        <div className="flexRow justify-between">
                                            <h3>
                                                Address Information
                                            </h3>
                                            <button className="editbtn" disabled>
                                                <span>Edit</span>
                                                <EditImg stroke='#292D32' />
                                            </button>
                                        </div>

                                        <div className="grid">
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    Basic Address
                                                </label>
                                                <span>
                                                    {AdminData.basicAddress}
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    City
                                                </label>
                                                <span>
                                                    {AdminData.city}
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    State
                                                </label>
                                                <span>
                                                    {AdminData.state}
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    PIN Code
                                                </label>
                                                <span>
                                                    {AdminData.pincode}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            AdminData === null ?
                                <Loader /> : ""
                        }
                    </div>
                </div>
            </div>



        </>
    )
}

export default SystemAdmin