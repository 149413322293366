import { React, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
const localizer = momentLocalizer(moment);

const MyCalendar = ({ eventsData }) => {

    const [Event, SetEvent] = useState(eventsData)

    useEffect(() => {
        SetEvent(eventsData)
        // eslint-disable-next-line
    }, [])
    let EVENTS = Event.map((data) => (
        {
            start: moment(data.appointmentStart).toDate(),
            end: moment(data.appointmentEnd).toDate(),
            title: data.patientInfo.patientFirstName,
            data: {
                status: data.status,
                time: data.time
            }
        }))
    const components = {
        event: (props) => {
            const eventStatus = props.event.data.status.toLowerCase()
            switch (eventStatus.toLowerCase()) {
                case "pending":
                    return (
                        <div style={{
                            background: "#FFF6E9",
                            color: "#B5850B",
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            gap: "5px",
                            padding: "5px"
                        }}>{props.event.data.time}<br />{props.title}<br />{props.event.data.status}</div>
                    );
                case "approved":
                    return (
                        <div style={{
                            background: "#DCDCFD",
                            color: "#222070",
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            gap: "5px",
                            padding: "5px"
                        }}>{props.event.data.time}<br />{props.title}<br />{props.event.data.status}</div>
                    );
                case "reject":
                    return (
                        <div style={{
                            background: "#FFEAEA",
                            color: "#B83131",
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            gap: "5px",
                            padding: "5px"
                        }}>{props.event.data.time}<br />{props.title}<br />{props.event.data.status}</div>
                    );
                case "consulted":
                    return (
                        <div style={{
                            background: "#EDFFEA",
                            color: "#165E3D",
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            gap: "5px",
                            padding: "5px"
                        }}>{props.event.data.time}<br />{props.title}<br />{props.event.data.status}</div>
                    );
                default:
                    return null;
            }
        }
    }

    const minTime = new Date();
    minTime.setHours(8, 30, 0);
    const maxTime = new Date();
    maxTime.setHours(20, 30, 0);
    return (
        <div>
            <Calendar
                localizer={localizer}
                events={EVENTS} // Add your events array here
                startAccessor="start"
                endAccessor="end"
                defaultView='day'
                min={minTime}
                max={maxTime}
                components={components}
                style={{ "height": "78vh" }}
            />
        </div>
    );
};

export default MyCalendar;
