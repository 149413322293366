/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import "../../CSS/EMR/AllEMR.css"
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import FilterImg from '../../Assets/SVG/EMR/Filter.svg'
import EMRProfileCard from './EMRProfileCard'
import AppointmentStatus from '../Patient/EMR/AppointmentStatus'
import PrintImg from "../../Assets/SVG/EMR/Print.svg"
import DiagnosticImg from "../../Assets/SVG/EMR/Diagnostic.svg"
import ConsultImg from "../../Assets/SVG/EMR/Consulted.svg"
import ConfirmImg from "../../Assets/SVG/EMR/Confirm.svg"
import TimeImg from "../../Assets/SVG/EMR/Timer.svg"
import axios from 'axios'
import BlankAlert from '../BlankComp/BlankAlert'
import { Link, useNavigate } from 'react-router-dom'

const AllEMR = () => {
    const navigate = useNavigate()
    const backendUrl = process.env.REACT_APP_BACKEND_URL
    const [PatientDetails, SetPatientDetails] = useState("")

    const [PatientProfile, SetPatientProfile] = useState(null)
    const [AppointmentsFilterDate, SetAppointmentsFilterDate] = useState({
        startDate: "",
        endDate: ""
    })

    const [AllAppointments, SetAllAppointments] = useState(null)
    const [FilterAppointments, SetFilterAppointments] = useState(null)
    const [AllDiagnostics, SetAllDiagnostics] = useState(null)
    const [FilterDiagnostics, SetFilterDiagnostics] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        getPatients()
    }, [])

    useEffect(() => {
        getEmr()
        // eslint-disable-next-line
    }, [PatientDetails])

    const getPatients = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/getall", { "hospitalId": localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetPatientProfile(res.data)
        }).catch((err) => {
            console.log(err.response.data)
        })
    }


    const getEmr = () => {
        axios.post(backendUrl + "/emr/appointment/get/all", { patientId: PatientDetails }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            if (res.data.length !== 0) {
                SetAllAppointments(res.data)
                SetFilterAppointments(res.data)
            } else {
                SetAllAppointments(null)
                SetFilterAppointments(null)

            }
        }).catch((err) => {
            console.log(err.response.data)

        })
        axios.post(backendUrl + "/emr/diagnostics/get/all", { patientId: PatientDetails }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            if (res.data.length !== 0) {

                SetAllDiagnostics(res.data)
                SetFilterDiagnostics(res.data)
            } else {
                SetAllDiagnostics(null)
                SetFilterDiagnostics(null)

            }
        }).catch((err) => {
            console.log(err.response.data)

        })
    }

    const DownloadPrescription = () => {
        navigate("/pdf", { state: { download: true } })
    }

    const filterPatient = PatientProfile ? PatientProfile.filter(patient => {
        const query = searchQuery.toLowerCase()

        return (
            patient.patientFirstName.toLowerCase().includes(query) ||
            patient.patientLastName.toLowerCase().includes(query) ||
            patient.uhid.toLowerCase().includes(query)

        )
    }) : []

    return (
        <>


            <div className="ParentRapper">
                <TopBar pageName="EMR" />

                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <section className="allEmr flex-col g-1 pb-1">
                            <div className="pageTitle">
                                Dashboard / patient-emr /
                            </div>


                            <div className="flexRow h-100 g-2 overflow-hide">
                                <div className="allEmrProfileSection">
                                    <div className="flex-col g-1">
                                        <div className="allEmrProfileSectionHeader flexRow justify-between ">
                                            <h3 style={{ "color": "#2C3857" }}>Patients</h3>
                                            <select name="dateShorter" id="dateShorter">
                                                <option value="">Search Option</option>
                                                <option value="name">Patient Name</option>
                                                <option value="uhid">Patient UHID</option>
                                            </select>
                                        </div>
                                        <div className='searchPatientEmrInput'>
                                            <input type="text" placeholder='Search...' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} />
                                            <img src={FilterImg} alt="" />
                                        </div>
                                    </div>
                                    <div className="flex-1 scrollAbleDiv emrProfileCards">


                                        <div className="flex-col g-1">
                                            {filterPatient.map((data) => {
                                                return <EMRProfileCard PatientDetails={{ data, SetPatientDetails }} key={data._id} />
                                            })}
                                        </div>
                                    </div>




                                </div>
                                <div className="allEmrSection flex-1">
                                    <div className="singlePatientEMRSection">

                                        <div className="profileAppointmentDiagnosticEMR">


                                            {/* Appointment section EMR */}

                                            <div className="singleprofileAppointmentEMR">
                                                <div className="singleEMRFilterHeader">
                                                    <h3>Appointments</h3>
                                                    {/* <div className="singleEMRFIlter">
                                                        <div className="flexRow">
                                                            <label htmlFor="">Date From</label>
                                                            <input type="date" name='startDate' onChange={(e) => SetAppointmentsFilterDate({ ...AppointmentsFilterDate, [e.target.name]: e.target.value })} />
                                                        </div>
                                                        <div className="flexRow">
                                                            <label htmlFor="">Date to</label>
                                                            <input type="date" name='endDate' onChange={(e) => SetAppointmentsFilterDate({ ...AppointmentsFilterDate, [e.target.name]: e.target.value })} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="singlePatientEMRList">
                                                    {/* list */}
                                                    <ul>
                                                        {
                                                            FilterAppointments && FilterAppointments.map((data) => {
                                                                return (

                                                                    <li key={data._id}>
                                                                        <div className="EMRdocName">{
                                                                            data.patientInfo.patientFirstName + " " + data.patientInfo.patientLastName
                                                                        }</div>
                                                                        <div className="DateTime">
                                                                            <span>{
                                                                                data.date
                                                                            }</span>
                                                                            <span>{data.time}</span>
                                                                        </div>

                                                                        <div className="patientVisitedStatus">
                                                                            {
                                                                                data.status.toLowerCase() === "pending" ?
                                                                                    <AppointmentStatus Text={"Requested"} Image={TimeImg} Color={"#B5850B"} Background={"#FFF6E9"} /> : ""
                                                                            }
                                                                            {
                                                                                data.status.toLowerCase() === "consulted" ?
                                                                                    <AppointmentStatus Text={"Consulted"} Image={ConsultImg} Color={"#222070"} Background={"#DCDCFD"} /> : ""
                                                                            }
                                                                            {
                                                                                data.status.toLowerCase() === "approved" ?
                                                                                    <AppointmentStatus Text={"Approved"} Image={ConfirmImg} Color={"#165E3D"} Background={"#EDFFEA"} /> : ""
                                                                            }
                                                                            {
                                                                                data.status.toLowerCase() === "reject" ?
                                                                                    <AppointmentStatus Text={"Rejected"} Color={"white"} Background={"red"} /> : ""
                                                                            }


                                                                        </div>
                                                                        <div className="printBtnsection">
                                                                            {
                                                                                data.status.toLowerCase() !== "pending" && data.status.toLowerCase() !== "approved" && data.status.toLowerCase() !== "reject" ?
                                                                                    <Link to={"/pdf"} state={{ download: true, data: data }} onClick={() => DownloadPrescription()} >
                                                                                        <img src={PrintImg} alt="" />
                                                                                        <span>Print Doc.</span>
                                                                                    </Link> :
                                                                                    <button disabled>
                                                                                        <img src={PrintImg} alt="" />
                                                                                        <span>Consultation Pending.</span>
                                                                                    </button>
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    {FilterAppointments === null ?
                                                        <BlankAlert msg="No Appointment Details Found" className="h-100" /> : ""}

                                                </div>

                                                <div className="singleEMRViewAllBtnSection">
                                                    <button>
                                                        {/* <span >View All</span>
                                                        <img src={ArrowRight} alt="" /> */}
                                                    </button>
                                                </div>
                                            </div>


                                            {/* diagnostic section EMR */}
                                            <div className="singleprofileDiagnosticEMR">
                                                <div className="singleEMRFilterHeader">
                                                    <h3>Diagnostics</h3>
                                                    {/* <div className="singleEMRFIlter">
                                                        <div className="flexRow">
                                                            <label htmlFor="">Date From</label>
                                                            <input type="date" name='startDate' />
                                                        </div>
                                                        <div className="flexRow">
                                                            <label htmlFor="">Date to</label>
                                                            <input type="date" name='endDate' />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="singlePatientEMRList">
                                                    {/* List */}


                                                    <ul>

                                                        {
                                                            FilterDiagnostics && FilterDiagnostics.map((diagnostics) => {
                                                                return (
                                                                    <li key={diagnostics._id}>
                                                                        <div className="singleDiagnosticEMR">
                                                                            <div className="singleDiagnosticEMRCommonIcon">
                                                                                <img src={DiagnosticImg} alt="" />
                                                                            </div>
                                                                            <div style={{ paddingLeft: "10px" }}>
                                                                                {diagnostics.diagnosticsInfo.map((data, index) => {
                                                                                    return (
                                                                                        <h3 className="diagnosticTitle" key={index}>
                                                                                            {data}
                                                                                        </h3>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        <div className="DateTime">
                                                                            <span>0000-00-00</span>
                                                                            <span>00:00</span>
                                                                        </div>


                                                                        <AppointmentStatus Text={"Success"} Image={ConsultImg} Color={"#165E3D"} Background={"#EDFFEA"} />


                                                                        <div className="printBtnsection">
                                                                            {diagnostics.labTestResult !== undefined ?
                                                                                <a href={`${backendUrl}/pdf/${diagnostics.labTestResult}`} target='blank'>
                                                                                    <button>
                                                                                        <img src={PrintImg} alt="" />
                                                                                        <span>Print Doc.</span>
                                                                                    </button>
                                                                                </a> :
                                                                                <button disabled={true}>
                                                                                    <img src={PrintImg} alt="" />
                                                                                    <span>Lab Test Pending.</span>
                                                                                </button>
                                                                            }
                                                                        </div>

                                                                    </li>

                                                                )
                                                            })
                                                        }


                                                    </ul>
                                                    {FilterDiagnostics === null ?
                                                        <BlankAlert msg="No Diagnostics Details Found" className="h-100" /> : ""}


                                                </div>
                                                <div className="singleEMRViewAllBtnSection">
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>



        </>
    )
}

export default AllEMR