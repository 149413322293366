/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import TopBar from '../../Bars/TopBar'
import SideBar from '../../Bars/SideBar'
import SettingTopNavBar from '../../Setting/SettingTopNavBar'
import "../../../CSS/Profiles/SystemAdmin/AccountLists.css"
import DataTable from 'react-data-table-component'
import ThreeDot from "../../../Assets/SVG/Settings/ThreeDot.svg"
import axios from 'axios'
const AccountLists = () => {
    const [Role, SetRole] = useState("doctor")

    const [ProfileData, SetProfileData] = useState([])
    const [FilterData, SetFilterData] = useState([])
    const [ListHeader, SetListHeader] = useState([])
    const [RequestUrl, SetRequestUrl] = useState("/doctor/getall")
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const filterData = ProfileData.filter(val => {
            return val.type.toLowerCase().match(Role.toLowerCase())
        })
        SetFilterData(filterData)


        switch (Role) {
            case "doctor":
                SetRequestUrl("/doctor/getall")
                SetListHeader([
                    {
                        name: "Name",
                        selector: row => row.name,
                        sortable: true
                    },
                    {
                        name: "DoctorID",
                        selector: row => row.licenseNo
                    },
                    {
                        name: "Specility",
                        selector: row => row.specialization
                    },

                    {
                        name: "Phone",
                        selector: row => row.phone
                    },
                    {
                        name: "Email",
                        selector: row => row.email
                    }, {
                        name: "DOB",
                        selector: row => row.DOB
                    },
                    {
                        name: "",
                        selector: row => <button className='dataTableActionBtn' onClick={() => alert("Second Phase")}><img src={ThreeDot} alt="" /></button>
                    }
                ])
                break;
            case "receptionist":

                SetRequestUrl("/receptionist/get")
                SetListHeader([
                    {
                        name: "Name",
                        selector: row => row.name,
                        sortable: true
                    },


                    {
                        name: "Phone",
                        selector: row => row.phone
                    },
                    {
                        name: "Email",
                        selector: row => row.email
                    },
                    {
                        name: "",
                        selector: row => <button className='dataTableActionBtn' onClick={() => alert("Second Phase")}><img src={ThreeDot} alt="" /></button>
                    }
                ])
                break;
            case "pa":

                SetRequestUrl("/pa/get")
                SetListHeader([
                    {
                        name: "Name",
                        selector: row => row.name,
                        sortable: true
                    },
                    {
                        name: "Phone",
                        selector: row => row.phone
                    },
                    {
                        name: "Email",
                        selector: row => row.email
                    },
                    {
                        name: "Doctor Email",
                        selector: row => row.doctorEmail
                    },
                    {
                        name: "",
                        selector: row => <button className='dataTableActionBtn' onClick={() => alert("Second Phase")}><img src={ThreeDot} alt="" /></button>
                    }
                ])
                break;
            case "nurse":

                SetRequestUrl("/nurse/get")
                SetListHeader([
                    {
                        name: "Name",
                        selector: row => row.name,
                        sortable: true
                    },


                    {
                        name: "Phone",
                        selector: row => row.phone
                    },
                    {
                        name: "Email",
                        selector: row => row.email
                    },
                    {
                        name: "NurseID",
                        selector: row => row.licenseNo
                    },
                    {
                        name: "DOB",
                        selector: row => row.DOB
                    },
                    {
                        name: "",
                        selector: row => <button className='dataTableActionBtn' onClick={() => alert("Second Phase")}><img src={ThreeDot} alt="" /></button>
                    }
                ])
                break;
            case "diagnostics":

                SetRequestUrl("/diagnostics/get")
                SetListHeader([
                    {
                        name: "Email",
                        selector: row => row.email
                    },
                    {
                        name: "Phone",
                        selector: row => row.phone
                    },

                    {
                        name: "",
                        selector: row => <button className='dataTableActionBtn' onClick={() => alert("Second Phase")}><img src={ThreeDot} alt="" /></button>
                    }
                ])
                break;
            default:
                SetListHeader([
                    {
                        name: "Name",
                        selector: row => row.name,
                        sortable: true
                    },


                    {
                        name: "Phone",
                        selector: row => row.phone
                    },
                    {
                        name: "Email",
                        selector: row => row.Email
                    },
                    {
                        name: "",
                        selector: row => <button className='dataTableActionBtn' onClick={() => alert("Second Phase")}><img src={ThreeDot} alt="" /></button>
                    }
                ])
                break;
        }

        // eslint-disable-next-line
    }, [Role])
    const getUsers = () => {
        setLoading(true);
        axios.post(process.env.REACT_APP_BACKEND_URL + RequestUrl, { hospitalId: localStorage.getItem("hospitalId") }, {
            headers: {
                token: window.localStorage.getItem("token")
            }
        }).then((res) => {
            SetFilterData(res.data)
            setLoading(false);

        }).catch((err) => {
            console.log(err.response.data)
            setLoading(false);
        })
    }
    useEffect(() => {
        getUsers()
        // eslint-disable-next-line
    }, [RequestUrl])
    useEffect(() => {
        getUsers()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="ParentRapper">
                <TopBar pageName="Setting" />

                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <SettingTopNavBar />
                        <div className="allAccountCreatedList">





                            <div className="adminPageAccountsList">
                                <DataTable
                                    columns={ListHeader}
                                    data={FilterData}
                                    selectableRows
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                    selectableRowsHighlight
                                    subHeader
                                    subHeaderAlign='left'
                                    subHeaderComponent={
                                        <div className="flexRow g-1">
                                            <h3 className='profileListPreviewText'>{
                                                Role.charAt(0).toLocaleUpperCase() + Role.slice(1)}</h3>
                                            <select name="role" id="selectRole" value={Role} onChange={(e) => SetRole(e.target.value)} >
                                                <option value="doctor">Doctor</option>
                                                <option value="nurse">Nurse</option>
                                                <option value="pa">Doctor PA</option>
                                                <option value="receptionist">Receptionist</option>
                                                <option value="diagnostics">Diagnostics</option>
                                            </select>
                                        </div>

                                    }
                                    progressPending={loading} 
                                    progressComponent={<div className='loaderSpinner'></div>}
                                    noDataComponent="There are no records to display"
                                />

                            </div>

                        </div>






                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountLists