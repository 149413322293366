import React from 'react'
import { Modal, useMantineTheme } from "@mantine/core";
import '../../CSS/modals/ViewDiagnostics.css'
import labs from '../../Assets/SVG/lab.svg'


const ViewDiagnostics = ({ openModel, setOpenModel, data }) => {
    const theme = useMantineTheme();

    return (
        <div>
            <Modal
                overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 4,
                }}
                size="40%"
                opened={openModel}
                onClose={() => setOpenModel(false)}
            >
                <div className='ParentModalWrapper'>
                    <div className='heading'>
                        <img src={labs} alt="" />
                        <h1>Lab Tests</h1>
                    </div>
                    <div>
                        {data && data.map((data, count) => {
                            return (
                                <div key={count}>
                                    <h3>{count + 1} ) {data}</h3>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ViewDiagnostics