import React from 'react'
import "../../../CSS/Profiles/SuperAdmin/CreateHospital.css"
import "../../../../App.css"
const CreateHospital = () => {
  return (
    <>
      <section className="hospitalCreateFormSection h-100 w-100 flex align-center justify-center absolute">
        <form className='hospitalCreateForm' onSubmit={(e)=>e.preventDefault()} >

          <div className="w-100 flex-col p-3">
            <label htmlFor="Email">Email</label>
            <input type="email" className='hospitalCreateInput' placeholder='Please enter hospital admin email' />
          </div>
          <div className="w-100 flex-col p-3">
            <label htmlFor="Phone">Phone No</label>
            <input type="number" className='hospitalCreateInput' placeholder='Please enter hospital admin phone no' />
          </div>
          <div className="w-100 flex-col p-3">
            <label htmlFor="Phone">Hospital Name</label>
            <input type="text" className='hospitalCreateInput' placeholder='Please enter hospital name' />
          </div>
          <div className="w-100 flex-col p-3">
            <label htmlFor="Phone">Hospital Name</label>
            <div className="flexRow g-1 flex-1 justify-between">
              <input type="text" className='hospitalCreateInput flex-1' placeholder='Please enter hospital name' />
              <button type='button' className='hospitalidGenaratorBtn'>
                Generate
              </button>
            </div>
          </div>

          <div className="flex justify-center align-center">
          <button type='submit' className='hospitalCreateBtn'>
                Continue
              </button>
          </div>

        </form>
      </section>

    </>
  )
}

export default CreateHospital