import { React, useEffect, useState } from 'react'
import "../../../CSS/Appointment/Diagnostics/DiagnosticsBooking.css"
import TopBar from '../../Bars/TopBar'
import SideBar from '../../Bars/SideBar'
import PlusSvg from "../../../Assets/SVG/Appointment/BloodText.svg"
import ReactModal from 'react-modal'
import SearchIcon from "../../../Assets/SVG/patientSVG/SearchBtn.svg"
import { ReactComponent as AddMoreImg } from "../../../Assets/SVG/patientSVG/AddMore.svg"
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loader from '../../Loader/Loader'
import { ToastContainer, toast } from 'react-toastify'
import backBtn from '../../../Assets/SVG/back.svg'
import labImg from '../../../Assets/SVG/lab.svg'


const DiagnosticsBooking = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const backendUrl = process.env.REACT_APP_BACKEND_URL
    const [openModal, setOpenModal] = useState(false);

    const AddSubDiagnosticItem = (e) => {
        let inputVal = document.getElementById("subdiagnosticInput").value

        const elm1 = document.createElement("div")
        elm1.classList.add("subItemsCard")

        const elm2 = document.createElement("span")
        elm2.innerHTML = inputVal

        const elm3 = document.createElement("input")
        elm3.type = "checkbox"
        elm3.name = e.target.parentNode.children[0].value
        elm3.value = inputVal
        elm3.addEventListener("change", (e) => {
            DiagnosticsSelector(e)
        })
        elm1.append(elm2, elm3)
        if (e.target.parentNode.children[0].value !== "") {
            e.target.parentNode.parentNode.insertBefore(elm1, e.target.parentNode)
            e.target.parentNode.children[0].value = ""
        }
    }

    const [Diagnostics, SetDiagnostics] = useState(null)
    const [SubDiagnostics, SetSubDiagnostics] = useState(null)
    const [BookDiagnostics, SetBookDiagnostics] = useState({
        hospitalId: localStorage.getItem("hospitalId"),
        patientId: location.state.id,
        diagnosticsInfo: []
    })
    useEffect(() => {
        getDiagnostcs()
        // eslint-disable-next-line
    }, [])
    const getDiagnostcs = () => {
        axios.post(backendUrl + "/diagnostics/info/get", { hospitalId: localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetDiagnostics(res.data)
        }).catch((err) => {
            console.log(err.response.data)

        })
    }

    const DiagnosticsSelector = (e) => {
        var Inputvalue = e.target.value
        if (e.target.checked) {

            SetBookDiagnostics(prevState => ({
                hospitalId: localStorage.getItem("hospitalId"),
                patientId: location.state.id,
                diagnosticsInfo: [...prevState.diagnosticsInfo, Inputvalue]
            }));
        } else {
            SetBookDiagnostics((prevState) => {
                const updatedDiagnostic = prevState.diagnosticsInfo.filter((_, index) => _ !== Inputvalue);
                return { ...prevState, diagnosticsInfo: updatedDiagnostic };
            });
        }
    }

    const DiagnosticsSet = () => {
        if (BookDiagnostics.diagnosticsInfo.length !== 0) {
            navigate("/user/patient/disgnostics/booking", { state: { BookDiagnostics } })
        } else {

            toast.error("Please Select at least one test")
        }
    }


    return (
        <>
            <ToastContainer />

            <div className="ParentRapper">
                <TopBar pageName="Diagonstics" />

                <div className="pageDetails">
                    <SideBar />


                    <div className="page space-between pb-1">
                        <div className="flex-col g-1">
                            <div className="AppointmentSelection">
                                <h3 className="pageTitle">Diagonstics</h3>
                                <div className="pagePath">
                                    <img src={backBtn} alt="" onClick={() => window.history.back()}/>
                                    Dashboard / patient-login / diagonstics
                                </div>
                            </div>


                            <div className="patientDiagnosticsList">


                                {
                                    Diagnostics && Diagnostics.map((lab) => {
                                        return (
                                            <div className="patientDiagnosticsCards" key={lab._id}>
                                                <div className="diagnosticItemIcon">
                                                    <span></span>
                                                    <img src={labImg} alt="" />
                                                </div>
                                                <span className="diagnosticcardTitle">
                                                    {lab.diagnoisticsName}
                                                </span>
                                                <img src={PlusSvg} alt="" onClick={(e) => [SetSubDiagnostics([lab.diagnoisticsSubGroup]), setOpenModal(true)]} />
                                            </div>
                                        )
                                    })

                                }


                            </div>
                        </div>
                        {
                            Diagnostics === null ? <Loader className="h-100" /> : ""
                        }


                        <ReactModal isOpen={openModal} onBlur={() => setOpenModal(false)} >

                            <div className="modalSearch">
                                <input type="text" placeholder='Search...' />
                                <img src={SearchIcon} alt="" />
                            </div>
                            <button className="subDiagnosticAdderModalCloser" onClick={() => setOpenModal(false)}>
                                &times;
                            </button>
                            <div className="extraDiagnosticItemAddedCards">


                                <div className="subDdiagnosticField">
                                    {
                                        SubDiagnostics && SubDiagnostics.map((data) => {
                                            return (
                                                data.map((d) => {
                                                    return (
                                                        <div div className="subItemsCard" key={d}>
                                                            <span>{d}</span>
                                                            <input type="checkbox" name={d} value={d} onChange={(e) => DiagnosticsSelector(e)} key={d} />
                                                        </div>
                                                    )
                                                })
                                            )
                                        })
                                    }
                                    <div className="subDiagnosticTypeAdder">
                                        <input type="text" id='subdiagnosticInput' />
                                        <button onClick={(e) => AddSubDiagnosticItem(e)}>
                                            <AddMoreImg className='addmoreDiagnosticImg' />
                                            <span>Add Other</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="addedSubdiagnosticBtn" onClick={() => setOpenModal(false)}>
                                <button>Save Now</button>
                            </div>
                        </ReactModal>


                        <div className="diagnosticScheduleNextBtnSection">
                            <button
                                onClick={() => DiagnosticsSet()}
                                className='diagnosticAppointment' style={{ cursor: "pointer" }}>
                                Go Next
                            </button>
                        </div>
                    </div>
                </div >
            </div >



        </>
    )
}

export default DiagnosticsBooking