import React from 'react'

const Receptionist = ({ FormData }) => {
    return (
        <>

            <div className="flex-col g-05">
                <label htmlFor="name">
                    Receptionist Name
                </label>
                <input type="text" name='name' placeholder='Enter Receptionist name' value={FormData.FormData.receptionistName} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="email">
                    Receptionist Email
                </label>
                <input type="email" name='email' placeholder='Enter nurse email' value={FormData.FormData.receptionistEmail} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value,hospitalId: localStorage.getItem("hospitalId") })} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="phone">
                    Receptionist Phone No
                </label>
                <input type="number" name='phone' placeholder='Enter nurse phone no' value={FormData.FormData.receptionistPhone} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
            </div>

            <div className="flex-col g-05">
                <label htmlFor="speciality">
                    Receptionist Profile Password
                </label>
                <input type="password" name='password' placeholder='Enter nurse profile Password' value={FormData.FormData.receptionistPassword} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
            </div>

        </>
    )
}

export default Receptionist