/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import "../../../CSS/Patient/Billing/PatientBilling.css"
import { ReactComponent as PlusImg } from "../../../Assets/SVG/patientSVG/AddMore.svg"
import BillingStatus from './BillingStatus'
import { Link, useLocation } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ReactComponent as EditImg } from "../../../Assets/SVG/Settings/Edit.svg"
import axios from 'axios'
import Loader from '../../Loader/Loader'
import BlankAlert from '../../BlankComp/BlankAlert'
import ReactModal from 'react-modal'
import { ToastContainer, toast } from 'react-toastify'
import backBtn from '../../../Assets/SVG/back.svg'

const PatientBillingComp = (id) => {
    const location = useLocation()
    const [FilterDate, SetFilterDate] = useState({
        startDate: "",
        endDate: ""
    })

    const [PatientBill, SetPatientBill] = useState(null)
    const [DisplayBill, SetDisplayBill] = useState([]);
    const [paidAmountVal, SetpaidAmountVal] = useState()
    const [EditModal, SetEditModal] = useState(false)
    const [EditAmount, SetEditAmount] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [FilteredBill, SetFilteredBill] = useState(null);

    const ITEMS_PER_PAGE = 10;


    const [BillingCols, SetBillingCols] = useState([
        {
            name: "Date",
            selector: row => row.updatedAt.split("T")[0]
        }, {
            name: "Time",
            selector: row => row.updatedAt.split("T")[1].split(":")[0] + ":" + row.updatedAt.split("T")[1].split(":")[1]
        }, {
            name: "Subject",
            selector: row => row.bill.subject
        }, {
            name: "Due Ammount",
            selector: row => row.bill.amount - row.bill.paid
        }, {
            name: "Paid Amount",
            selector: row => row.bill.paid
        }
        , {
            name: "Total",
            selector: row => row.bill.amount
        }, {
            name: "Status",
            selector: row => < BillingStatus amount={row.bill.amount} paid={row.bill.paid} />

        }, {
            name: "Action",
            selector: row => <button className='editBillingBtn' onClick={() => EditPaidAmmount(row)}>
                <EditImg stroke='var(  --nav-btn-back-color)' />
            </button>
        }
    ])

    useEffect(() => {
        GetBill()
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        if (PatientBill) {
            const startIndex = 0;
            const endIndex = currentPage * ITEMS_PER_PAGE;
            SetDisplayBill(PatientBill.slice(0, endIndex));
        }
    }, [PatientBill, currentPage]);

    const GetBill = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/billing/get", { patientId: id.id, hospitalId: localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetPatientBill(res.data)
        }).catch((err) => {
            console.log(err.response.data)
        })
    }
    const EditPaidAmmount = (data) => {
        SetEditModal(true)
        SetEditAmount(data)
    }
    const submitAmount = (e) => {
        e.preventDefault()
        e.target.lastChild.children[0].disabled = true

        if (EditAmount && Number(EditAmount.bill.amount) - Number(EditAmount.bill.paid) >= Number(paidAmountVal)) {


            axios.put(process.env.REACT_APP_BACKEND_URL + "/billing/update", {
                id: EditAmount._id,
                amount: (Number(paidAmountVal) + Number(EditAmount.bill.paid)).toString()
            }, {
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then(() => {
                SetEditModal(false)
                toast.success("Amount Change successfully")
                GetBill()
                e.target.lastChild.children[0].disabled = false

            }).catch((err) => {
                console.log(err.response.data)
            })
        } else {
            toast.error("You entry amount is rich from total amount")

        }
    }

    const loadMoreData = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    useEffect(() => {
        if (PatientBill) {
            const filteredData = PatientBill.filter(bill => {
                const billDate = new Date(bill.updatedAt);
                const startDateMatch = FilterDate.startDate ? billDate >= new Date(FilterDate.startDate) : true;
                const endDateMatch = FilterDate.endDate ? billDate <= new Date(new Date(FilterDate.endDate).setHours(23, 59, 59, 999)) : true;
                return startDateMatch && endDateMatch;
            });
            SetFilteredBill(filteredData);
            const startIndex = 0;
            const endIndex = currentPage * ITEMS_PER_PAGE;
            SetDisplayBill(filteredData.slice(0, endIndex));
        }
    }, [PatientBill, FilterDate, currentPage]);


    return (
        <>
            <ToastContainer />
            <ReactModal isOpen={EditModal}>
                <button className="subDiagnosticAdderModalCloser" onClick={() => SetEditModal(false)}>
                    &times;
                </button>
                <div className="flex-col g-2 justify-center align-center w-100 h-100">
                    <form onSubmit={(e) => submitAmount(e)} className="flex-col g-1 w-100 justify-center align-center">
                        <h3>Edit Paid Amount</h3>
                        <p><strong>Billing Subject :</strong> {EditAmount && EditAmount.bill.subject}</p>
                        <p><strong>Total Paid Amount :</strong> {EditAmount && EditAmount.bill.amount}</p>
                        <p><strong>Previous Paid Amount  :</strong> {EditAmount && EditAmount.bill.paid}</p>
                        <p><strong>Total Due Amount  :</strong> {EditAmount && Number(EditAmount.bill.amount) - Number(EditAmount.bill.paid)}</p>
                        {EditAmount && EditAmount.bill.amount - EditAmount.bill.paid === 0 ? "Your Billing amount already paid" :

                            <div className="flexRow g-1 align-center">
                                <input type="number" name="paidamount" onChange={(e) => SetpaidAmountVal(e.target.value)} placeholder='Enter Paidable Amount' className='inputs' required />
                            </div>
                        }
                        <div className="patientcardsBtns">
                            <button disabled={EditAmount && EditAmount.bill.amount - EditAmount.bill.paid === 0 ? true : false} >{EditAmount && EditAmount.bill.amount - EditAmount.bill.paid ? "Submit Paid Amount" : "Fully Paid"}</button>
                        </div>
                    </form>
                </div>
            </ReactModal>





            <section className="billingSection">
                <div className="billigSectionTopFIlter">
                    <div className="billingPageTitleAddBilling">
                        <div className="billingPageTitle">

                            <h3>Patient Billing</h3>
                            <div className="pagePath">
                                <img src={backBtn} alt="" onClick={() => window.history.back()} />
                                Dashboard / patient-login / patient-billing /
                            </div>
                        </div>
                        <Link to={"/user/patient/profile/billing/addbilling"} state={id}>
                            <PlusImg className='billingPagePlusBtn' />
                            <span>Add Billing</span>
                        </Link>
                    </div>
                    <div className="billingHeaderFilter">
                        <div className="billingShortOption">
                            <div className="flexRow">
                                <label htmlFor="">Date From: &nbsp;</label>
                                <input type="date" name="startDate" onChange={(e) => {
                                    SetFilterDate({ ...FilterDate, [e.target.name]: e.target.value });
                                    setCurrentPage(1);  // Reset to first page when filter changes
                                }} />
                            </div>
                            <div className="flexRow">
                                <label htmlFor="">Date To: &nbsp;</label>
                                <input type="date" name="endDate" onChange={(e) => {
                                    SetFilterDate({ ...FilterDate, [e.target.name]: e.target.value });
                                    setCurrentPage(1);  // Reset to first page when filter changes
                                }} />
                            </div>
                        </div>
                    </div>
                </div>

                {PatientBill !== null ? PatientBill.length !== 0 ?
                    <>
                        <DataTable
                            columns={BillingCols}
                            data={DisplayBill}
                            selectableRows
                        />
                        {DisplayBill.length < (FilterDate.startDate || FilterDate.endDate ? FilteredBill.length : PatientBill.length) && (
                            <div className="loadMore">
                                <button onClick={loadMoreData}>Load More</button>
                            </div>
                        )}
                    </>
                    : <BlankAlert msg="No Result Found" /> : ""
                }
                {
                    PatientBill === null && id.id !== null ? <Loader />
                        : ""
                }
                {
                    id.id === null ? <BlankAlert msg={"Select a patient profile"} /> : ""
                }
            </section >

        </>
    )
}

export default PatientBillingComp