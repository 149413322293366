import { React, useState, useEffect } from 'react'
import TopBar from "../../Bars/TopBar"
import SideBar from "../../Bars/SideBar"
import "../../../CSS/Patient/EMR/EMR.css"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ArrowRight from "../../../Assets/SVG/EMR/ArrowRight.svg"
import ConsultImg from "../../../Assets/SVG/EMR/Consulted.svg"
import PendingImg from "../../../Assets/SVG/EMR/Timer.svg"
import ConfirmImg from "../../../Assets/SVG/EMR/Confirm.svg"
import PrintImg from "../../../Assets/SVG/EMR/Print.svg"
import DiagnosticImg from "../../../Assets/SVG/EMR/Diagnostic.svg"
import Data from "./EMRData.json"
import AppointmentStatus from './AppointmentStatus'
import axios from 'axios'
import ReactModal from 'react-modal'
import RejectImg from "../../../Assets/SVG/patientSVG/CrossCircle.svg"
import backBtn from '../../../Assets/SVG/back.svg'

const EMR = () => {

    const [BasicDetails, SetBasicDetails] = useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [DiagnosticsFilterDate, SetDiagnosticsFilterDate] = useState({
        startDate: "",
        endDate: ""
    })
    const [AppointmentsFilterDate, SetAppointmentsFilterDate] = useState({
        startDate: "",
        endDate: ""
    })
    const [AllDiagnostics, SetAllDiagnostics] = useState(null)
    const [FilterDiagnostics, SetFilterDiagnostics] = useState(null)


    const [AllAppointments, SetAllAppointments] = useState(null)
    const [FilterAppointments, SetFilterAppointments] = useState(null)
    useEffect(() => {
        if (AllDiagnostics !== null) {

            if (DiagnosticsFilterDate.startDate !== "" && DiagnosticsFilterDate.endDate !== "") {
                const Filter = AllDiagnostics.filter((data) => {
                    let dataDate = new Date(data.date);
                    return dataDate >= DiagnosticsFilterDate.startDate && dataDate <= DiagnosticsFilterDate.endDate
                })
                SetFilterDiagnostics(Filter)
            }
        }



    }, [DiagnosticsFilterDate, AllDiagnostics])



    useEffect(() => {
        if (AppointmentsFilterDate.startDate !== "" && AppointmentsFilterDate.endDate !== "") {

            const Filter = AllAppointments.filter((data) => {
                let dataDate = new Date(data.date);

                return dataDate >= AppointmentsFilterDate.startDate && dataDate <= AppointmentsFilterDate.endDate
            })
            SetFilterAppointments(Filter)
        }

    }, [AppointmentsFilterDate, AllAppointments])

    useEffect(() => {

        getEMR()
        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/get", { phone: location.state.phone }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetBasicDetails(res.data[0])
        }).catch((err) => {
            console.log(err.response.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getEMR = () => {
        axios.post(backendUrl + "/emr/appointment/get/all", { patientId: location.state.id }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetFilterAppointments(res.data)
            SetAllAppointments(res.data)

        }).catch((err) => {
            console.log(err.response.data)
        })

        axios.post(backendUrl + "/emr/diagnostics/get/all", { patientId: location.state.id }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetAllDiagnostics(res.data)
            SetFilterDiagnostics(res.data)

        }).catch((err) => {
            console.log(err.response.data)
        })
    }
    const [ModalContent, SetModalContent] = useState(null)
    const [ModalOpen, SetModalOpen] = useState(false)
    const EMRDiagnosticModal = (data) => {
        SetModalContent(data)
        SetModalOpen(true)
    }

    const DownloadPrescription = () => {
        navigate("/pdf", { state: { download: true } })
    }

    return (
        <>
            {ModalContent && <ReactModal isOpen={ModalOpen}>
                <button className="subDiagnosticAdderModalCloser" onClick={() => SetModalOpen(false)}>
                    &times;
                </button>
                <div className="flex-col g-2 h-100 w-100 justify-center align-center">
                    <ul className="flex-col">
                        <li>
                            <h3>Diagnostic All Test Name</h3>
                        </li>



                        <ol className='list'>

                            {
                                ModalContent && ModalContent.map((data) => {
                                    return (
                                        <li>{data}</li>
                                    )
                                })
                            }
                        </ol>

                    </ul>
                </div>


            </ReactModal>
            }
            <div className="ParentRapper">
                <TopBar pageName="Patient EMR" />

                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <div className="pageTitle">
                            <img src={backBtn} alt="" onClick={() => window.history.back()} />
                            <p>Dashboard / patient-login / patient-emr /</p>
                        </div>

                        <div className="singlePatientEMRSection">

                            <div className="profileEMRMedicalHistorySection">
                                <div className="shortEmrProfile">
                                    <div className="circuler w-5r h-5r flex align-center justify-center " style={{ border: "2px solid #222070", padding: "3px" }}>
                                        <span className='circuler w-100 h-100 flex align-center justify-center -white ' style={{ background: "#222070" }}>
                                            {
                                                BasicDetails && BasicDetails.patientFirstName.slice(0, 2).toUpperCase()
                                            }
                                        </span>
                                    </div>

                                    <h3>{BasicDetails && BasicDetails.patientFirstName + " " + BasicDetails.patientLastName}</h3>
                                    <p className='patientUHID'>UHID:{BasicDetails && BasicDetails.uhid}</p>

                                    <Link className="viewProfileEmRPageBtn">
                                        View Profile
                                    </Link>

                                </div>
                                <div className="shortEmrMedicalHistory">
                                    <h3>Medical History</h3>
                                </div>
                            </div>
                            <div className="profileAppointmentDiagnosticEMR">


                                {/* Appointment section EMR */}

                                <div className="singleprofileAppointmentEMR">
                                    <div className="singleEMRFilterHeader">
                                        <h3>Appointments</h3>
                                        <div className="singleEMRFIlter">
                                            <div className="flexRow">
                                                <label htmlFor="">Date From</label>
                                                <input type="date" name='startDate' onInput={(e) => SetAppointmentsFilterDate({ ...AppointmentsFilterDate, [e.target.name]: new Date(e.target.value) })} />
                                            </div>
                                            <div className="flexRow">
                                                <label htmlFor="">Date to</label>
                                                <input type="date" name='endDate' onInput={(e) => SetAppointmentsFilterDate({ ...AppointmentsFilterDate, [e.target.name]: new Date(e.target.value) })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="singlePatientEMRList">
                                        {/* list */}


                                        <ul>
                                            {
                                                FilterAppointments && FilterAppointments.map((data) => {

                                                    return (
                                                        <>

                                                            <li>
                                                                <div className="EMRdocName">{data.doctorInfo.name}</div>
                                                                <div className="DateTime">
                                                                    <span>{data.date}</span>
                                                                    <span>{data.time}</span>
                                                                </div>

                                                                <div className="patientVisitedStatus">
                                                                    {
                                                                        data.status.toLowerCase() === "pending" ?
                                                                            <AppointmentStatus Text={data.status} Image={PendingImg} Color={"#B5850B"} Background={"#FFF6E9"} /> : ""
                                                                    }

                                                                    {
                                                                        data.status.toLowerCase() === "approved" ?
                                                                            <AppointmentStatus Text={data.status} Image={ConsultImg} Color={"#222070"} Background={"#DCDCFD"} /> : ""
                                                                    }
                                                                    {
                                                                        data.status.toLowerCase() === "consulted" ?
                                                                            <AppointmentStatus Text={data.status} Image={ConfirmImg} Color={"#165E3D"} Background={"#EDFFEA"} /> : ""
                                                                    }
                                                                    {
                                                                        data.status.toLowerCase() === "reject" ?
                                                                            <AppointmentStatus Text={"Rejected"} Image={""} Color={"white"} Background={"#FF3236"} /> : ""
                                                                    }

                                                                </div>
                                                                <div className="printBtnsection">
                                                                    {
                                                                        data.status.toLowerCase() !== "pending" && data.status.toLowerCase() !== "approved" && data.status.toLowerCase() !== "reject" ?
                                                                            <Link to={"/pdf"} state={{ download: true, data: data }} onClick={() => DownloadPrescription()} >
                                                                                <img src={PrintImg} alt="" />
                                                                                <span>Print Doc.</span>
                                                                            </Link> :
                                                                            <button disabled>
                                                                                <img src={PrintImg} alt="" />
                                                                                <span>Print Doc.</span>
                                                                            </button>
                                                                    }
                                                                </div>
                                                            </li>
                                                        </>
                                                    )
                                                })
                                            }

                                        </ul>
                                        {
                                            FilterAppointments && FilterAppointments.length === 0 ? <div className='blankAppointment'>
                                                No Appointments EMR Found
                                            </div> : ""
                                        }
                                        {
                                            FilterAppointments === null ? <div className='blankAppointment'>
                                                Please wait ...
                                            </div> : ""
                                        }



                                    </div>

                                    <div className="singleEMRViewAllBtnSection">
                                        <button disabled={true}>
                                            <span onClick={() => SetFilterAppointments(Data.appointments)}>View All</span>
                                            <img src={ArrowRight} alt="" />
                                        </button>
                                    </div>
                                </div>


                                {/* diagnostic section EMR */}
                                <div className="singleprofileDiagnosticEMR">
                                    <div className="singleEMRFilterHeader">
                                        <h3>Diagnostics</h3>
                                        <div className="singleEMRFIlter">
                                            <div className="flexRow">
                                                <label htmlFor="">Date From</label>
                                                <input type="date" name='startDate' onChange={(e) => SetDiagnosticsFilterDate({ ...DiagnosticsFilterDate, [e.target.name]: new Date(e.target.value) })} />
                                            </div>
                                            <div className="flexRow">
                                                <label htmlFor="">Date to</label>
                                                <input type="date" name='endDate' onChange={(e) => SetDiagnosticsFilterDate({ ...DiagnosticsFilterDate, [e.target.name]: new Date(e.target.value) })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="singlePatientEMRList">
                                        {/* List */}


                                        <ul>
                                            {
                                                FilterDiagnostics && FilterDiagnostics.map((data,index) => {
                                                    return (
                                                        <li>
                                                            <div className="singleDiagnosticEMR g-2">
                                                                <div className="singleDiagnosticEMRCommonIcon">
                                                                    <img src={DiagnosticImg} alt="" />
                                                                </div>
                                                                <h3 className="diagnosticTitle">
                                                                    {

                                                                        FilterDiagnostics && data.diagnosticsInfo.slice(0, 2).map((diag) => {
                                                                            return (<>
                                                                                <div className="flex-col">
                                                                                    <span>{diag}</span>
                                                                                </div>
                                                                            </>
                                                                            )
                                                                        })
                                                                    }
                                                                    {data.diagnosticsInfo.length > 2 ?
                                                                        <button className='viewallEMRBtn' onClick={() => EMRDiagnosticModal(data.diagnosticsInfo)}>
                                                                            view All
                                                                        </button>
                                                                        : ""
                                                                    }
                                                                </h3>
                                                            </div>
                                                            <div className="DateTime">
                                                                <span>{data.date}</span>
                                                                <span>{data.time}</span>
                                                            </div>

                                                            {
                                                                data.status.toLowerCase() === "approved" ?
                                                                    <AppointmentStatus Text={"Confirm"} Image={ConfirmImg} Color={"#165E3D"} Background={"#EDFFEA"} />
                                                                    : ""
                                                            }
                                                            {
                                                                data.status.toLowerCase() === "pending" ?
                                                                    <AppointmentStatus Text={data.status} Image={PendingImg} Color={"#B5850B"} Background={"#FFF6E9"} />
                                                                    : ""

                                                            }
                                                            {
                                                                data.status.toLowerCase() === "reject" ?
                                                                    <AppointmentStatus Text={"Rejected"} Image={RejectImg} Color={"white"} Background={"red"} />
                                                                    : ""
                                                            }
                                                            <div className="printBtnsection">
                                                                {console.log(AllDiagnostics[index].labTestResult)}
                                                                <a href={`${backendUrl}/pdf/${AllDiagnostics[index].labTestResult}`} target='blank'>
                                                                    <button disabled={AllDiagnostics[index].labTestResult === undefined ? true : false}>
                                                                        <img src={PrintImg} alt="" />
                                                                        <span>Print Doc.</span>
                                                                    </button>
                                                                </a>
                                                            </div>

                                                        </li>


                                                    )

                                                })
                                            }



                                        </ul>
                                        {FilterDiagnostics && FilterDiagnostics.length === 0 ? <div className='blankAppointment'>
                                            No Diagnostic EMR
                                        </div> : ""}
                                        {FilterDiagnostics === null ? <div className='blankAppointment'>
                                            Please wait ...
                                        </div> : ""}


                                    </div>
                                    <div className="singleEMRViewAllBtnSection">
                                        <button disabled={true}>
                                            <span onClick={() => SetFilterDiagnostics(Data.diagnostics)}>View All</span>
                                            <img src={ArrowRight} alt="" />
                                        </button>
                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}

export default EMR