import React from 'react'

const Diagnostics = ({ FormData }) => {
    return (
        <>
            <div className="flex-col g-05">
                <label htmlFor="email">
                    Diagnostics Email
                </label>
                <input type="email" name='email' placeholder='Enter diagnostic email' value={FormData.FormData.email} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value,hospitalId: localStorage.getItem("hospitalId") })} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="phone">
                    Diagnostics Phone No
                </label>
                <input type="number" name='phone' placeholder='Enter diagnostic phone no' value={FormData.FormData.phone} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
            </div>

            <div className="flex-col g-05">
                <label htmlFor="speciality">
                    Diagnostics Profile Password
                </label>
                <input type="password" name='password' placeholder='Enter diagnostic profile Password' value={FormData.FormData.password} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
            </div>

        </>

    )
}

export default Diagnostics