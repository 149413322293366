/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import "./Prescription.css"
import jsPDF from "jspdf"
import html2canvas from 'html2canvas'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import Loader from '../Loader/Loader'
import axios from 'axios'
import ImgI from "../../Assets/Images/Avatar.png"
const PdfDocument = () => {
    const PDFRef = useRef()
    const location = useLocation()
    const navigate = useNavigate()
    const [Download, SetDownload] = useState(false)
    const [Prescription, SetPrescription] = useState(null)
    const [HospitalDetails, SetHospitalDetails] = useState(null)
    const [Medicine, SetMedicine] = useState(null)
    const PdfGen = () => {

        SetDownload(true)
        const input = PDFRef.current;
        Prescription && HospitalDetails && html2canvas(input).then((canvas) => {
            const imageData = canvas.toDataURL("svg")
            const PDF = new jsPDF("p", "mm", "a4")
            const PdfHeight = PDF.internal.pageSize.getHeight()
            const PdfWidth = PDF.internal.pageSize.getWidth()
            const imgwidth = canvas.width
            const imgHeight = canvas.height
            const ratio = Math.min(PdfWidth / imgwidth, PdfHeight / imgHeight)
            const imgX = (PdfWidth - imgwidth * ratio) / 2
            const imgY = 0;
            PDF.addImage(imageData, "png", imgX, imgY, imgwidth * ratio, imgHeight * ratio)
            SetDownload(false)
            console.log(hospitalLogo)
            PDF.save("Prescription.pdf")
        })
    }
    useEffect(() => {
        if (location.state.download) {
            getIcon()
        }
        // eslint-disable-next-line
    }, [location.state.download])
    const getIcon = () => {
        const BACKENDURL = process.env.REACT_APP_BACKEND_URL
        axios.post(process.env.REACT_APP_BACKEND_URL + "/hospital/get", { hospitalId: localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetHospitalDetails(res.data)
            console.log(res.data)
            axios.post(BACKENDURL + "/patients/prescription/get", {
                hospitalId: localStorage.getItem("hospitalId"),
                patientId: location.state.data.patientInfo._id,
                appointmentId: location.state.data._id
            }, {
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then((res) => {
                SetPrescription(res.data)
                console.log(location.state.data._id, location.state.data.patientInfo._id)
                console.log(res.data)

            }).catch((err) => {
                console.log(err)
            })
        }).catch((err) => {
            console.log(err)
        })
    }
    const AppointmentDate = Prescription && Prescription.patientOtherInfo.createdAt.toString().split("T")

    const hospitalLogo = HospitalDetails && process.env.REACT_APP_BACKEND_URL + "/image/" + HospitalDetails.imageUrl;
    return (
        <>

            {Prescription === null ?
                <div className="loaderForm" style={{ position: 'absolute', width: "100%", height: "100%", background: "#80808063", zIndex: 99999 }}>
                    <Loader />
                </div> : ""
            }
            {Prescription && <div className="flexRow g-2 w-100 justify-center align-center " style={{ paddingTop: "1rem" }}>
                <div className="prescreptionBtns">
                    <button onClick={() => navigate('/user/patientlist')}>
                        Back To Consultation
                    </button>
                </div>
                <ReactToPrint
                    trigger={() => {
                        return <div className="prescreptionBtns">
                            <button>
                                Print
                            </button>
                        </div>;
                    }}
                    content={() => PDFRef.current}
                />
                <div className="prescreptionBtns">
                    <button onClick={() => PdfGen()}>
                        Download
                    </button>
                </div>
            </div>}
            {
                Download ?
                    <Loader />
                    : ""
            }
            {Prescription &&
                <section className='prescreptionPage flexRow g-2' id='content' ref={PDFRef} >
                    <div className="prscriptionBody" id='test'>
                        <div className="prescriptionHeader flexRow justify-between align-center">


                            <div className="flex-col ">
                                <p>Dr.{Prescription && Prescription.DoctorInfo.name}</p>
                                <p>{Prescription && Prescription.DoctorInfo.specialization}</p>
                                <p>License No. :{Prescription && Prescription.DoctorInfo.licenseNo}</p>

                            </div>
                            <div className="flex-col g-05 flex-end">
                                <div className="logo">
                                    {/* <img ref={hospitalLogoRef} src={hospitalLogo && hospitalLogo} alt="" /> */}
                                    <img src={ImgI} alt="" />
                                </div>
                                <div className="flexRow">
                                    <strong>Date:</strong>
                                    {Prescription && AppointmentDate[0]}
                                </div>
                                <div className="flexRow">
                                    <strong>
                                        Pat Id:
                                    </strong>
                                    {Prescription && Prescription.patientBasicInfo.uhid}
                                </div>
                            </div>


                        </div>
                        <div className="flex-col prescriptionBody g-2">
                            <div className="flex-col">

                                <div className="flexRow">
                                    <strong>
                                        Name :&nbsp;
                                    </strong>
                                    <span>
                                        {Prescription && (Prescription.patientBasicInfo.patientFirstName + " " + Prescription.patientBasicInfo.patientLastName).toUpperCase()}
                                    </span>
                                </div>

                                <div className="flexRow">
                                    <strong>
                                        Age :&nbsp;
                                    </strong>
                                    <span>
                                        {Prescription && Prescription.patientBasicInfo.patientAge === "" ? "N/A" : Prescription && Prescription.patientBasicInfo.patientAge}
                                    </span>
                                </div>


                                <div className="flexRow">
                                    <strong>
                                        Height :&nbsp;
                                    </strong>
                                    <span>
                                        {Prescription && Prescription.patientOtherInfo !== null && Prescription.patientOtherInfo.vitals !== undefined && Prescription.patientOtherInfo.vitals !== null ? Prescription.patientOtherInfo.vitals.patientHeight : "N/A"}
                                    </span>
                                </div>
                                <div className="flexRow">
                                    <strong>
                                        Weight : &nbsp;
                                    </strong>
                                    <span>
                                        {Prescription && Prescription.patientOtherInfo !== null && Prescription.patientOtherInfo.vitals !== undefined ? Prescription.patientOtherInfo.vitals.patientWeight : "N/A"}
                                    </span>
                                </div>
                            </div>

                            {/* <div className="flexRow justify-between w-100">
                            <strong className='w-50'>
                                Diagnosis
                            </strong>
                            <span className='w-50'>
                                Depression
                            </span>
                        </div>
                        <div className="flexRow justify-between w-100">
                            <strong className='w-50'>
                                Description
                            </strong>
                            <span className='w-50'>
                                Low mood, irritability, loss of interest, feeling lonely, not getting along with hostel friends, over thinking, decreased sleep
                            </span>
                        </div> */}
                            <hr />

                            {/* <table className='prescriptionTable'>
                                <thead>
                                    <tr>
                                        <th>Others Info</th>
                                        <th>Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>RiskFactor</td>
                                        <td>
                                            {
                                                Prescription && Prescription.patientOtherInfo.riskFactor.length !== 0 ? Object.keys(Prescription.patientOtherInfo.riskFactor).map((data) => {

                                                    return data !== "haveRiskFactor" && data !== "haveSurgery" ? data + "," : ""
                                                }) : ""
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Medical Problem</td>
                                        <td>
                                            {
                                                Prescription && Prescription.patientOtherInfo.medicalProblem.length !== 0 ? Object.keys(Prescription.patientOtherInfo.medicalProblem).map((data) => {

                                                    return data !== "haveRiskFactor" && data !== "haveSurgery" ? data + "," : ""
                                                }) : ""
                                            }
                                        </td>
                                    </tr>

                                </tbody>
                            </table> */}
                            <table className='prescriptionTable'>
                                <thead>
                                    <tr>
                                        <th>Medicine Name</th>
                                        <th>Dose</th>
                                    </tr>
                                </thead>
                                <tbody className='medicineTableBody'>

                                    {
                                        Prescription && Prescription.patientOtherInfo.medicine.map((data, index) => {
                                            return <tr className='w-100' key={index}>
                                                <td className='w-50'>{data.name}</td>
                                                <td className='w-50'>{data.dose}</td>
                                            </tr>
                                        })
                                    }

                                </tbody>
                            </table>
                            <hr />

                            <div className="flexRow g-2">
                                <span className='bold'>
                                    Lab Test
                                </span>
                                <span>
                                    {
                                        Prescription && Prescription.patientOtherInfo.diagnostics === undefined ? "N/A" : Prescription && Object.keys(Prescription.patientOtherInfo.diagnostics).map((data) => {
                                            return data !== "haveRiskFactor" && data !== "allergieNotes" && data !== "haveSurgery" ? data + "," : ""
                                        })
                                    }
                                </span>
                            </div>
                            <hr />
                            <div className="flexRow g-2">
                                <span className='bold'>
                                    Doctor’s Advice
                                </span>
                                <ul className='list'>
                                    {Prescription && Prescription.patientOtherInfo.diagnosis}
                                    {Prescription && Prescription.patientOtherInfo.diagnosis === "" ? "Doctor was not adviced any thing" : ""}
                                </ul>
                            </div>
                            <div className="flex-col color-gray">
                                <div>
                                    {Prescription && Prescription.DoctorInfo.name}

                                </div>
                                <div>
                                    {Prescription && Prescription.DoctorInfo.specialization}
                                </div>
                                <a href="http://albahospitals.in/">
                                    albahospitals.in
                                </a>
                            </div>

                        </div>
                    </div>


                </section>
            }
        </>
    )
}

export default PdfDocument