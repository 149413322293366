import React, { useState } from 'react'
import "../../CSS/PatientForm/Medicine.css"
import plusIcon from "../../Assets/SVG/Billing/Add.svg"
import removeIcon from "../../Assets/SVG/Billing/YetToPay.svg"
import { ToastContainer, toast } from 'react-toastify'
const Medicine = ({ PatientData }) => {
    const [InputCount, SetInputCount] = useState(1)
    const AddNewInput = () => {
        if (InputCount === 0) {
            let parentElm = document.createElement("tr")
            parentElm.classList.add("medicineData")
            let Input1 = document.createElement("input")
            let Input2 = document.createElement("input")
            Input1.placeholder = "Enter Medicine Name"
            Input2.placeholder = "Enter Medicine Dose"
            let AddBtn = document.createElement("button")
            AddBtn.classList.add("changeAddBtn")
            let AddImg = document.createElement("img")
            AddImg.src = plusIcon
            let AddSpan = document.createElement("span")
            AddBtn.append(AddImg, AddSpan)
            AddBtn.addEventListener("click", (e) => {
                InputChanger(Input1, Input2, AddBtn)
            })
            parentElm.append(Input1, Input2, AddBtn)
            let addNewMedicineSection = document.getElementById("addNewMedicineSection")
            addNewMedicineSection.append(parentElm)
            SetInputCount(1)
        } else {
            toast.warning("One Field exist");
        }
    }
    const InputChanger = (input1, input2, removeBtn) => {
        const mainParent = input1.parentNode;
        console.log(mainParent)
        const value1 = input1.value
        const value2 = input2.value
        const td1 = document.createElement("td")
        const td2 = document.createElement("td")
        const removeButton = document.createElement("button")
        removeButton.classList.add("remove-button")
        const removeImg = document.createElement("img")
        removeImg.src = removeIcon
        const removeSpan = document.createElement("span")
        removeButton.append(removeImg, removeSpan)
        td1.innerHTML = value1
        td2.innerHTML = value2
        removeButton.addEventListener("click", (e) => {
            removeMedicineState(value1)
            RemoveElement(td1);
        })
        mainParent.replaceChild(td1, input1)
        mainParent.replaceChild(td2, input2)
        mainParent.replaceChild(removeButton, removeBtn)

        addMedicineState(value1, value2)
        SetInputCount(0)
    }
    const removeMedicineState = (name) => {
       PatientData.SetFormData(prevFormData => {
            const updatedMedicine = prevFormData.medicine.filter(item => item.name !== name);
            return { ...prevFormData, medicine: updatedMedicine };
        });
    }
    const addMedicineState = (name, dose) => {
        const newMedicine = { name: name, dose: dose };
        PatientData.SetFormData(prevFormData => {
            const updatedMedicine = [...prevFormData.medicine, newMedicine];
            return { ...prevFormData, medicine: updatedMedicine };
        });
    }
    const RemoveElement = (elm) => {
        console.log(elm.parentNode.parentNode)
        elm.parentNode.parentNode.removeChild(elm.parentNode)
    }
    return (
        <>

            <ToastContainer />
            <section className='medicineSection'>
                <div className="formHeading fit-width">
                    Medicine
                </div>
                <div className="medicineAddBody flexRow g-2">

                    <div className="flex w-30r">
                        <table className='h-100 w-100 flex-col g-1'>
                            <thead className='w-100'>
                                <tr className='flexRow justify-between align-center w-100 bottom-border-dark'>
                                    <th className='color-border bold'>Medicine Name</th>
                                    <th className='color-border bold'>Medicine Dose</th>
                                    <th className='color-border bold'>Action</th>
                                </tr>
                            </thead>

                            <tbody id='addNewMedicineSection'>
                                {/* {
                                    PatientData && PatientData.FormData.medicine.map((data) => {
                                        return (
                                            <tr className='medicineData'>
                                                <td>{data.name}</td>
                                                <td>{data.dose}</td>
                                                <button className='remove-button' onClick={(e) => removeMedicineState(e.target.parentNode.previousSibling.previousSibling)}>
                                                    <img src={removeIcon} alt='' />
                                                    <span></span>
                                                </button>
                                            </tr>
                                        )
                                    })
                                } */}

                                <tr className='medicineData'>
                                    <input type="text" placeholder='Enter Medicine Name' />
                                    <input type="text" placeholder='Enter Medicine Dose' />
                                    <button className='changeAddBtn' onClick={(e) => InputChanger(e.target.parentNode.previousSibling.previousSibling, e.target.parentNode.previousSibling, e.target.parentNode)}>
                                        <img src={plusIcon} alt='' />
                                        <span></span>
                                    </button>
                                </tr>



                            </tbody>
                        </table>
                    </div>
                    <div className="flex w-50 ">
                        <button onClick={() => AddNewInput()} className='basic-button flexRow g-05 align-center blue-back c-white'>
                            <img src={plusIcon} alt="" />
                            <span className='c-white'>Add New Medicine</span>
                        </button>
                    </div>


                </div>


            </section>

        </>
    )
}

export default Medicine