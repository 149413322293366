import React from 'react'
import PatientContact from '../Patient/PatientContact'
import EnvelopImg from "../../Assets/SVG/patientSVG/Envelop.svg"
import PhoneImg from "../../Assets/SVG/patientSVG/Contact.svg"
import CalenderImg from "../../Assets/SVG/patientSVG/ContactCalender.svg"
import DoctorImg from "../../Assets/SVG/patientSVG/Doctor.svg"
import { useNavigate } from 'react-router-dom'

const AppointmentPatientCard = ({ AppointmentDetials }) => {
    const navigate = useNavigate()
    return (
        <>
            <div className="patientCards">
                <div className="patientcardsLeft">

                    <div className="patientCardUpperTop">

                        <h3>{AppointmentDetials.patientInfo.patientFirstName + " " + AppointmentDetials.patientInfo.patientLastName}</h3>
                        <p>UHID: {AppointmentDetials.patientInfo.uhid}</p>
                    </div>
                    <div className="patientContactDetails">

                        {
                            AppointmentDetials.patientInfo.patientEmail === "" ?
                                <PatientContact contactSvg={EnvelopImg} ContactTitle="Email" ContactCredentials={"N/A"} key="email" />
                                :
                                <PatientContact contactSvg={EnvelopImg} ContactTitle="Email" ContactCredentials={AppointmentDetials.patientInfo.patientEmail} key="email" />

                        }
                        {
                            AppointmentDetials.patientInfo.phoneno === "" ?
                                <PatientContact contactSvg={PhoneImg} ContactTitle="Phone" ContactCredentials={"N/A"} key="phone" />
                                :
                                <PatientContact contactSvg={PhoneImg} ContactTitle="Phone" ContactCredentials={AppointmentDetials.patientInfo.phoneno} key="phone" />
                        }
                        {
                            AppointmentDetials.date === "" ?
                                <PatientContact contactSvg={CalenderImg} ContactTitle="Date" ContactCredentials={"N/A"} key="Date" />
                                :
                                <PatientContact contactSvg={CalenderImg} ContactTitle="Date" ContactCredentials={AppointmentDetials.date} key="Date" />
                        }
                        {
                            AppointmentDetials.doctorInfo === "" ?
                                <PatientContact contactSvg={DoctorImg} ContactTitle="Doctor" ContactCredentials={"N/A"} key="Doctor" />
                                :
                                <PatientContact contactSvg={DoctorImg} ContactTitle="Doctor" ContactCredentials={AppointmentDetials.doctorInfo.name} key="Doctor" />
                        }



                    </div>
                </div>
                <div className="patientcardsBtns">

                    <button type='button'>
                        {AppointmentDetials.status}
                    </button>
                    <button type='button' onClick={() => navigate("/user/patient/profile", { state: { phone: AppointmentDetials.patientInfo.phoneno } })}>
                        View Details
                    </button>

                </div>

            </div >


        </>
    )
}

export default AppointmentPatientCard