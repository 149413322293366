import React from 'react'
import "../../CSS/Appointment/AppointmentSelection.css"
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import ByDoctorImg from "../../Assets/SVG/Appointment/Anesthesiology.svg"
import DoctorImg from "../../Assets/SVG/Appointment/Cancer.svg"
import { Link, useLocation } from 'react-router-dom'
import backBtn from '../../Assets/SVG/back.svg'
function AppointmentSelection() {
    const location = useLocation();
    return (
        <>
            <div className="ParentRapper">
                <TopBar pageName="Appointment" />
                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <div className="AppointmentSelection">
                            <h3 className="pageTitle">Choose Your Specialist</h3>
                            <div className="pagePath">
                                <img src={backBtn} alt="" onClick={() => window.history.back()}/>
                                Dashboard / patient-Profile /appointment
                            </div>

                            <div className="DataCols">
                                <Link to={"/user/patient/appointment/specialist"} state={{ appointType: "specialist", dept: "single", id: location.state.id }} className="appointmentBookingFilter">
                                    <img src={ByDoctorImg} alt="" />
                                    <p>Speciality</p>
                                </Link>





                                <Link to={"/user/patient/appointment/doctorlist"} state={{ appointType: "doctor", dept: "all", id: location.state.id }} className="appointmentBookingFilter">
                                    <img src={DoctorImg} alt="" />
                                    <p>Doctors</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div >



        </>
    )
}

export default AppointmentSelection