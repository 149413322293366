import { React } from 'react'
import "../../CSS/PatientForm/Vitals.css"
const Vitals = ({ Others, PatientData }) => {
    return (
        <>

            <div className="patientsFormVitalsSection">
                <div className="formHeading">
                    {Others}
                </div>
                <form onSubmit={(e) => e.preventDefault()} className="basicform">

                    <div className="DataRows">
                        <label htmlFor="Blood Sugar">Spot Blood Sugar ( mg/dl )</label>
                        <input type="text" name="suger" placeholder='enter in mg/dl' value={PatientData.FormData.vitals.suger || ""} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })} />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="Ofc">Ofc (cm)</label>
                        <input type="text" name="ofc" placeholder='Enter in cm' value={PatientData.FormData.vitals.ofc || ""} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })} />
                    </div>


                    <div className="DataRows">
                        <label htmlFor="height">Height (cm)</label>
                        <input type="text" name="patientHeight" placeholder='Enter in cm' value={PatientData.FormData.vitals.patientHeight} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="PEFR">PEFR (L/min)</label>
                        <input type="text" name="patientPefr" placeholder='Enter in L/dl' value={PatientData.FormData.vitals.patientPefr} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="BMI">BMI (kh/m³)</label>
                        <input type="text" name="patientBmi" placeholder='Enter in kh/m³' value={PatientData.FormData.vitals.patientBmi} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="bodyFat">Body Fat</label>
                        <input type="text" name="patientBodyFat" placeholder='Enter in %' value={PatientData.FormData.vitals.patientBodyFat} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="paulse">Pulse ( \min)</label>
                        <input type="text" name="patientPaulse" placeholder='Enter in min' value={PatientData.FormData.vitals.patientPaulse} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="upperSegment">Upper Segment (cm)</label>
                        <input type="text" name="patientUpperSegment" placeholder='Enter in cm' value={PatientData.FormData.vitals.patientUpperSegment} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="lowerSegment">Lower Segment (cm)</label>
                        <input type="text" name="patientLowerSegment" placeholder='Enter in cm' value={PatientData.FormData.vitals.patientLowerSegment} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="weight">Weight (kg)</label>
                        <input type="text" name="patientWeight" placeholder='Enter Weight in kg' value={PatientData.FormData.vitals.patientWeight} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="diastolic">Diastolic (m/mHG)</label>
                        <input type="text" name="patientDiastolic" placeholder='Enter m/mHG' value={PatientData.FormData.vitals.patientDiastolic} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="temperature">Temperature (°c)</label>
                        <input type="text" name="patientTemperature" placeholder='Enter in temperature' value={PatientData.FormData.vitals.patientTemperature} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>
                    <div className="DataRows">
                        <label htmlFor="systolic">Systolic (m/mHG)</label>
                        <input type="text" name="patientSystolic" placeholder='Enter in m/mHG' value={PatientData.FormData.vitals.patientSystolic} onChange={(e) => PatientData.SetFormData({
                            ...PatientData.FormData,
                            vitals: {
                                ...PatientData.FormData.vitals, [e.target.name]: e.target.value
                            }
                        })}  />
                    </div>







                </form>

            </div>

        </>
    )
}

export default Vitals