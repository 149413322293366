import React, { useEffect, useState } from 'react'
import List from "../../../Assets/SVG/Doctor/ListI.svg"
import Person from "../../../Assets/SVG/Doctor/User.svg"
import Clock from "../../../Assets/SVG/Doctor/Timer.svg"
import Action from "../../../Assets/SVG/Doctor/Action.svg"

import "../../../CSS/Profiles/Doctor/AppointmentList.css"
import { ToastContainer } from 'react-toastify'
import { Link } from 'react-router-dom'
import axios from 'axios'
import BlankAlert from '../../BlankComp/BlankAlert'
import Loader from '../../Loader/Loader'

function AllAppointmentsListTable({ filterDate }) {
    const [TotalAppointments, SetTotalAppointments] = useState(null)
    const [FilterAppointments, SetFilterAppointments] = useState(null)

    useEffect(() => {
        if (filterDate !== "") {
            const filter = TotalAppointments && TotalAppointments.filter((data) => {
                const fdate = new Date(filterDate)
                const dbdate = new Date(data.date)
                dbdate.setHours(0, 0, 0, 0)
                return fdate >= dbdate && fdate <= dbdate
            })
            SetFilterAppointments(filter)
        } else {
            SetFilterAppointments(TotalAppointments)
        }

        // eslint-disable-next-line
    }, [filterDate])

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + "/appointment/doctor/get", {
            headers: {
                token: window.localStorage.getItem("token")
            }

        }).then((res) => {
            SetTotalAppointments(res.data)
            SetFilterAppointments(res.data)

        }).catch((err) => {
            console.log(err.response.data)
        })
    }, [])


    return (
        <>
            <ToastContainer />
            <div className="consultAdmitPatientList">


                <table className="docConSultedPatientList">
                    <thead>
                        <tr>
                            <th>
                                <span>Status</span>
                            </th>
                            <th>
                                <img src={List} alt="" />
                                <span>Sl no.</span>
                            </th>
                            <th>
                                <img src={Person} alt="" />
                                <span>Name</span>
                            </th>
                            <th>
                                <img src={Clock} alt="" />
                                <span>Visiting Time</span>
                            </th>
                            <th>
                                <img src={Action} alt="" />
                                <span>Action</span>
                            </th>
                        </tr>
                    </thead>
                    <div className="patientConsultedListBody">


                        <tbody>
                            {
                                FilterAppointments &&
                                FilterAppointments.map((data, count) => {
                                    return (

                                        <tr key={count}>
                                            {
                                                data.status.toLowerCase() === "pending" ?
                                                    <td
                                                        className='patientListStatus'
                                                        value={"Pending"} style={{ "--status-backcolor": "rgb(255, 246, 233)", "--status-color": "rgb(181, 133, 11)" }}>
                                                        Pending</td>
                                                    : ""
                                            }
                                            {
                                                data.status.toLowerCase() === "approved" ?
                                                    <td
                                                        className='patientListStatus'
                                                        value={"Approved"} style={{ "--status-backcolor": "rgb(220, 220, 253)", "--status-color": "rgb(34, 32, 112)" }}>
                                                        Approved</td>

                                                    : ""
                                            }
                                            {
                                                data.status.toLowerCase() === "consulted" ?
                                                    <td
                                                        className='patientListStatus'
                                                        value={"Consulted"} style={{ "--status-backcolor": "rgb(237, 255, 234)", "--status-color": "rgb(22, 94, 61)" }}>Consulted</td>
                                                    : ""
                                            }
                                            {
                                                data.status.toLowerCase() === "reject" ?
                                                    <td
                                                        className='patientListStatus'
                                                        value={"Rejected"}
                                                        style={{
                                                            "--status-backcolor": "rgb(255, 234, 234)",
                                                            "--status-color": "rgb(184, 49, 49)"
                                                        }}>Rejected</td>
                                                    : ""
                                            }
                                            <td>{count + 1}</td>
                                            <td>{data.patientInfo.patientFirstName + " " + data.patientInfo.patientLastName}</td>
                                            <td>{data.time}</td>
                                            <td className='flex-col align-center'>
                                                {/* <button className='allAppointmentTableActionBtn' value={"uploadPres"}>Upload Pres.</button> */}
                                                <Link to={"/user/patient/profile"} state={{ phone: data.patientInfo.phoneno, uhid: data.patientInfo.uhid }} className='allAppointmentTableActionBtn' value={"Reject"}>View Profile</Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </div>
                    {
                        FilterAppointments && FilterAppointments.length === 0 ? <BlankAlert msg={"You have no patient in this date"} /> : ""
                    }
                    {
                        FilterAppointments === null ? <Loader /> : ""
                    }
                </table>
            </div>

        </>
    )
}

export default AllAppointmentsListTable