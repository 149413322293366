import React from 'react'
import "../../CSS/Patient/OverViewCards.css"
import UpperGraphIndicator from "../../Assets/SVG/patientSVG/UpperGraphIndicator.svg"
import LowerGraphIndicator from "../../Assets/SVG/patientSVG/LowerGraphIdicator.svg"
const OverViewCard = (prop) => {
    return (
        <>
            <div className="overViewCards">
                <div className="overviewUpperCardSec">
                    <span style={{ "--patient-overview-card-svg-back": prop.svgBackColor }}>
                        <img src={prop.SvgImg} alt="" />
                    </span>
                    <h3>{prop.OverViewTitle}</h3>
                </div>
                <div className="overviewCardBottom">
                    <h2>{prop.OverViewCount}</h2>
                    <div className="anlysisGraphIndicatorSec">
                        <img src={(prop.indicator === "low") ? LowerGraphIndicator : UpperGraphIndicator} alt="" />
                        <span style={{ "--overViewCardIndicatorColor": (prop.indicator === "low") ? "#E13030" : "#0A9D4C" }} className='indicatorData'>{prop.indicatorData}%</span>
                        <span>from last week</span>
                    </div>
                </div>

            </div>

        </>
    )
}

export default OverViewCard