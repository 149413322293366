import { React } from 'react'
import "../../CSS/PatientForm/FamilyInformation.css"
import CrossImg from "../../Assets/SVG/patientSVG/CrossCircle.svg"
import { ReactComponent as AddMoreImg } from "../../Assets/SVG/patientSVG/AddMore.svg"
import ArrowRightImg from "../../Assets/SVG/Login/ArrowRight.svg"
import ArrowLeft from "../../Assets/SVG/Patient/ArrowLeft.svg"

const FamilyInformation = ({ Others, PatientData, setPageStatus }) => {


    const AddAnItem = (e) => {
        let ParrentDiv = document.createElement("div")
        let InputHide = document.createElement("img")
        InputHide.src = CrossImg
        InputHide.addEventListener('click', (c) => {
            hideTag(c)
        });


        let MedicalproblemExtra = document.createElement("input")
        MedicalproblemExtra.id = "extraInputCreate"
        let prevMedicalSelector = document.getElementsByClassName("prevMedicalSelector")[0]
        ParrentDiv.append(MedicalproblemExtra, InputHide)
        prevMedicalSelector.insertBefore(ParrentDiv, e.target.parentNode);
        MedicalproblemExtra.addEventListener("keyup", (key) => {
            if (key.key === "Enter") {
                let CDiv = document.createElement("div")
                let CSpan = document.createElement("span")
                let CInput = document.createElement("input")

                CDiv.classList.add("toggleInputBtnDiv")
                CInput.addEventListener("change", (e) => {
                    // PatientData.SetPatientData({ ...FormData, [e.target.name]: e.target.checked })

                    PatientData.SetFormData({
                        ...PatientData.FormData,
                        familyInfo: {
                            ...PatientData.FormData.familyInfo, [e.target.name]: e.target.checked
                        }
                    })

                })
                let CImg = document.createElement("img")
                CImg.src = CrossImg
                CImg.classList.add("tagsRemover")
                CInput.type = "checkbox"
                CInput.name = document.getElementById("extraInputCreate").value
                CInput.value = document.getElementById("extraInputCreate").value
                CSpan.innerHTML = document.getElementById("extraInputCreate").value
                CDiv.append(CSpan, CInput, CImg)
                prevMedicalSelector.insertBefore(CDiv, e.target.parentNode.previousSibling);

                CImg.addEventListener('click', (e) => {
                    hideTag(e);
                });
                ParrentDiv.parentNode.removeChild(ParrentDiv)
            }
        })

    }
    const hideTag = (e) => {
        PatientData.SetFormData({
            ...PatientData.FormData,
            familyInfo: {
                ...PatientData.FormData.familyInfo, [e.target.name]: false
            }
        })
        e.target.parentNode.parentNode.removeChild(e.target.parentNode)

    }
    const AddNote = (e) => {
        let Notename = prompt("Enter Field Name")
        if (Notename !== "" && Notename !== null) {
            const NoteAdd = document.createElement("textarea")
            NoteAdd.cols = "40"
            NoteAdd.rows = "5"
            NoteAdd.name = Notename
            NoteAdd.addEventListener("input", (e) => {
                PatientData.SetFormData({
                    ...PatientData.FormData,
                    familyInfo: {
                        ...PatientData.FormData.familyInfo, [e.target.name]: e.target.value
                    }
                })
            })


            e.target.parentNode.parentNode.children[0].append(NoteAdd)
        }
    }


    return (
        <>
            <div className='PatientBasicForm'>
                <div className="familyInformation">

                    <div className="formHeading">
                        {Others}
                    </div>
                    <div className="scrollAbleDiv h-100">


                        <form onSubmit={(e) => e.preventDefault()} className="patientForm">
                            <div className="familyDetailConfirmation">
                                <label htmlFor="Family Confermation">  Do patient have any close kins</label>
                                <input type="radio" name="kins" value="Yes" className='kinsDetailsConfirmationBtn' checked={PatientData.FormData.familyInfo.kins ? true : false} onChange={(e) => PatientData.SetFormData({
                                    ...PatientData.FormData,
                                    familyInfo: {
                                        ...PatientData.FormData.familyInfo, [e.target.name]: true
                                    }
                                })} />
                                <input type="radio" name="kins" value="No" className='kinsDetailsConfirmationBtn' checked={PatientData.FormData.familyInfo.kins ? false : true} onChange={(e) => PatientData.SetFormData({
                                    ...PatientData.FormData,
                                    familyInfo: {
                                        ...PatientData.FormData.familyInfo, [e.target.name]: false
                                    }
                                })} />
                            </div>
                            {
                                PatientData.FormData.familyInfo.kins ?
                                    <div className="gridEqual">
                                        <div className="DataRows">
                                            <label htmlFor="Kins Name">First Name</label>
                                            <input type="text" name="patientKinsFirstName" id="patientCountry" onChange={(e) => PatientData.SetFormData({
                                                ...PatientData.FormData,
                                                familyInfo: {
                                                    ...PatientData.FormData.familyInfo, [e.target.name]: e.target.value
                                                }
                                            })} placeholder='Please enter  first name' />
                                        </div>
                                        <div className="DataRows">
                                            <label htmlFor="Phone Number">Phone Number</label>
                                            <input type="number" name="patientKinsNumber" id="patientKinsNumber" value={PatientData.FormData.familyInfo.patientKinsNumber} onChange={(e) => PatientData.SetFormData({
                                                ...PatientData.FormData,
                                                familyInfo: {
                                                    ...PatientData.FormData.familyInfo, [e.target.name]: e.target.value
                                                }
                                            })} placeholder='Please enter phone number' />
                                        </div>
                                        <div className="DataRows">
                                            <label htmlFor="Last Name">Last Name</label>
                                            <input type="text" name="patientKinsLastName" id="patientKinsLastName" value={PatientData.FormData.familyInfo.patientKinsLastName} onChange={(e) => PatientData.SetFormData({
                                                ...PatientData.FormData,
                                                familyInfo: {
                                                    ...PatientData.FormData.familyInfo, [e.target.name]: e.target.value
                                                }
                                            })} placeholder='Please enter  last name' />
                                        </div>
                                        <div className="DataRows">
                                            <label htmlFor="Relation With">Relation With</label>
                                            <select name="patientRelationship" value={PatientData.FormData.familyInfo.patientRelationship} onChange={(e) => PatientData.SetFormData({
                                                ...PatientData.FormData,
                                                familyInfo: {
                                                    ...PatientData.FormData.familyInfo, [e.target.name]: e.target.value
                                                }
                                            })} id="patientRelationship" required>
                                                <option value="">Select :</option>
                                                <option value="Father">Father</option>
                                                <option value="Mother">Mother</option>
                                                <option value="Friends">Friends</option>
                                                <option value="Others">Others</option>
                                            </select></div>
                                        <div className="DataRows">
                                            <label htmlFor="Email Address">Email Address</label>
                                            <input type="email" name="patientKinsEmail" value={PatientData.FormData.familyInfo.patientKinsEmail} onChange={(e) => PatientData.SetFormData({
                                                ...PatientData.FormData,
                                                familyInfo: {
                                                    ...PatientData.FormData.familyInfo, [e.target.name]: e.target.value
                                                }
                                            })} id="patientKinsEmail" placeholder='Please enter the email address' />
                                        </div>
                                    </div> : ""}{
                                PatientData.FormData.familyInfo.kins ?


                                    <div className="familyHistorySection">
                                        <h3>Family History</h3>
                                        <label htmlFor="family medical issues">Any family medical issues?</label>
                                        <div className="prevMedicalSelector">

                                            <div className="toggleInputBtnDiv">
                                                <span>Hand Surgery</span>
                                                <input type="checkbox" name='patientHandSurgery' value={"Hand Surgery"} onChange={(e) => PatientData.SetFormData({
                                                    ...PatientData.FormData,
                                                    familyInfo: {
                                                        ...PatientData.FormData.familyInfo, [e.target.name]: e.target.checked
                                                    }
                                                })} checked={FormData.patientHandSurgery} />
                                                <img src={CrossImg} className='tagsRemover' onClick={(e) => hideTag(e)} alt='' />
                                            </div>
                                            <div className="toggleInputBtnDiv">
                                                <span>Hyperlipidemia</span>
                                                <input type="checkbox" name='hyperlipidemia' value={"Hyperlipidemia"} onChange={(e) => PatientData.SetFormData({
                                                    ...PatientData.FormData,
                                                    familyInfo: {
                                                        ...PatientData.FormData.familyInfo, [e.target.name]: e.target.checked
                                                    }
                                                })} checked={FormData.hyperlipidemia} />
                                                <img src={CrossImg} className='tagsRemover' onClick={(e) => hideTag(e)} alt='' />
                                            </div>

                                            <button type='button' className='addMoreBtn' onClick={(e) => AddAnItem(e)}>

                                                <AddMoreImg className='addBtnSvg' />
                                                <span>AddMore</span>
                                                <span></span>

                                            </button>
                                        </div>



                                    </div> : ""
                            }{
                                PatientData.FormData.familyInfo.kins ?
                                    <div className="medicalproblemsNotes">
                                        <div className="notesInput">
                                            <textarea name="familyMedicalIssue" id="notesTextarea" cols="40" rows="5" value={PatientData.FormData.familyInfo.familyMedicalIssue} onChange={(e) => PatientData.SetFormData({
                                                ...PatientData.FormData,
                                                familyInfo: {
                                                    ...PatientData.FormData.familyInfo, [e.target.name]: e.target.value
                                                }
                                            })} ></textarea>
                                        </div>


                                        <button type='button' className='addMoreBtn' onClick={(e) => AddNote(e)}>

                                            <AddMoreImg className='addBtnSvg' />
                                            <span>AddMore</span>
                                            <span></span>

                                        </button>
                                    </div> : ""

                            }
                        </form>
                    </div>
                </div>
            </div>
            <div className="patientAddActionButton flex-btn-sec">

                <button onClick={() => setPageStatus({ Basic: true, familyInfo: false, riskFactor: false })}>
                    <img src={ArrowLeft} alt="" />
                    <span>Go Previous</span>
                </button>

                <button onClick={() => setPageStatus({ Basic: false, familyInfo: false, riskFactor: true })}>
                    <span>Go Next</span>
                    <img src={ArrowRightImg} alt="" />
                </button>

            </div>

        </>
    )
}

export default FamilyInformation