/* eslint-disable eqeqeq */
import React from 'react'
import { useNavigate } from 'react-router-dom'

const PatientWellComeLogin = ({ PatientData }) => {
    const navigation = useNavigate()


    const PatientLogin = (e) => {
        e.preventDefault()
        navigation("/user/patient/profile", { state: { "phone": PatientData.phone } })
    }
    return (
        <>
            <form onSubmit={(e) => PatientLogin(e)} className="congratulationForm" >

                <h3 className="formHeading">Patient Added!</h3>
                <div className="patientLoginUserInput PatientUIDPatientLogin">
                    <label htmlFor="PatientId">Patient UID</label>
                    <input type="text" name='patientUID' value={PatientData.uhid} placeholder='Patient Id' />
                </div>
                <div className="patientLoginUserInput">
                    <label htmlFor="Name">Name</label>
                    <input type="text" name='patientName' value={PatientData.name} placeholder='Patient Name' />
                </div>
                <div className="patientLoginUserInput">
                    <label htmlFor="PatientId">Age</label>
                    <input type="text" name='patientAge' value={PatientData.age != "N/A" ? PatientData.age : ""} placeholder='Patient Age' />
                </div>
                <div className="patientLoginUserInput">
                    <label htmlFor="PatientId">Phone No.</label>
                    <input type="number" name='patientPhoneNo' value={PatientData.phone} placeholder='Patient Phone No' />
                </div>

                <button className='patientLoginBtn' type='submit'>
                    Login
                </button>

            </form>



        </>
    )
}

export default PatientWellComeLogin