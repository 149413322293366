import { useState } from "react";
import LoginAuthContext from "./LoginAuthContext";


const LoginAuth = (props) => {
    const [AuthLogin, SetAuthLogin] = useState({
        token:"",
        adminType:"",
        id:"",
        logedin: false
    })
    return (

        <LoginAuthContext.Provider value={{ AuthLogin, SetAuthLogin }}>
            {props.children}
        </LoginAuthContext.Provider>
    )
}


export default LoginAuth;