/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import "../../CSS/Patient/Patient.css"
import OverViewCard from './OverViewCard'
import Calender from "../../Assets/SVG/patientSVG/Calender.svg"
import ArrowRight from "../../Assets/SVG/patientSVG/ArrowRight.svg"
import ArrowLeft from "../../Assets/SVG/patientSVG/ArrowLeft.svg"
import { ReactComponent as SearchIcon } from "../../Assets/SVG/patientSVG/SearchBtn.svg"
import PatientAddImg from "../../Assets/SVG/patientSVG/PersonAdd.svg"
import PatientCard from './PatientCard'
import { Link } from 'react-router-dom'
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import axios from 'axios'
import Loader from '../Loader/Loader'
import backBtn from '../../Assets/SVG/back.svg'
import BlankAlert from '../BlankComp/BlankAlert'



const Patient = () => {
    const [PatientDetails, SetPatientDetails] = useState(null)
    const [PatientFilterDetails, SetPatientFilterDetails] = useState(null)
    const [SearchInput, SetSearchInput] = useState("")
    const [PatientChanger, SetPatientChanger] = useState({
        in: false,
        out: false
    })
    const [Statics, SetStatics] = useState(null)
    const [Searchval, SetSearchval] = useState("")
    const [visibleCount, setVisibleCount] = useState(10);

    useEffect(() => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/getall", { "hospitalId": localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetPatientDetails(res && res.data)
            SetPatientFilterDetails(res && res.data)
        }).catch((err) => {
            console.log(err.response)
        })
        statics()
    }, [])



    useEffect(() => {
        if (SearchInput === "") {
            SetPatientFilterDetails(PatientDetails)
        }

        // eslint-disable-next-line
    }, [SearchInput])

    const Search = (searchInput) => {
        if (searchInput !== "") {
            const filterPatients = PatientDetails && PatientDetails.filter((data) => {
                const fullName = `${data.patientFirstName} ${data.patientLastName}`.toLowerCase();
                const searchLower = searchInput.toLowerCase();

                if (data.uhid.toString() === searchInput) {
                    return true;
                }
                if (fullName.includes(searchLower)) {
                    return true;
                }
                if (data.phoneno.includes(searchInput)) {
                    return true;
                }
                return false;
            });
            SetPatientFilterDetails(filterPatients.length === 0 ? null : filterPatients);
        } else {
            SetPatientFilterDetails(PatientDetails);
        }
    }


    const statics = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/analytics/get", { hospitalId: localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetStatics(res.data)
        }).catch((err) => {
            console.log(err.response)
        })
    }



    const loadMorePatients = () => {
        setVisibleCount(prevCount => prevCount + 10);
    };

    return (
        <>


            <div className="ParentRapper">
                <TopBar pageName="Patient" />

                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <div className="pagePath">
                            <img src={backBtn} alt="" onClick={() => window.history.back()} />
                            Dashboard / patient-portal /
                        </div>
                        <div className="patientPageOverviewHeader">




                            <OverViewCard SvgImg={Calender} indicator="high" OverViewCount={Statics && Statics.todayPatient} indicatorData="4.5" svgBackColor="#ECECFF" OverViewTitle="Appointments Today" />
                            <OverViewCard SvgImg={ArrowRight} indicator="low" OverViewCount={Statics && Statics.inPatient} indicatorData="25" svgBackColor="#E9FFE5" OverViewTitle="In Patient" />
                            <OverViewCard SvgImg={ArrowLeft} indicator="high" OverViewCount={Statics && Statics.outPatient} indicatorData="40" svgBackColor="#FFDFE4" OverViewTitle="Out Patient" />
                        </div>

                        <div className="patientPageAddiButtons" >
                            <span className='patientSearchSec'>
                                <input type="text" placeholder='Search' onChange={(e) => { SetSearchInput(e.target.value); Search(e.target.value); }} />
                                <button type="button" className='searchButton' >
                                    <SearchIcon />
                                </button>
                            </span>
                            <button type='button' className="patientListName">
                                Patient List
                            </button>
                            <Link to={"/user/patient/patientBasicInfo"} className="ExportBtn" type='button' >
                                <img src={PatientAddImg} alt="" />
                                <span>Add Patient</span>
                            </Link>
                            <Link to={"/user/patientLogin"} className="ExportBtn" type='button' >
                                <img src={PatientAddImg} alt="" />
                                <span>Search Patient</span>
                            </Link>
                        </div>
                        <div className="patientList">

                            {
                                PatientFilterDetails &&
                                PatientFilterDetails.slice(0, visibleCount).map((Data, count) => {

                                    return (
                                        <PatientCard PatientData={Data} key={count} />

                                    )
                                })
                            }
                            {PatientDetails === null ?
                                <div className='blankPatientData flex-1'>
                                    <Loader />
                                </div> : ""

                            }
                            {PatientDetails && PatientDetails.length === 0 ?
                                <div className='blankPatientData flex-1'>
                                    <BlankAlert msg="You Have No Patients" />
                                </div> : ""

                            }
                            {PatientFilterDetails && PatientFilterDetails.length === 0 && PatientDetails.length !== 0 ?
                                <div className='blankPatientData flex-1'>
                                    <BlankAlert msg="you have no patient in this id" />
                                </div> : ""

                            }
                            {
                                // Render the "Show More" button if there are more patients to show
                                PatientFilterDetails && PatientFilterDetails.length > visibleCount && (
                                    <div className='showMoreContainer'>
                                        <button className="showMoreBtn" onClick={loadMorePatients}>Show More</button>
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </div>
            </div>



        </>
    )
}

export default Patient