/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import TopBar from "../Bars/TopBar"
import SideBar from "../Bars/SideBar"
import "../../CSS/Appointments/AllAppointments.css"
import { ReactComponent as SearchIcon } from "../../Assets/SVG/patientSVG/SearchBtn.svg"
import { Link } from 'react-router-dom'
import PatientAddImg from "../../Assets/SVG/patientSVG/PersonAdd.svg"
import AppointmentPatientCard from './AppointmentPatientCard'
import axios from 'axios'
import BlankAlert from '../BlankComp/BlankAlert'
import Loader from '../Loader/Loader'
import { ToastContainer, toast } from 'react-toastify'


const AllAppointments = () => {
  const [FilterAppointment, SetFilterAppointment] = useState(null)
  const [Appointment, SetAppointment] = useState(null)
  const [PatientFilterBtn, SetPatientFilterBtn] = useState("")
  const [SearchInput, SetSearchInput] = useState("")
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [visibleCount, setVisibleCount] = useState(10);

  useEffect(() => {
    GetAppointments()
  }, [])
  const GetAppointments = () => {
    axios.post(process.env.REACT_APP_BACKEND_URL + "/appointment/get", { hospitalId: localStorage.getItem("hospitalId") }, {
      headers: {
        token: localStorage.getItem("token")
      }
    }).then((res) => {
      SetAppointment(res.data)
      SetFilterAppointment(res.data)
    }).catch((err) => {
      console.log(err.response.data)
    })
  }

  useEffect(() => {
    if (PatientFilterBtn !== "") {
      if (PatientFilterBtn === "In Patient" || PatientFilterBtn === "Out Patient") {
        const filter = Appointment && Appointment.filter((data) => {
          return data.patientType === PatientFilterBtn
        })
        SetFilterAppointment(filter)
      } else if (PatientFilterBtn === "Attended") {
        const filter = Appointment && Appointment.filter((data) => {
          return data.patientType !== "Reject"
        })
        SetFilterAppointment(filter)
      } else if (PatientFilterBtn === "Not Attended") {
        const filter = Appointment && Appointment.filter((data) => {
          return data.patientType === "Reject"
        })
        SetFilterAppointment(filter)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PatientFilterBtn])


  useEffect(() => {
    if (SearchInput === "") {
      SetFilterAppointment(Appointment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchInput])

  useEffect(() => {
    filterByDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, Appointment]);

  const Search = () => {
    if (SearchInput !== "") {
      const filter = Appointment && Appointment.filter((data) => {
        const patientInfo = data.patientInfo;
        const doctorInfo = data.doctorInfo;

        const fullPatientName = `${patientInfo.patientFirstName} ${patientInfo.patientLastName}`.toLowerCase();

        // Check if the patient name, email, or phone number matches
        const isPatientMatch = fullPatientName.includes(SearchInput.toLowerCase()) ||
          patientInfo.patientEmail.toLowerCase().includes(SearchInput.toLowerCase()) ||
          patientInfo.phoneno.toLowerCase().includes(SearchInput.toLowerCase());

        // Check if the doctor name matches
        const isDoctorMatch = doctorInfo.name.toLowerCase().includes(SearchInput.toLowerCase());

        return isPatientMatch || isDoctorMatch;
      });
      SetFilterAppointment(filter);
    }
  }

  const filterByDate = () => {
    let filter = Appointment;
    if (startDate) {
      filter = filter && filter.filter((data) => new Date(data.createdAt) >= new Date(startDate));
    }
    if (endDate) {
      filter = filter && filter.filter((data) => new Date(data.createdAt) <= new Date(endDate));
    }
    SetFilterAppointment(filter);
  };

  const loadMorePatients = () => {
    setVisibleCount(prevCount => prevCount + 10);
  };


  return (
    <>
      <ToastContainer />
      <div className="ParentRapper">
        <TopBar pageName="Appointments" />

        <div className="pageDetails">
          <SideBar />
          <div className="page">
            <section className="allAppointmentsSection flex-col g-2">
              <div className="flexRow g-1">
                <button type='button' className="patientListName" onClick={() => SetFilterAppointment(Appointment)}>
                  Appointments List
                </button>


                <span className="filterButtons" key={"attend"}>
                  <input type="radio" name='filterbtn' value="Attended" onChange={(e) => SetPatientFilterBtn(e.target.value)} />
                </span>
                <span className="filterButtons" key={"nattend"}>
                  <input type="radio" name='filterbtn' value="Not Attended" onChange={(e) => SetPatientFilterBtn(e.target.value)} />
                </span>
                <span className="filterButtons" key={"Inpa"}>
                  <input type="radio" name='filterbtn' value="In Patient" style={{ "--PatientFilter": "In Patient" }} onChange={(e) => SetPatientFilterBtn(e.target.value)} />
                </span>
                <span className="filterButtons" key={"outpa"}>
                  <input type="radio" name='filterbtn' value="Out Patient" style={{ "--PatientFilter": "Out Patient" }} onChange={(e) => SetPatientFilterBtn(e.target.value)} />
                </span>

                <div className="appointmentListSearchInput" key={"search"}>
                  <input type="text" placeholder='Search Appointment' onChange={(e) => { SetSearchInput(e.target.value); Search() }} />
                  <SearchIcon />
                </div>


                <Link to={"/user/patient"} className="addBtn" type='button' >
                  <img src={PatientAddImg} alt="" />
                  <span>Add Appointment</span>
                </Link>
              </div>
              <div className="flexRow g-2 singleEMRFIlter">
                <div className="flexRow">
                  <label htmlFor="">Date From</label>
                  <input type="date" name='startDate' onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className="flexRow">
                  <label htmlFor="">Date to</label>
                  <input type="date" name='endDate' onChange={(e) => setEndDate(e.target.value)} />
                </div>

              </div>
              <div className="scrollAbleDiv h-100">
                <div className="flex-col g-1 flex-1" style={{ paddingBottom: "1rem" }}>

                  {
                    FilterAppointment && FilterAppointment.slice(0, visibleCount).map((data) => {
                      return <AppointmentPatientCard AppointmentDetials={data} key={data._id} />
                    })
                  }
                </div>
                {
                  FilterAppointment && FilterAppointment.length === 0 ? <BlankAlert msg={"No appointment found"} /> : ""
                }
                {
                  FilterAppointment === null ? <Loader /> : ""
                }
                {
                  // Render the "Show More" button if there are more patients to show
                  FilterAppointment && FilterAppointment.length > visibleCount && (
                    <div className='showMoreContainer'>
                      <button className="showMoreBtn" onClick={loadMorePatients}>Show More</button>
                    </div>
                  )
                }
              </div>
            </section>




          </div>
        </div>
      </div>


    </>
  )
}

export default AllAppointments