import React, { useEffect, useRef, useState } from 'react'
import "./Prescription.css"
import { useLocation, useNavigate } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import Loader from '../Loader/Loader'
import ImgI from "../../Assets/SVG/NavLogo.svg"
import axios from 'axios'

const PdfDocument = () => {
    const PDFRef = useRef()
    const location = useLocation()
    const navigate = useNavigate()
    const [billing, setBilling] = useState([])
    const [patientInfo, setPatientInfo] = useState([])
    let totalBill = 0;
    const BackendUrl = process.env.REACT_APP_BACKEND_URL

    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;


    useEffect(() => {
        const billing = location
        setBilling(billing?.state?.PatientBilling?.bill)

        if (billing?.state?.PatientBilling?.patientId && billing?.state?.PatientBilling?.hospitalId) {
            axios.post(`${BackendUrl}/patients/get/id`, { patientId: billing?.state?.PatientBilling?.patientId }, {
                headers: {
                    token: localStorage.getItem("token")
                }
            }
            ).then((res) => {
                setPatientInfo(res.data);
            }).catch(err => {
                console.log(err);
            })
        }
    }, [BackendUrl, location])


    return (
        <>

            {billing === undefined ?
                <div className="loaderForm" style={{ position: 'absolute', width: "100%", height: "100%", background: "#80808063", zIndex: 99999 }}>
                    <Loader />
                </div> : ""
            }
            {billing && <div className="flexRow g-2 w-100 justify-center align-center " style={{ paddingTop: "1rem" }}>
                <div className="prescreptionBtns">
                    <button onClick={() => navigate('/user/billing/all')}>
                        Back To Billing
                    </button>
                </div>
                <ReactToPrint
                    trigger={() => {
                        return <div className="prescreptionBtns">
                            <button>
                                Print
                            </button>
                        </div>;
                    }}
                    content={() => PDFRef.current}
                />
            </div>}
            {billing &&
                <section className='prescreptionPage flexRow g-2' id='content' ref={PDFRef} >
                    <div className="prscriptionBody" id='test'>
                        <div className="prescriptionHeader flexRow justify-between align-center">

                            {console.log(patientInfo.data)}
                            <div className="flex-col ">
                                <p style={{ paddingBottom: "0.5rem" }}>Patient Name: <b>{patientInfo?.data?.patientFirstName} {patientInfo?.data?.patientLastName}</b></p>
                                <p style={{ paddingBottom: "0.5rem" }}>Patient Mobile No: <b>{patientInfo?.data?.phoneno}</b></p>
                                <p style={{ paddingBottom: "0.5rem" }}>Patient Email id:	<b>{patientInfo?.data?.patientEmail}</b></p>
                                <p>UHID: <b>{patientInfo?.data?.uhid}</b></p>

                            </div>
                            <div className="flex-col g-05 flex-end">
                                <div className="logo">
                                    {/* <img ref={hospitalLogoRef} src={hospitalLogo && hospitalLogo} alt="" /> */}
                                    <img src={ImgI} alt="" />
                                </div>
                                <div className="flexRow">
                                    <strong>Date:</strong>
                                    <strong>{formattedDate}</strong>
                                </div>
                            </div>


                        </div>
                        <div className="flex-col prescriptionBody g-2">
                            <div className="flex-col" style={{ paddingTop: "1.5rem" }}>
                                <table>
                                    <tr>
                                        <th>Subject</th>
                                        <th>Amount (₹)</th>
                                    </tr>

                                    {billing && billing.map((data, index) => {
                                        totalBill = totalBill + Math.floor(parseFloat(data.amount));
                                        return (
                                            <tr key={index}>
                                                <td style={{ textAlign: "center", padding: "0.6rem 0" }}>{data.subject}</td>
                                                <td style={{ textAlign: "center", padding: "0.6rem 0" }}>{data.amount}</td>
                                            </tr>
                                        )
                                    })}
                                </table>
                                <hr />
                                <div className="flexRow" style={{ justifyContent: "space-around", paddingTop: "0.7rem" }}>
                                    <strong>Total:</strong>
                                    <strong>₹{totalBill}</strong>
                                </div>

                            </div>
                        </div>
                    </div>


                </section>
            }
        </>
    )
}

export default PdfDocument