import React, { useEffect, useState } from 'react'
import "../../../CSS/Profiles/Doctor/DoctorProfile.css"
import "../../../CSS/Profiles/Doctor/DoctorCalender.css"
import "../../../CSS/Profiles/Doctor/DocProfilePatientList.css"

import TopBar from '../../Bars/TopBar'
import TopNavBar from './TopNavBar'



import Calendar from 'react-calendar'
import AppointmentAllList from './AppointmentAllList'
import DoctorSideBar from '../../Bars/DoctorSideBar'
import { useLocation } from 'react-router-dom'


const DoctorProfile = () => {
    const date = new Date()
    const location = useLocation()
    const [DateFilter, SetDateFilter] = useState("")
    const [ListPreview, SetListPreview] = useState({
        allList: true,
        singleList: false
    })
    useEffect(() => {
        if (location.state !== "" || location.state !== undefined) {
            SetDateFilter(date)
        }
        // eslint-disable-next-line
    }, [])
    const AllPatientList = () => {
        SetDateFilter("")
    }
    return (
        <>


            <section className="ParentRapper">
                <TopBar pageName={localStorage.getItem("adminType").slice(0, 1).toUpperCase() + localStorage.getItem("adminType").slice(1).toLowerCase()} />

                <div className="pageDetails">
                    <DoctorSideBar />
                    <div className="page">
                        <section className="docProfileMainSection">

                            <TopNavBar />
                            <div className="PatientListAndDateFilter">
                                <div className="doctorprofilePatientList">
                                    <div className="flexRow justify-between">

                                        <h3>Patient List</h3>
                                        <div className="patientListFilterByBtn">
                                            <input type='radio' name="patientListFilter" onChange={(e) => SetListPreview({ ...ListPreview, singleList: true, allList: false })} checked={ListPreview.singleList} value={"Single List"} disabled />
                                            <input type='radio' name="patientListFilter" onClick={(e) => AllPatientList(e)} onChange={(e) => SetListPreview({ ...ListPreview, allList: true, singleList: false })} checked={ListPreview.allList} value={"All List"} />
                                        </div>
                                    </div>
                                    <AppointmentAllList FilterDate={DateFilter} />
                                </div>
                                <div className="docProfilePatientListFilterByDate">
                                    <Calendar onChange={(e) => SetDateFilter(e)} />
                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </section >

        </>
    )
}

export default DoctorProfile