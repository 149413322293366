import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import ArrowRightImg from "../../Assets/SVG/Login/ArrowRight.svg"
import "../../CSS/PatientForm/PatientForm.css"
import "../../CSS/PatientForm/BasicForm.css"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FamilyInformation from './FamilyInformation'
import RiskFactor from './RiskFactor'
const BasicDetails = () => {
    const navigate = useNavigate()

    const [FormData, SetFormData] = useState({
        patientFirstName: "",
        patientLastName: "",
        maritalStatus: "",
        patientDOB: "",
        patientEmail: "",
        phoneno: "",
        patientAge: "",
        landmark: "",
        localityAddress: "",
        patientCity: "",
        patientState: "",
        familyInfo: { kins: false },
        riskFactor: { haveRiskFactor: false },
        hospitalId: localStorage.getItem("hospitalId")
    })

    const [pageStatus, setPageStatus] = useState({ Basic: true, familyInfo: false, riskFactor: false })
    const [loding, setLoding] = useState(false)
    let Today = new Date()

    useEffect(() => {
        const age = Today.getFullYear() - FormData.patientDOB.split("-")[0]
        SetFormData({ ...FormData, patientAge: FormData.patientDOB !== "" ? age : "NA" })
        // eslint-disable-next-line 
    }, [FormData.patientDOB])

    const BasicDetailsSubmit = (e) => {

        setLoding(true)

        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/add", FormData, {
            headers: {
                token: localStorage.getItem("token")
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    navigate("/user/patientLogin", {
                        state: {
                            uhid: res.data.uhid,
                            patientid: res.data.patientid,
                            name: (FormData.patientFirstName + " " + FormData.patientLastName),
                            phone: FormData.phoneno,
                            age: FormData.patientDOB !== "" ? Today.getFullYear() - FormData.patientDOB.split("-")[0] : "NA"
                        }
                    });
                }
                setLoding(false)
            }).catch((err) => {
                setLoding(false)
                toast.error(err.response.data)
            })
    }

    const funcGoNext = (e) => {
        e.preventDefault();

        if (FormData.patientFirstName === "") {
            toast.error("Enter Patient Name")
            return;
        }

        if (FormData.phoneno === "") {
            toast.error("Enter Patient Phone Number")
            return;
        }

        if (FormData.phoneno !== "") {
            const indianPhoneNumberRegex = /^(?:\+?91)?(?:\d{10}|\d{5}-\d{5})$/;
            const verifyMobile = indianPhoneNumberRegex.test(FormData.phoneno);

            if (!verifyMobile) {
                toast.error("Enter a valid Phone")
                return;
            }
        }

        if (FormData.patientEmail !== "") {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
            const verifyEmail = emailRegex.test(FormData.patientEmail)
            if (!verifyEmail) {
                toast.error("Enter a valid Email")
                return;
            }
        }

        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/get", { "phone": FormData.phoneno }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then(response => {
            toast.info("Patient already has an account. Please Login")
            return;
        }).catch(err => {
            if (err.response.data === 'Patiend Not Found') {
                setPageStatus({ familyInfo: true, Basic: false, riskFactor: false })
            }
            else {
                console.log(err)
            }
        })
    }

    return (
        <>
            <ToastContainer />
            <div className="ParentRapper">
                <TopBar pageName="Add Patient" />

                <div className="pageDetails">
                    <SideBar />
                    {pageStatus.Basic &&
                        <form className="page">
                            <div className="pagePath">Dashboard/patient-portal/add-patient/</div>
                            <div className="PatientBasicForm">


                                <div className="basicDataPatientForm">
                                    <h3 className="formHeading">Basic Details
                                    </h3>
                                    <div className="basicform">
                                        <div className="DataRows" key="1">
                                            <label htmlFor="First Name">First Name</label>
                                            <input type="text" name="patientFirstName" value={FormData.patientFirstName} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} id="patientFirstName" placeholder='Please enter first name' required />
                                        </div>
                                        <div className="DataRows" key="2">
                                            <label htmlFor="Last Name">Last Name</label>
                                            <input type="text" name="patientLastName" value={FormData.patientLastName} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} id="patientLastName" placeholder='Please enter last name' />
                                        </div>
                                        <div className="DataRows" key="3">
                                            <label htmlFor="phone">Phone</label>
                                            <input type="number" name="phoneno" value={FormData.phoneno} id="phoneno" onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} placeholder='Phone no with country code' required />
                                        </div>

                                        <div className="DataRows" key="4">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" name="patientEmail" value={FormData.patientEmail} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} id="email" placeholder='Please enter the email address' />
                                        </div>
                                        <div className="DataRows" key="5">
                                            <label htmlFor="marital">Martial Status</label>
                                            <select name="maritalStatus" id="maritalStatus" value={FormData.maritalStatus} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })}>
                                                <option value="">Select :</option>
                                                <option value="married">Married</option>
                                                <option value="unmarried">Unmarried</option>
                                            </select>
                                        </div>
                                        <div className="DataRows" key="6">
                                            <label htmlFor="reletionship">S/o | D/o | W/o</label>
                                            <input type="text" name="relationShip" id="relationShip" value={FormData.relationShip} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} placeholder='Enter their name' />
                                        </div>

                                        <div className="DataRows" key="7">
                                            <label htmlFor="DOB">Date of Birth</label>
                                            <input type="date" name="patientDOB" id="patientDOB" value={FormData.patientDOB} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} placeholder='Please enter  first name' />
                                        </div>
                                        <div className="DataRows" key="8">
                                            <label htmlFor="DOB">Age</label>
                                            <input type="number" name="patientAge" id="patientAge" value={FormData.patientDOB} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} placeholder='Please enter Age' disabled />
                                        </div>
                                        <div className="DataRows" key="9">
                                            <label htmlFor="Locality">Locality Address</label>
                                            <input type="text" name="localityAddress" id="ocalityAddress" value={FormData.localityAddress} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} placeholder='Please enter road name, apartment' />
                                        </div>
                                        <div className="DataRows" key="10">
                                            <label htmlFor="Landmark">Landmark</label>
                                            <input type="text" name="landmark" id="landmark" value={FormData.landmark} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} placeholder='Please enter any landmark' />
                                        </div>
                                        <div className="DataRows" key="11">
                                            <label htmlFor="City">City</label>
                                            <input type="text" name="patientCity" id="patientCity" value={FormData.patientCity} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} placeholder='Please enter city name' />
                                        </div>
                                        <div className="DataRows" key="12">
                                            <label htmlFor="State">State</label>
                                            <input type="text" name="patientState" id="patientState" value={FormData.patientState} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} placeholder='Please enter state' />
                                        </div>
                                        <div className="DataRows" key="13">
                                            <label htmlFor="Country">Country</label>
                                            <input type="text" name="patientCountry" id="patientCountry" value={FormData.patientCountry} onChange={(e) => SetFormData({ ...FormData, [e.target.name]: e.target.value })} placeholder='Please enter country name' />
                                        </div>
                                    </div >

                                </div >



                            </div>
                            <div className="patientAddActionButton">

                                <button onClick={funcGoNext}>
                                    <span>Go Next</span>
                                    <img src={ArrowRightImg} alt="" />
                                </button>

                            </div>
                        </form>
                    }
                    {pageStatus.familyInfo &&
                        <div className="page">
                            <FamilyInformation PatientData={{ FormData, SetFormData }} Others={"Family Info"} setPageStatus={setPageStatus} />
                        </div>
                    }
                    {pageStatus.riskFactor &&
                        <div className="page">
                            <RiskFactor PatientData={{ FormData, SetFormData }} Others={"Risk Factor"} setPageStatus={setPageStatus} BasicDetailsSubmit={BasicDetailsSubmit} loding={loding} />
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default BasicDetails