import { useState } from "react";
import UserContext from "./UserContext";


const UserAuth = (props) => {
    // const [AuthLogin, SetAuthLogin] = useState({ "logedin": true })
    const [AuthUser, SetAuthUser] = useState({ "user": "" })
    return (

        <UserContext.Provider value={{ AuthUser, SetAuthUser }}>
            {props.children}
        </UserContext.Provider>
    )
}


export default UserAuth;