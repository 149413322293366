import { createContext, useState } from "react";

export const AppointmentContext = createContext()


export const AppointmentState = (props) => {
    const [AppointBooking, SetAppointmentBooking] = useState({
        date: "",
        docName: ""
    })
    return (

        <AppointmentContext.Provider value={{AppointBooking,SetAppointmentBooking}}>
            {props.children}
        </AppointmentContext.Provider>
    )
}