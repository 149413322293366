import { React } from 'react'
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import "../../CSS/Patient/PatientLogin.css"
import "../../CSS/PatientForm/BasicForm.css"
import { useLocation, useNavigate } from 'react-router-dom'
import PatientWellComeLogin from './PatientWellComeLogin'
import PatientDirectLogin from './PatientDirectLogin'

const PatientLogin = () => {
    const navigate = useNavigate()
    const NavigateFrom = useLocation()
    const patientFormLogin = () => {

        if (NavigateFrom.state !== null) {
            return (<PatientWellComeLogin PatientData={NavigateFrom.state} />)
        } else {
            navigate("/user/patient")

            return (
                <PatientDirectLogin />
            )
        }
    }
    return (
        <>

            <div className="ParentRapper">
                <TopBar pageName="Add Patient" />

                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <div className="pagePath">Dashboard/patient-portal/Patient-Login/</div>

                        <div className="patientLogin">
                            <div className="patientLoginForm">
                                {patientFormLogin()}




                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}

export default PatientLogin