/* eslint-disable no-mixed-operators */
import React from 'react'

import { ReactComponent as CalenderImg } from "../../../Assets/SVG/SideBarIcons/Calender.svg"
import { ReactComponent as ListImg } from "../../../Assets/SVG/Doctor/List.svg"
// import { ReactComponent as TimerImg } from "../../../Assets/SVG/Doctor/Timer.svg"
import { ReactComponent as PatientImg } from "../../../Assets/SVG/Diagnostics/Patient.svg"
import { NavLink } from 'react-router-dom'
const TopNavBar = () => {
    return (
        <div className="doctorProfileTopSubLinks">
            {
                (window.localStorage.getItem("adminType").toLocaleLowerCase() === "doctor") && (window.localStorage.getItem("token")) ?
                    <NavLink to={"/user/doctor/calender"} className={({ isActive }) => (isActive ? "doctorProfileTopActiveNavMenu docProfileNavLinks" : "doctorProfileTopInActiveNavMenu docProfileNavLinks")}>
                        <div className="docprofileNavManuIcons">
                            <CalenderImg />
                        </div>
                        <span>Calender</span>
                    </NavLink>
                    : ""
            }

            {
                (window.localStorage.getItem("adminType").toLocaleLowerCase() === "diagnostics") && window.localStorage.getItem("token") ?

                    <NavLink to={"/user/lab/diagnostics"} className={({ isActive }) => (isActive ? "doctorProfileTopActiveNavMenu docProfileNavLinks" : "doctorProfileTopInActiveNavMenu docProfileNavLinks")}>
                        <div className="docprofileNavManuIcons">
                            <PatientImg />
                        </div>
                        <span>Patient List</span>
                    </NavLink>
                    : ""
            }

            {
                (window.localStorage.getItem("adminType").toLocaleLowerCase() === "doctor") || (window.localStorage.getItem("adminType").toLocaleLowerCase() === "pa") && (window.localStorage.getItem("token")) ?
                    <NavLink to={"/user/patientlist"} className={({ isActive }) => (isActive ? "doctorProfileTopActiveNavMenu docProfileNavLinks" : "doctorProfileTopInActiveNavMenu docProfileNavLinks")}>
                        <div className="docprofileNavManuIcons">
                            <ListImg />
                        </div>
                        <span>Patient List</span>
                    </NavLink>
                    : ""
            }

            {
                ((window.localStorage.getItem("adminType").toLocaleLowerCase() === "doctor") || (window.localStorage.getItem("adminType").toLocaleLowerCase() === "pa") || (window.localStorage.getItem("adminType").toLocaleLowerCase() === "diagnostics")) && window.localStorage.getItem("token") ?

                    <NavLink to={"/user/appointmentlist"} className={({ isActive }) => (isActive ? "doctorProfileTopActiveNavMenu docProfileNavLinks" : "doctorProfileTopInActiveNavMenu docProfileNavLinks")}>
                        <div className="docprofileNavManuIcons">
                            <ListImg />
                        </div>
                        <span>Appointments</span>
                    </NavLink>
                    : ""
            }

            {
                (window.localStorage.getItem("adminType").toLocaleLowerCase() === "doctor") && window.localStorage.getItem("token") ?

                    <NavLink to={"/user/doctor/allappointments"} className={({ isActive }) => (isActive ? "doctorProfileTopActiveNavMenu docProfileNavLinks" : "doctorProfileTopInActiveNavMenu docProfileNavLinks")}>
                        <div className="docprofileNavManuIcons">
                            <ListImg />
                        </div>
                        <span>My Patients</span>
                    </NavLink> : ""}


            {
                (window.localStorage.getItem("adminType").toLocaleLowerCase() === "nurse") && window.localStorage.getItem("token") ?

                    <NavLink to={"/user/nurse/inpatientList"} className={({ isActive }) => (isActive ? "doctorProfileTopActiveNavMenu docProfileNavLinks" : "doctorProfileTopInActiveNavMenu docProfileNavLinks")}>
                        <div className="docprofileNavManuIcons">
                            <ListImg />
                        </div>
                        <span>Patient List</span>
                    </NavLink>
                    : ""
            }

            {/* {
                (window.localStorage.getItem("adminType").toLocaleLowerCase() === "doctor") || (window.localStorage.getItem("adminType").toLocaleLowerCase() === "nurse") || (window.localStorage.getItem("adminType").toLocaleLowerCase() === "pa") || (window.localStorage.getItem("adminType").toLocaleLowerCase() === "diagnostics") && window.localStorage.getItem("token") ?
                    <NavLink to={"/user/availibility"} className={({ isActive }) => (isActive ? "doctorProfileTopActiveNavMenu docProfileNavLinks" : "doctorProfileTopInActiveNavMenu docProfileNavLinks")}>
                        <div className="docprofileNavManuIcons">
                            <TimerImg />
                        </div>
                        <span>Availability</span>
                    </NavLink>
                    : ""
            } */}



        </div>
    )
}

export default TopNavBar