import React, { useState } from 'react'
import "../../../CSS/Profiles/SystemAdmin/AdminAccountCreation.css"
import TopBar from '../../Bars/TopBar'
import SideBar from '../../Bars/SideBar'
import SettingTopNavBar from '../../Setting/SettingTopNavBar'
import DoctorForm from '../../ProfileCreateForms/DoctorForm'
import NurseForm from '../../ProfileCreateForms/NurseForm'
import DoctorPA from '../../ProfileCreateForms/DoctorPA'
import Diagnostics from '../../ProfileCreateForms/Diagnostics'
import Receptionist from '../../ProfileCreateForms/Receptionist'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'

const AdminAccountCreation = () => {
    const url = process.env.REACT_APP_BACKEND_URL;
    const [ProfileRole, SetProfileRole] = useState({
        role: ""
    })
    const [FormData, SetFormData] = useState({
        hospitalId: ""
    })

    const validateForm = () => {
        if (ProfileRole.role === 'doctor') {
            const regex = /^[A-Z]{2,5}\/\d{4}\/\d{1,6}$/;
            if (!regex.test(FormData.licenseNo) || FormData.licenseNo.length >= 20) {
                toast.error('Invalid License Number');
                return false;
            }
        }
        return true;
    };


    const AccountCreationForm = () => {

        if (ProfileRole.role === "doctor") {
            return <DoctorForm FormData={{ FormData, SetFormData }} />
        } else if (ProfileRole.role === "nurse") {

            return <NurseForm FormData={{ FormData, SetFormData }} />
        } else if (ProfileRole.role === "pa") {

            return <DoctorPA FormData={{ FormData, SetFormData }} />
        } else if (ProfileRole.role === "receptionist") {

            return <Receptionist FormData={{ FormData, SetFormData }} />
        } else if (ProfileRole.role === "diagnostics") {

            return <Diagnostics FormData={{ FormData, SetFormData }} />
        }
        else {
            return null
        }
    }

    const AdminAccountCreation = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        let path;

        switch (ProfileRole.role) {
            case ("doctor"):
                path = "/doctor/add";
                break;
            case ("nurse"):
                path = "/nurse/add";
                break;
            case ("receptionist"):
                path = "/receptionist/add";
                break;
            case ("pa"):
                path = "/pa/add";
                break;
            case ("diagnostics"):
                path = "/diagnostics/add";
                break;
            default:
                path = ""
                break;
        }


        axios.post(url + path, JSON.parse(JSON.stringify(FormData)), {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((data) => {
            toast.success(data.data)
            e.target.reset()
            SetFormData({})
        }).catch((err) => {
            toast.error(err.response.data)
        })
    }



    return (
        <>
            <ToastContainer />
            <div className="ParentRapper">
                <TopBar pageName="Setting" />

                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <SettingTopNavBar />

                        <form onSubmit={(e) => AdminAccountCreation(e)} className="adminPageProfileCreation">
                            <div className="grid w-100">
                                <div className="flex-col">
                                    <label htmlFor="email">
                                        Select Role
                                    </label>
                                    <select name="role" id="selectRole" onChange={(e) => [SetProfileRole({ ...ProfileRole, [e.target.name]: e.target.value }), SetFormData({})]} required>
                                        <option value="">Select Role : </option>
                                        <option value="doctor">Doctor</option>
                                        <option value="nurse">Nurse</option>
                                        <option value="pa">Doctor PA</option>
                                        <option value="receptionist">Receptionist</option>
                                        <option value="diagnostics">Diagnostics</option>
                                    </select>
                                </div>
                                {
                                    AccountCreationForm()
                                }
                            </div>
                            <div className="adminProfileCreationFormBtns flexRow g-1 w-100">
                                <button type='submit'>Save</button>
                                <button type='reset' onClick={() => [AccountCreationForm(), SetProfileRole({}), SetFormData({})]}>Cancel</button>
                            </div>
                        </form>





                    </div>
                </div>
            </div>

        </>
    )
}

export default AdminAccountCreation