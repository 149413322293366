import React from 'react'
import TopBar from '../../Bars/TopBar'
import SideBar from '../../Bars/SideBar'
import "../../../CSS/Dashboard/Dashboard.css"
import '../../../CSS/Profiles/Doctor/DownloadPdfOption.css'
import ArrowRight from "../../../Assets/SVG/Patient/ArrowRight.svg"
import TopNavBar from './TopNavBar'
import { useLocation, useNavigate } from 'react-router-dom'

const DownloadPdfOption = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const funcGoBackConsultation = () => {
        navigate("/user/patientlist", { state: { reload: true } })
    }

    const funcDownloadPdf = () => {
        const patientInfo = location.state;
        const data = {
            _id: patientInfo.appointmentId,
            patientInfo: {
                _id: patientInfo.patientId
            }
        }


        navigate('/pdf', { state: { data: data, download:true } })
    }
    return (
        <div>
            <div className="ParentRapper">
                <TopBar pageName={localStorage.getItem('adminType')} />
                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <TopNavBar />
                        <section className='sectionWrapper'>
                            <div className='heading'>
                                <p>Prescription</p>
                            </div>
                            <div className='DownloadBtnParentWrapper'>
                                <div className='DownloadBtnWrapper'>
                                    <div className="btn consultation" onClick={funcGoBackConsultation}>
                                        <button>Go Back to consultation</button>
                                        <img src={ArrowRight} alt="ArrowRight" />
                                    </div>
                                    <div>
                                        <p>
                                            OR
                                        </p>
                                    </div>
                                    <div className="btn download" onClick={funcDownloadPdf}>
                                        <button>Download Prescription</button>
                                        <img src={ArrowRight} alt="ArrowRight" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadPdfOption
