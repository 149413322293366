/* eslint-disable no-unused-vars */
import { PDFViewer } from '@react-pdf/renderer'
import React from 'react'
import PdfDocument from './PdfDocument'

const PdfViewer = () => {
    return (
        <PdfDocument />
    )
}

export default PdfViewer