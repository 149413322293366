import React from 'react'

const NurseForm = ({ FormData }) => {
    return (
        <>
            <div className="flex-col g-05">
                <label htmlFor="email">
                    Enter Nurse Email
                </label>
                <input type="email" name='email' placeholder='Enter nurse email' value={FormData.FormData.email} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value,hospitalId: localStorage.getItem("hospitalId") })} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="name">
                    Nurse Name
                </label>
                <input type="text" name='name' placeholder='Enter nurse name' value={FormData.FormData.name} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="phone">
                    Nurse Phone No
                </label>
                <input type="number" name='phone' placeholder='Enter nurse phone no' value={FormData.FormData.phone} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
            </div>

            <div className="flex-col g-05">
                <label htmlFor="speciality">
                    Nurse DOB
                </label>
                <input type="date" name='DOB' placeholder='Enter nurse speciality' value={FormData.FormData.DOB} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="speciality">
                    Nurse Licence No
                </label>
                <input type="text" name='nurseId' placeholder='Enter nurse licence no.' value={FormData.FormData.nurseId} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="speciality">
                    Nurse Profile Password
                </label>
                <input type="password" name='password' placeholder='Enter nurse profile Password' value={FormData.FormData.password} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
            </div>

        </>

    )
}

export default NurseForm