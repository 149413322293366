/* eslint-disable no-mixed-operators */
import React, { useEffect, useState, useRef } from 'react'
import EyeImg from "../../../Assets/SVG/Diagnostics/Eye.svg"
import FileUpload from "../../../Assets/SVG/Diagnostics/FileUpload.svg"
import DownloadImg from "../../../Assets/SVG/Diagnostics/DownloadFile.svg"
import DoneImg from "../../../Assets/SVG/EMR/Confirm.svg"
import YetToDone from "../../../Assets/SVG/Billing/YetToPay.svg"
import PendingImg from "../../../Assets/SVG/EMR/Timer.svg"
import { ReactComponent as RejectImg } from "../../../Assets/SVG/patientSVG/CrossCircle.svg"
import ViewDiagnostics from '../../modals/ViewDiagnostics.jsx'
import axios from 'axios'
import pendingFile from '../../../Assets/Images/pendingFile.png'

const PatientListCard = ({ status, pName, pid, Date, slno, data, toast }) => {

    const URL = process.env.REACT_APP_BACKEND_URL
    const [openModel, setOpenModel] = useState(false)
    const [diagnosticsInfo, setDiagnosticsInfo] = useState([])
    const [selectedStatus, setSelectedStatus] = useState('');
    const [file, setFile] = useState("")
    const fileInputRef = useRef(null);

    const funcViewDetails = () => {
        setOpenModel(true)
        setDiagnosticsInfo(data.diagnosticsInfo)
    }


    useEffect(() => {
        axios.post(`${URL}/diagnostics/labtest/status/get`, {
            hospitalId: data.hospitalId,
            diagonisticId: data._id,
            labTestStatus: selectedStatus
        },
            {
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then(res => {
                setSelectedStatus(res.data.data)
            }).catch(err => {
                console.log(err)
            })

        // eslint-disable-next-line
    }, [])


    const handleStatusChange = (status) => {
        setSelectedStatus(status);
        axios.post(`${URL}/diagnostics/labtest/status/change`, {
            hospitalId: data.hospitalId,
            diagonisticId: data._id,
            labTestStatus: status
        }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then(res => {
            toast.success(res.data.message)
            console.log(res.data.message)
        }).catch(err => {
            console.log(err)
        })
    };


    const submitPdf = () => {
        if (file === "") {
            toast.error("Select a File")
            return;
        }

        const formdata = new FormData();
        const fileName = file.name;
        formdata.append('name', fileName)
        formdata.append('pdf', file)
        formdata.append('hospitalId', data.hospitalId)
        formdata.append('_id', data._id)

        axios.post(`${URL}/diagnostics/labtest/results/upload`, formdata, {
            headers: {
                "Content-Type": "multipart/form-data",
                "token": localStorage.getItem('token')
            }
        }).then((res) => {
            setFile("")
            fileInputRef.current.value = null;
            toast.success(res.data.message);
        }).catch(err => {
            console.log(err);
        })
    }


    return (
        <>
            <ViewDiagnostics openModel={openModel} setOpenModel={setOpenModel} data={diagnosticsInfo} />
            <div className="flexRow g-3 diagnosticsPatientCard align-center justify-between">
                <div className="w-6r">
                    <h3 className='flex align-center'>{slno}</h3>
                </div>
                <div className="flex-col diagnosticsBasicDetails g-05">
                    <h3>{pName}</h3>
                    <span>Patient ID :{pid}</span>
                    {
                        status === "Complete" ?
                            <div className="diagosticsStatus  size14" style={{ background: "#70CF97", color: "white" }} >
                                Complete
                            </div>
                            : ""
                    }
                    {
                        status === "Approved" && status !== "Complete" ?
                            <div className="diagosticsStatus  size14" style={{ background: "#DCDCFD", color: "#222070" }} >
                                Confirmed
                            </div>
                            : ""
                    }
                    {
                        status === "Reject" ?
                            <div className="diagosticsStatus  size14" style={{ background: "red", color: "white" }} >
                                Rejected
                            </div>
                            : ""
                    }
                    {
                        status === "pending" ?
                            <div className="diagosticsStatus  size14" style={{ background: "#FFF6E9", color: "#B5850B" }} >
                                Pending
                            </div>
                            : ""
                    }

                </div>
                <span className='size14 color-border'>{Date}</span>
                <button className='flexRow viewdetailsDiagnostics' onClick={funcViewDetails}>
                    <span>View Details</span>
                    <img src={EyeImg} alt="" />
                </button>
                {
                    selectedStatus === "Test Done" ?
                        <div className="diagnosticsStatusPreviewer flexRow g-05" >
                            <>
                                <img src={DoneImg} alt="" />
                                <span style={{ color: "#165E3D", background: "#EDFFEA" }}>Test Done</span>
                            </>
                        </div>
                        : ""
                }
                {
                    selectedStatus === "Yet to test" ?
                        <div className="diagnosticsStatusPreviewer flexRow g-05" >
                            <>
                                <img src={YetToDone} alt="" />
                                <span style={{ color: "#B83131", background: "#FFEAEA" }}>Yet to test</span>
                            </>
                        </div>
                        : ""
                }
                {
                    selectedStatus === "Testing" ?
                        <div className="diagnosticsStatusPreviewer flexRow g-05" >
                            <>
                                <img src={PendingImg} alt="" />
                                <span style={{ background: "#FFF6E9", color: "#B5850B" }}>Testing</span>
                            </>
                        </div>
                        : ""
                }
                {
                    status === "Approved" && localStorage.getItem("adminType") === "hospitalAdmin" || localStorage.getItem("adminType") === "receptionist" ?
                        <div className="diagnosticsStatusPreviewer flexRow g-05" >
                            <>
                                <img src={DoneImg} alt="" />
                                <span style={{ color: "#165E3D", background: "#EDFFEA" }}>Confirm</span>
                            </>
                        </div>
                        : ""
                }
                {
                    status === "Reject" ?
                        <div className="diagnosticsStatusPreviewer flexRow g-05" >
                            <>
                                <RejectImg fill='white' stroke='red' />
                                <span style={{ color: "#B83131", background: "#FFEAEA" }}>Rejected</span>
                            </>
                        </div>
                        : ""
                }
                {
                    status === "pending" ?
                        <div className="diagnosticsStatusPreviewer flexRow g-05" >
                            <img src={PendingImg} alt="" />
                            <span style={{ color: "#B5850B", background: "#FFF6E9" }}>Pending</span>
                        </div>
                        : ""
                }
                {
                    localStorage.getItem("adminType") === "Diagnostics" ?
                        <div className="dropdown">
                            <button className="dropdown-toggle" type="button" id="diagnosticsDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {selectedStatus || 'Loading...'}
                            </button>
                            <div className="dropdown-menu" aria-labelledby="diagnosticsDropdown">
                                <button className="dropdown-item" type="button" onClick={() => handleStatusChange("Yet to test")} selected={selectedStatus === "Yet to test"}>Yet to test</button>
                                <button className="dropdown-item" type="button" onClick={() => handleStatusChange("Testing")} selected={selectedStatus === "Testing"}>Testing</button>
                                <button className="dropdown-item" type="button" onClick={() => handleStatusChange("Test Done")} selected={selectedStatus === "Test Done"}>Test Done</button>
                            </div>
                        </div> : ""
                }


                <div className="flexRow g-2 diagnosticUploadSection">
                    {
                        localStorage.getItem("adminType") === "Diagnostics" ?
                            <>
                                <input type="file" onChange={(e) => { setFile(e.target.files[0]) }} accept='application/pdf' ref={fileInputRef} />
                                <img src={FileUpload} alt="" onClick={() => { submitPdf() }} />
                            </>
                            :
                            data.labTestResult !== undefined ?
                                <a href={URL + `/pdf/${data.labTestResult}`} target='_bank'>
                                    <img src={DownloadImg} alt="" />
                                </a> : <img src={pendingFile} alt="" />
                    }
                </div>
            </div>

        </>
    )
}

export default PatientListCard