import React, { useState } from 'react'
import "../../../CSS/Profiles/Doctor/AppointmentListTable.css"

import TopBar from '../../Bars/TopBar'
import TopNavBar from './TopNavBar'



import Calendar from 'react-calendar'
import AppointmentTable from './AppointmentTable'
import DoctorSideBar from '../../Bars/DoctorSideBar'


const AppointmentList = () => {
    const [FilterDate, SetFilterDate] = useState("")
    const [ListPreview, SetListPreview] = useState({
        allList: true,
        singleList: false
    })
    const DateChanger = () => {
        SetFilterDate("")
    }
    return (
        <>

            <section className="ParentRapper">
                <TopBar pageName={window.localStorage.getItem("adminType")} />

                <div className="pageDetails">
                    <DoctorSideBar />
                    <div className="page">
                        <section className="docProfileMainSection">

                            <TopNavBar />
                            <div className="PatientListAndDateFilter">
                                <div className="doctorprofilePatientList">
                                    <div className="flexRow justify-between">

                                        <h3>Patient List</h3>
                                        <div className="patientListFilterByBtn">
                                            <input type='radio' name="patientListFilter" onChange={(e) => SetListPreview({ ...ListPreview, singleList: true, allList: false })} checked={ListPreview.singleList} value={"Single List"} disabled />
                                            <input type='radio' name="patientListFilter" onClick={() => DateChanger()} onChange={(e) => SetListPreview({ ...ListPreview, allList: true, singleList: false })} checked={ListPreview.allList} value={"All List"} />
                                        </div>
                                    </div>

                                    <AppointmentTable DateFilter={FilterDate} />

                                </div>
                                <div className="docProfilePatientListFilterByDate">
                                    <Calendar onChange={(e) => SetFilterDate(e)} />
                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </section >



        </>
    )
}

export default AppointmentList