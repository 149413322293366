import React, { useState } from 'react'

const DoctorForm = ({ FormData }) => {

    const [error, setError] = useState({ status: false, msg: "" })

    const validateLicenseNo = (licenseNo) => {
        const regex = /^[A-Z]{2,5}\/?\d{4}\/?\d{1,6}$/;
        return regex.test(licenseNo) && licenseNo.length <= 20;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'licenseNo') {
            if (validateLicenseNo(value)) {
                setError({ status: false, msg: '' });
            } else {
                setError({ status: true, msg: 'Invalid License Number' });
            }
        }

        FormData.SetFormData({ ...FormData.FormData, [name]: value });
    };

    return (
        <>
            <div className="flex-col g-05">
                <label htmlFor="email">
                    Enter Email
                </label>
                <input type="email" name='email' placeholder='Enter person email' value={FormData.FormData.doctorEmail} onChange={(e) => FormData.SetFormData({
                    ...FormData.FormData,
                    [e.target.name]: e.target.value,
                    hospitalId: localStorage.getItem("hospitalId")
                })} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="name">
                    Doctor Name
                </label>
                <input type="text" name='name' placeholder='Enter doctor name' value={FormData.FormData.doctorName} onChange={handleInputChange} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="phone">
                    Doctor Phone No
                </label>
                <input type="number" name='phone' placeholder='Enter doctor phone no' value={FormData.FormData.doctorPhone} onChange={handleInputChange} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="speciality">
                    Doctor Speciality
                </label>
                <input type="text" name='specialization' placeholder='Enter doctor speciality' value={FormData.FormData.doctorSpeciality} onChange={handleInputChange} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="speciality">
                    Doctor DOB
                </label>
                <input type="date" name='DOB' placeholder='Enter doctor speciality' value={FormData.FormData.doctorDOB} onChange={handleInputChange} required />
            </div>
            <div className="flex-col g-05">
                <label htmlFor="speciality">
                    Doctor Licence No (Eg: KMC/2024/67890)
                </label>
                <input type="text" name='licenseNo' placeholder='Enter doctor licence no.' value={FormData.FormData.licenseNo || ''} onChange={handleInputChange} required />
                <div className='errorMsgContainer'>
                    {error.status && <p>{error.msg}</p>}
                </div>
            </div>
            <div className="flex-col g-05">
                <label htmlFor="speciality">
                    Doctor Profile Password
                </label>
                <input type="password" name='password' placeholder='Enter doctor profile Password' value={FormData.FormData.doctorPassword} onChange={handleInputChange} required />
            </div>

        </>
    )
}

export default DoctorForm