/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react'
import List from "../../../Assets/SVG/Doctor/ListI.svg"
import Person from "../../../Assets/SVG/Doctor/User.svg"
import Clock from "../../../Assets/SVG/Doctor/Timer.svg"
import Action from "../../../Assets/SVG/Doctor/Action.svg"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import Loader from '../../Loader/Loader'
import BlankAlert from '../../BlankComp/BlankAlert'

const AppointmentTable = ({ DateFilter }) => {
    const [PendingAppointment, SetPendingAppointment] = useState(null)
    const [FilterPendingAppointments, SetFilterPendingAppointments] = useState(null)

    useEffect(() => {
        if (DateFilter !== "" && typeof (PendingAppointment) !== "string") {

            let Filter = PendingAppointment && PendingAppointment.filter((data) => {
                let date = new Date(DateFilter)
                console.log(date)
                return (
                    date.setHours(0, 0, 0, 0) === new Date(data.date).setHours(0, 0, 0, 0)
                )
            })
            SetFilterPendingAppointments(Filter)
        } else {
            PendingAppointment && SetFilterPendingAppointments(PendingAppointment)
        }
        // eslint-disable-next-line
    }, [DateFilter])

    const adminType = localStorage.getItem("adminType").toLocaleLowerCase()
    const getPatientList = () => {
        const docId = localStorage.getItem("Id")
        if (adminType === "doctor" || adminType === "pa") {
            axios.post(process.env.REACT_APP_BACKEND_URL + "/appointment/status/pending/get", { doctorId: localStorage.getItem("adminType") === "Doctor" ? docId : localStorage.getItem("doctor_id"), hospitalId: localStorage.getItem("hospitalId") }, {
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then((res) => {
                SetPendingAppointment(res.data)
                SetFilterPendingAppointments(res.data)

            }).catch((err) => {
                console.log(err.response.data)
            })
        } else {
            axios.post(process.env.REACT_APP_BACKEND_URL + "/diagnostics/book/get", { hospitalId: localStorage.getItem("hospitalId") }, {
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then((res) => {
                SetPendingAppointment(res.data)
                SetFilterPendingAppointments(res.data)

            }).catch((err) => {
                console.log(err.response.data)
            })
        }

    }

    const AppointmentConfirm = (status, id) => {

        let path;

        if (adminType === "doctor" || adminType === "pa") {
            path = "/appointment/status/change"
        } else {
            path = "/diagnostics/status/change"
        }

        axios.post(process.env.REACT_APP_BACKEND_URL + path, {
            status: status,
            appointmentId: id,
            hospitalId: localStorage.getItem("hospitalId"),
            diagnostiscId: id
        }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            getPatientList()
            toast.success(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }


    useEffect(() => {
        getPatientList()
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <ToastContainer />
            <div className="consultAdmitPatientList">
                <table className="docConSultedPatientList">
                    <thead>
                        <tr key={"TableHeaders"}>
                            <th>
                                <img src={List} alt="" />
                                <span>Token No</span>
                            </th>
                            <th>
                                <img src={Person} alt="" />
                                <span>Name</span>
                            </th>
                            <th>
                                <img src={Clock} alt="" />
                                <span>Visiting Time</span>
                            </th>
                            <th>
                                <img src={Action} alt="" />
                                <span>Action</span>
                            </th>
                        </tr>
                    </thead>
                    {/* <div className="patientConsultedListBody"> */}
                    <tbody>


                        {
                            FilterPendingAppointments && typeof (FilterPendingAppointments) !== "string" ? FilterPendingAppointments.filter((fdata) => {
                                return fdata.status === "pending"
                            }).map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{adminType === "nurse" || adminType === "doctor" || adminType === "pa" ? data.patientInfo.patientFirstName + "" + data.patientInfo.patientLastName : data.patientBasicInfo.patientFirstName + "" + data.patientBasicInfo.patientLastName}</td>
                                        <td>{data.time}</td>
                                        <td>

                                            <button className='appointmentConfirmBtn' value={"Approved"} onClick={(e) => AppointmentConfirm(e.target.value, data._id)}>Confirm</button>
                                            <button className='appointmentRejectBtn' value={"Reject"} onClick={(e) => AppointmentConfirm(e.target.value, data._id)}>Reject</button>
                                        </td>
                                    </tr>
                                )
                            }) : ""
                        }


                    </tbody>
                    {
                        FilterPendingAppointments === null ? <Loader className="h-100" /> : ""
                    }

                    {FilterPendingAppointments && FilterPendingAppointments.length === 0 || typeof (FilterPendingAppointments) === "string" ?
                        <BlankAlert msg={"No appointments on this date"} /> : ""
                    }
                </table >
            </div >

        </>
    )
}

export default AppointmentTable