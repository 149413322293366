import { React, useEffect, useState } from 'react'
import "../../CSS/Dashboard/Dashboard.css"
import DashboardCards from './DashboardCards'
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import Patients from '../../Assets/SVG/DashboardSvg/MedicalBed.svg'
import EMRImg from "../../Assets/SVG/DashboardSvg/EMRSvg.svg"
import MedicalPlusImg from "../../Assets/SVG/DashboardSvg/Medical.svg"
import CalenderImg from "../../Assets/SVG/DashboardSvg/Calender.svg"
import WaletImg from "../../Assets/SVG/DashboardSvg/Walet.svg"
import ShowImg from "../../Assets/Images/Dashboard/ShowImg.png"
import axios from 'axios'
import { toast } from 'react-toastify'

const Dashboard = () => {
    const [HospitalDetails, SetHospitalDetails] = useState(null)

    useEffect(() => {
        getDashboard()
    }, [])
    const getDashboard = async () => {
        const response = axios.post(process.env.REACT_APP_BACKEND_URL + "/hospital/get", { hospitalId: localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        })
        
        await response.then((res) => {
            SetHospitalDetails(res.data)
        }).catch((err) => {
            if (err.code === "ERR_NETWORK") {
                toast.error("check your internet connection")
            } else {

                console.log(err)
            }
        })
    }
    return (
        <>
            <div className="ParentRapper">
                <TopBar pageName="Dashboard" />
                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <div className="DashboardHeading">
                            <div className="headerIcon">

                                {HospitalDetails && <>
                                    <img src={process.env.REACT_APP_BACKEND_URL + "/image/" + HospitalDetails.imageUrl} alt="" className='DashboardHeaderLogo' />


                                </>}
                                <div className="DashboardHospitalName">
                                    {HospitalDetails &&
                                        <h2>{HospitalDetails.hospitalName}</h2>
                                    }
                                    {HospitalDetails === null ? "Loading Hospital Name" : ""
                                    }

                                    <p>Hospital Management System</p>
                                </div>
                            </div>
                        </div>
                        <div className="PageName">
                            Dashboard
                        </div>
                        <div className="dashboardScrolller">

                            <div className="DashboardCards">
                                <DashboardCards path="/user/patient" srcSvg={Patients} Title="Patients" />
                                <DashboardCards path="/user/diagnostics/all" srcSvg={MedicalPlusImg} Title="Diagnostics" />
                                <DashboardCards path="/user/appointments" srcSvg={CalenderImg} Title="Appointment" />
                                <DashboardCards path="/user/billing/all" srcSvg={WaletImg} Title="Bill Details" />
                                <DashboardCards path="/user/emr/all" srcSvg={EMRImg} Title="EMR" />
                            </div>
                        </div>
                        <div className="dashboardSideImage">
                            <img src={ShowImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Dashboard