import React from 'react'
import "../../CSS/Patient/PatientCard.css"
const PatientContact = (prop) => {
    return (
        <>
            <div className="patientContactCards" >
                <div className="patientContactSvg">
                    <img src={prop.contactSvg} alt="" />
                </div>
                <div className="contactContent">
                    <h3>{prop.ContactTitle}</h3>
                    <p>{prop.ContactCredentials}</p>
                </div>
            </div>
        </>
    )
}

export default PatientContact;