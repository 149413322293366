import React, { useState } from 'react'
import "../../CSS/Patient/PatientCard.css"
import PatientContact from './PatientContact'
import Envelop from "../../Assets/SVG/patientSVG/Envelop.svg"
import PhoneImg from "../../Assets/SVG/patientSVG/Contact.svg"
import CalenderSvg from "../../Assets/SVG/patientSVG/ContactCalender.svg"
import { useNavigate } from 'react-router-dom'
import EditPatient from '../modals/EditPatient'

const PatientCard = ({ PatientData, path }) => {
    const [openModel, setOpenModel] = useState(false)
    const navigate = useNavigate()
    const [patientInfo, setPatientInfo] = useState({
        _id: PatientData._id,
        patientFirstName: PatientData.patientFirstName,
        patientLastName: PatientData.patientLastName,
        phoneno: PatientData.phoneno,
        patientEmail: PatientData.patientEmail,
        patientAge: PatientData.patientAge
    })
    return (
        PatientData && <>
            <div className="patientCards">
                <EditPatient openModel={openModel} setOpenModel={setOpenModel} data={PatientData} patientInfo={patientInfo} setPatientInfo={setPatientInfo} />
                <div className="patientcardsLeft">

                    <div className="patientCardUpperTop">

                        <h3>{patientInfo.patientFirstName + " " + patientInfo.patientLastName}</h3>
                        <p>ID:{PatientData.uhid} </p>
                    </div>
                    <div className="patientContactDetails">
                        {
                            PatientData.patientEmail !== "" ?
                                <PatientContact contactSvg={Envelop} ContactTitle="Email" ContactCredentials={patientInfo.patientEmail} key="email" />
                                :
                                <PatientContact contactSvg={Envelop} ContactTitle="Email" ContactCredentials={"N/A"} key="email" />
                        }
                        {
                            PatientData.phoneno !== "" ?
                                <PatientContact contactSvg={PhoneImg} ContactTitle="Phone" ContactCredentials={patientInfo.phoneno} key="phone" />
                                :
                                <PatientContact contactSvg={PhoneImg} ContactTitle="Phone" ContactCredentials={"N/A"} key="phone" />
                        }
                        {
                            PatientData.patientAge !== "" ?
                                <PatientContact contactSvg={CalenderSvg} ContactTitle="Age" ContactCredentials={patientInfo.patientAge} key="ot" />
                                :
                                <PatientContact contactSvg={CalenderSvg} ContactTitle="Age" ContactCredentials={"N/A"} key="ot" />
                        }
                    </div>
                </div>
                <div className="patientcardsBtns">
                    {
                        path === "/user/patient/profile" ?
                            <button type='button' onClick={() => { setOpenModel(true) }}>
                                Edit Patient
                            </button> : <button type='button' onClick={() => navigate("/user/patient/profile", { state: PatientData })}>
                                View Details
                            </button>
                    }
                </div>

            </div >


        </>
    )
}

export default PatientCard