import React from 'react'

import { Link } from 'react-router-dom'
import Arrow from '../../Assets/SVG/DashboardSvg/CirculerArrow.svg'
const DashboardCards = (prop) => {
    return (
        <>
            <Link to={prop.path} state={{ id: prop.id, phone: prop.phoneno }} className="DashboardCard">
                <div className="cardInnerContentSec">
                    <div className="cardLeft">
                        <div className="dashboardCardSvgIcon">

                            <img src={prop.srcSvg} alt='card svg' />
                        </div>

                        <h3>{prop.Title}</h3>
                    </div>
                    <div className="cardRight">
                        <img src={Arrow} alt="arrow" />
                    </div>



                </div>


            </Link>
        </>
    )
}

export default DashboardCards