import React, { useEffect, useState } from 'react'
import "../../CSS/Appointment/Specialist.css"
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import doctorImg from '../../Assets/SVG/Doctor/doctor.svg'
import backBtn from '../../Assets/SVG/back.svg'

const SpecialistList = () => {
    const location = useLocation()

    const [AvailableSpaciality, SetAvailableSpaciality] = useState(null)


    useEffect(() => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/doctor/dept", { hospitalId: localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetAvailableSpaciality(res.data)
        }).catch((err) => {
            console.log(err.response.data)
        })
    }, [])

    return (
        <div className="ParentRapper">
            <TopBar pageName="Appointment" />
            <div className="pageDetails">
                <SideBar />

                <div className="page">
                    <div className="specialist">
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <img src={backBtn} alt="" style={{ paddingRight: '15px', cursor: 'pointer' }} onClick={() => { window.history.back() }} />
                            <h3>Choose Your Specialist</h3>
                        </div>

                        <div className="availableSpecialist">
                            {
                                AvailableSpaciality && AvailableSpaciality.map((data) => {
                                    return (
                                        <Link to={"/user/patient/appointment/doctorlist"} state={{ dept: "single", data: data, id: location.state.id }} className="specialistCards" key={data}>
                                            <div className="specilityIcons">
                                                <img src={doctorImg} alt="" />
                                            </div>
                                            <h3>{data}</h3>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpecialistList