import React, { useEffect, useState } from 'react'
import TopBar from '../../Bars/TopBar'
import SideBar from '../../Bars/SideBar'
import "../../../CSS/Patient/Billing/AddBilling.css"
import AddIcon from "../../../Assets/SVG/Billing/Add.svg"
import PrintIcon from "../../../Assets/SVG/Billing/Print.svg"
import ArrowIcon from "../../../Assets/SVG/Billing/ArrowLeft.svg"
import removeImg from "../../../Assets/SVG/Billing/YetToPay.svg"
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loader from '../../Loader/Loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AddBilling = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [Subject, SetSubject] = useState({
        subject: ""
    })
    const [count, Setcount] = useState(0)
    const [Amount, SetAmount] = useState({
        amount: ""
    })

    const [loadingPrint,setLoadingPrint] = useState(false)


    const [PatientBilling, SetPatientBilling] = useState({
        patientId: location.state.id,
        hospitalId: localStorage.getItem("hospitalId"),
        bill: []
    });

    const [totalAmount, setTotalAmount] = useState(0);
    const TotalReportPrice = () => {
        const testPriceElements = document.querySelectorAll('.testPrice');
        let sum = 0;

        testPriceElements.forEach((element) => {
            sum += Number(element.innerHTML);
        });
        setTotalAmount(sum);
    }
    useEffect(() => {
        TotalReportPrice()
    }, [PatientBilling]);


    const AddNewBilling = (insert) => {
        // let insert = 0;
        const table = document.querySelector(".addBillingSectionList table tbody")
        let button = document.createElement("button")
        button.classList.add("addRowBtn")
        let imgElm = document.createElement("img")
        imgElm.src = AddIcon
        button.append(imgElm)
        let tr = document.createElement("tr");
        // insert = insert + 1
        tr.id = insert
        // insert = insert + 1
        let td = document.createElement("td");
        let subject = document.createElement("input");
        let price = document.createElement("input");
        subject.id = "changingInput";
        subject.name = "subject";
        price.name = "amount";
        price.id = "changingInput";
        td.classList.add("billingSubject");
        let td2 = document.createElement("td");
        td2.classList.add("testPrice");
        price.addEventListener("input", (input) => {
        })

        subject.addEventListener("blur", (e) => {
            td.innerHTML = subject.value
            SetSubject({ ...Subject, subject: subject.value })
            TotalReportPrice()
        })


        price.addEventListener("blur", (e) => {
            td2.innerHTML = price.value
            SetAmount({ ...Amount, [e.target.name]: e.target.value })
            TotalReportPrice()

        })
        button.addEventListener("click", () => {

            SetPatientBilling(prevState => ({
                hospitalId: localStorage.getItem("hospitalId"),
                patientId: location.state.id,
                bill: [...prevState.bill, { subject: subject.value, amount: price.value, paid: "0" }]
            }));

            subject.parentNode.insertBefore(td, subject.parentNode.lastChild)
            subject.parentNode.removeChild(subject)

            price.parentNode.insertBefore(td2, price.parentNode.lastChild)

            price.parentNode.removeChild(price)
            const removeBtn = document.createElement("button")
            removeBtn.classList.add("removeBtn")
            imgElm.src = removeImg
            removeBtn.append(imgElm)
            removeBtn.addEventListener("click", (e) => {


                SetPatientBilling((prevState) => {
                    const updatedBill = prevState.bill.filter((_, index) => index !== Number(removeBtn.parentNode.id));
                    return { ...prevState, bill: updatedBill };
                });


                removeBtn.parentNode.parentNode.removeChild(removeBtn.parentNode)
                const addbillingTableBody = document.getElementsByClassName("addbillingTableBody")[0]
                Setcount(addbillingTableBody.childElementCount)
                for (let i = 0; i < addbillingTableBody.childElementCount; i++) {
                    addbillingTableBody.children[i].setAttribute("id", i)
                }
            })
            button.parentNode.insertBefore(removeBtn, button.parentNode.lastChild)
            button.parentNode.removeChild(button)
            TotalReportPrice()
        })

        td2.innerHTML = price.value
        td.innerHTML = subject.value
        tr.append(subject, price, button)
        table.append(tr)

    }
    useEffect(() => {
        const addbillingTableBody = document.getElementsByClassName("addbillingTableBody")[0]
        Setcount(addbillingTableBody.childElementCount)
    }, []);
    let loader = false

    const SaveBilling = (e) => {
        loader = true
        e.target.parentNode.disabled = true
        axios.post(process.env.REACT_APP_BACKEND_URL + "/billing/add", PatientBilling, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            window.history.back()
            e.target.parentNode.disabled = false
        }).catch((err) => {
            toast.error(err.response.data)
            e.target.parentNode.disabled = false
            console.log(err.response.data)
        })
    }



    const printBilling = () => {
        setLoadingPrint(true)
        axios.post(process.env.REACT_APP_BACKEND_URL + "/billing/add", PatientBilling, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            setLoadingPrint(false)
            navigate('/pdf/billing', { state: { PatientBilling } })
        }).catch((err) => {
            setLoadingPrint(false)
            toast.error(err.response.data)
            console.log(err.response.data)
        })

    }

    return (
        <>
            <ToastContainer />
            {
                loader ? <Loader className="absolute h-100 w-100" /> : ""
            }
            <div className="ParentRapper">
                <TopBar pageName="Add billing" />
                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <section className="addBillingSection">
                            <div className="addNewBillingPart">
                                <div className="addBillingHeader">
                                    <div className="billingPageTitle">
                                        <h3>Patient Billing</h3>
                                        <div className="pagePath">
                                            Dashboard / patient-login / patient-billing / Add-test /
                                        </div>

                                    </div>

                                </div>
                                <div className="billingAddList">
                                    <div className="addBillingSectionList">
                                        <table>
                                            <tr>
                                                <th>Subject</th>
                                                <th>Amount</th>
                                            </tr>

                                            <tbody className='addbillingTableBody'>
                                            </tbody>
                                            <tr>
                                                <td>Total</td>
                                                <td>{totalAmount}</td>
                                            </tr>
                                        </table>

                                    </div>
                                    <div className="addNewBillingInputBtn">
                                        <button onClick={() => [AddNewBilling(count), Setcount(count + 1)]}>
                                            <img src={AddIcon} alt="" />
                                            <span>Add Another</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="addAndPrintAddBillingBtnSection">
                                <button onClick={() => window.history.back()}>
                                    <img src={ArrowIcon} alt="" />
                                    <span>Go Back</span>
                                </button>
                                <div className="onPageActionBtn">
                                    <button onClick={() => printBilling()} disabled={loadingPrint}>
                                        <img src={PrintIcon} alt="" />
                                        <span>{loadingPrint?"Loading...":'Print Doc.'}</span>
                                    </button>
                                    <button className='billingAddBtn' onClick={(e) => SaveBilling(e)}>
                                        <img src={AddIcon} alt="" />
                                        <span>Add Billing</span>
                                        <span></span>
                                    </button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>


        </>
    )
}

export default AddBilling