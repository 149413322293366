import React from 'react'
import "../../CSS/PatientForm/RiskFactor.css"
import CrossBtnImg from "../../Assets/SVG/patientSVG/CrossCircle.svg"
import { ReactComponent as AddImg } from "../../Assets/SVG/Billing/Add.svg"
import CrossImg from "../../Assets/SVG/patientSVG/CrossCircle.svg"
import { ReactComponent as AddMoreImg } from "../../Assets/SVG/patientSVG/AddMore.svg"
import ArrowLeft from "../../Assets/SVG/Patient/ArrowLeft.svg"


const RiskFactor = ({ Others, PatientData, setPageStatus, BasicDetailsSubmit, loding }) => {
    const removeTage = (e) => {
        e.target.parentNode.parentNode.removeChild(e.target.parentElement)
    }
    const AddAnItem = (e) => {
        let nameInput = document.createElement("input")
        let div = document.createElement("div")
        div.classList.add("AddNewInput")
        let crossImg = document.createElement("img")
        crossImg.addEventListener("click", (e) => removeTage(e))
        crossImg.src = CrossBtnImg

        div.append(nameInput, crossImg)
        const Divlength = e.target.parentElement.parentNode.children.length - 1
        e.target.parentElement.parentElement.insertBefore(div, e.target.parentElement.parentElement.childNodes[Divlength])


        let tagDiv = document.createElement("div")
        let span = document.createElement("span")
        let input = document.createElement("input")
        input.type = "checkbox"
        input.addEventListener("change", (e) => {
            PatientData.SetFormData({
                ...PatientData.FormData,
                riskFactor: {
                    ...PatientData.FormData.riskFactor, [e.target.name]: e.target.checked
                }
            })
        })
        nameInput.addEventListener("keypress", (f) => {
            if (f.key === "Enter") {

                tagDiv.classList.add("toggleInputBtnDiv")
                span.innerHTML = nameInput.value
                input.name = nameInput.value.replace(/\s/g, '');

                input.value = nameInput.value
                // input.check = nameInput.value


                tagDiv.append(span, crossImg, input)
                e.target.parentElement.parentElement.insertBefore(tagDiv, e.target.parentElement.parentElement.childNodes[Divlength])
                e.target.parentNode.parentNode.removeChild(div)
            }
        })

    }





    const AddNote = (e) => {
        let Notename = prompt("Enter Field Name")
        if (Notename !== "" && Notename !== null) {
            const NoteAdd = document.createElement("textarea")
            NoteAdd.cols = "40"
            NoteAdd.rows = "5"
            let stringWithoutSpaces = Notename.replace(/\s/g, '');

            NoteAdd.name = stringWithoutSpaces
            NoteAdd.addEventListener("input", (e) => {
                PatientData.SetFormData({
                    ...PatientData.FormData,
                    riskFactor: {
                        ...PatientData.FormData.riskFactor, [e.target.name]: e.target.value
                    }
                })
            })
            e.target.parentNode.parentNode.parentNode.children[0].append(NoteAdd)
        }


    }


    console.log()
    return (
        <>
            <div className='PatientBasicForm'>
                <div className="riskFactorrmation">
                    <div className="formHeading">
                        {Others}
                    </div>
                    <form onSubmit={(e) => e.preventDefault()} className="patientForm">
                        {/* risk factor confermation btn (YES/NO) */}
                        <div className="ColRapper">
                            <label htmlFor="Have Risk Factor">Do patient have any risk factor</label>
                            <div className="riskfactorConfirmation">
                                <input type="radio" name='haveRiskFactor' value={"Yes"}
                                    onChange={(e) => PatientData.SetFormData({
                                        ...PatientData.FormData,
                                        riskFactor: {
                                            ...PatientData.FormData.riskFactor, [e.target.name]: true
                                        }
                                    })}
                                    checked={PatientData.FormData.riskFactor.haveRiskFactor ? true : false}
                                    className='checkInputButtons' />
                                <input type="radio" name='haveRiskFactor' value={"No"} onChange={(e) => PatientData.SetFormData({
                                    ...PatientData.FormData,
                                    riskFactor: {
                                        ...PatientData.FormData.riskFactor, [e.target.name]: false
                                    }
                                })}
                                    checked={PatientData.FormData.riskFactor.haveRiskFactor ? false : true}
                                    className='checkInputButtons' />
                            </div>
                        </div>
                        {
                            PatientData.FormData.riskFactor.haveRiskFactor ?
                                <>
                                    <div className="ColRapper">
                                        <label htmlFor="">What kind of risk factors patient have?</label>
                                        <div className="familyHistorySection">
                                            <div className="prevMedicalSelector">

                                                <div className="toggleInputBtnDiv">
                                                    <span>Food Allergy </span>
                                                    <input type="checkbox" name='foodallergy ' value={"Food Allergy "} onChange={(e) => PatientData.SetFormData({
                                                        ...PatientData.FormData,
                                                        riskFactor: {

                                                            ...PatientData.FormData.riskFactor, [e.target.name]: e.target.checked
                                                        }
                                                    })} checked={PatientData.FormData.riskFactor.foodallergy} />
                                                    <img src={CrossImg} className='tagsRemover' onClick={(e) => removeTage(e)} alt='' />
                                                </div>
                                                <div className="toggleInputBtnDiv">
                                                    <span>Dust Allergy</span>
                                                    <input type="checkbox" name='dustallergy' value={"Dust Allergy"} onChange={(e) => PatientData.SetFormData({
                                                        ...PatientData.FormData,
                                                        riskFactor: {
                                                            ...PatientData.FormData.riskFactor, [e.target.name]: e.target.checked
                                                        }
                                                    })} checked={PatientData.FormData.riskFactor.dustallergy} />
                                                    <img src={CrossImg} className='tagsRemover' onClick={(e) => removeTage(e)} alt='' />
                                                </div>

                                                <button type='button' className='addMoreBtn' onClick={(e) => AddAnItem(e)}>

                                                    <AddMoreImg className='addBtnSvg' />
                                                    <span>AddMore</span>
                                                    <span></span>

                                                </button>
                                            </div>
                                        </div>
                                    </div>















                                    <div className="ColRapper">
                                        <label htmlFor="Surgery">Do patient have any surgery</label>
                                        <div className="riskfactorConfirmation">
                                            <input type="radio" name='haveSurgery' value={"Yes"} checked={PatientData.FormData.riskFactor.haveSurgery ? true : false} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, riskFactor: { ...PatientData.FormData.riskFactor, [e.target.name]: true } })} className='checkInputButtons' />
                                            <input type="radio" name='haveSurgery' value={"No"} checked={PatientData.FormData.riskFactor.haveSurgery ? false : true} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, riskFactor: { ...PatientData.FormData.riskFactor, [e.target.name]: false } })} className='checkInputButtons' />
                                        </div>
                                    </div>
                                    {
                                        PatientData.FormData.riskFactor.haveSurgery ? <>

                                            <div className="ColRapper">
                                                <label htmlFor="Surgery type">If yes what kind of surgery did patient have?</label>
                                                <div className="prevMedicalSelector">

                                                    <div className="toggleInputBtnDiv">
                                                        <span>Hand Surgery</span>
                                                        <input type="checkbox" name='patientHandSurgery' value={"Hand Surgery"} onChange={(e) => PatientData.SetFormData({
                                                            ...PatientData.FormData,
                                                            riskFactor: {

                                                                ...PatientData.FormData.riskFactor, [e.target.name]: e.target.checked
                                                            }
                                                        })} checked={PatientData.FormData.riskFactor.patientHandSurgery} />
                                                        <img src={CrossImg} className='tagsRemover' onClick={(e) => removeTage(e)} alt='' />
                                                    </div>
                                                    <div className="toggleInputBtnDiv">
                                                        <span>Hyperlipidemia</span>
                                                        <input type="checkbox" name='hyperlipidemia' value={"Hyperlipidemia"} onChange={(e) => PatientData.SetFormData({
                                                            ...PatientData.FormData,
                                                            riskFactor: {
                                                                ...PatientData.FormData.riskFactor, [e.target.name]: e.target.checked
                                                            }
                                                        })} checked={PatientData.FormData.riskFactor.hyperlipidemia} />
                                                        <img src={CrossImg} className='tagsRemover' onClick={(e) => removeTage(e)} alt='' />
                                                    </div>

                                                    <button type='button' className='addMoreBtn' onClick={(e) => AddAnItem(e)}>

                                                        <AddMoreImg className='addBtnSvg' />
                                                        <span>AddMore</span>
                                                        <span></span>

                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                            : ""
                                    }

                                    <div className="ColRapper">
                                        <label htmlFor="patient Others Info">Any other details regarding the patient?</label>
                                        <div className="medicalproblemsNotes">
                                            <div className="notesInput">
                                                <textarea name="RiskFactorNotes" id="RiskFactorNotes" cols="40" rows="5" value={PatientData.FormData.riskFactor.RiskFactorNotes} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, riskFactor: { ...PatientData.FormData.riskFactor, [e.target.name]: e.target.value } })}></textarea>
                                            </div>
                                            <div className="addExtranoteBtn">
                                                <button type='button' className='addMoreBtn' onClick={(e) => AddNote(e)}>

                                                    <AddImg className='addBtnSvg' />
                                                    <span>AddMore</span>
                                                    <span></span>

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </> : ""
                        }

                    </form>

                </div>
            </div>
            <div className="patientAddActionButton flex-btn-sec">

                <button onClick={() => setPageStatus({ Basic: false, familyInfo: true, riskFactor: false })}>
                    <img src={ArrowLeft} alt="" />
                    <span>Go Previous</span>
                </button>

                <button onClick={() => { BasicDetailsSubmit() }} disabled={loding} >
                    <span>{loding ? "Submitting..." : "Submit"}</span>
                </button>

            </div>



        </>

    )
}

export default RiskFactor