import { React, createRef, useState } from 'react'
import { Link } from 'react-router-dom'
import "../../../CSS/Setting/Setting.css"
import CameraImg from "../../../Assets/SVG/WhiteLabel/Camera.svg"
import TopBar from '../../Bars/TopBar'
import SideBar from '../../Bars/SideBar'
import SettingTopNavBar from '../../Setting/SettingTopNavBar'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
const WhiteLabel = () => {
    const [ImagePreview, SetImagePreview] = useState("")
    const [WhiteLableData, SetWhiteLableData] = useState({
        imageUrl: "",
        hospitalName: "",
        password: "",
        adminId: localStorage.getItem("Id")
    })
    const [Image, SetImage] = useState()
    const UploadImg = createRef()

    const UploadLogo = (e) => {
        if (e.target.files) {
            SetImage(e.target.files[e.target.files.length - 1])
            SetImagePreview(URL.createObjectURL(e.target.files[0]))
        }
    }

    // console.log(Image)

    const submitForm = (e) => {
        e.preventDefault()

        if (Image) {
            const formData = new FormData();
            const fileName = Date.now() + "--" + Image.name
            formData.append('name', fileName)
            formData.append('photo', Image)
            formData.append("hospitalName", e.target.hospitalName.value)
            formData.append("hospitalPassword", e.target.hospitalPassword.value)
            formData.append("imageUrl", fileName)
            SetWhiteLableData(prevData => ({ ...prevData, imageUrl: fileName }))

            axios.post(process.env.REACT_APP_BACKEND_URL + "/hospital/add", {
                hospitalName: WhiteLableData.hospitalName,
                password: WhiteLableData.password,
                adminId: WhiteLableData.adminId,
                imageUrl: fileName
            }, {
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then((res) => {
                // console.log(res)
                localStorage.setItem("hospitalId", res.data.hospitalId)

                axios.post(process.env.REACT_APP_BACKEND_URL + "/upload/img/", formData, {
                    headers: {
                        token: localStorage.getItem("token")
                    }
                }).then((res) => {
                    console.log("Image uploaded Successfully")
                    window.location.reload()
                    window.location.href = "/user/dashboard"
                }).catch((err) => {
                    toast.error(err.response.data)
                })

                toast.success("Details Uploaded Successfully")
                e.target.reset()
                SetImagePreview("")
            }).catch((err) => {
                toast.error(err.response.data)
            })


        }



    }
    return (
        <>
            <ToastContainer />
            <div className="ParentRapper">
                <TopBar pageName="Setting" />
                <div className="pageDetails">
                    <SideBar />
                    <form onSubmit={(e) => submitForm(e)} className="page">
                        <SettingTopNavBar />
                        <div className="logoChangeSection">
                            <h3>

                                Name and logo



                            </h3>
                            {
                                localStorage.getItem("hospitalId") !== "NA" ?
                                    <>
                                        <p>Changing your name below will update your name on your profile.


                                            <Link to={localStorage.getItem("hospitalId") !== "NA" ? "/user/settings/adminprofile" : ""}>
                                                View your profile
                                            </Link>

                                        </p>
                                    </>
                                    : "Complete White lable First"
                            }
                            <div className="LogoInputSec">
                                <div className="logoPreviewer" style={{ "--PreviewImage": `url(${ImagePreview})` }}>
                                </div>
                                <div className="logoSelector" >
                                    <img src={CameraImg} alt="" />
                                    <input type="file" name='photo' ref={UploadImg} onChange={UploadLogo} multiple={false} accept='image/*' />
                                </div>
                            </div>



                        </div>
                        <div className="hospitalUserData">
                            <div className="hospitalName">
                                <label htmlFor="Hospital Name">Hospital Name</label>
                                <input type="text" name="hospitalName" id="hospitalName" onChange={(e) => SetWhiteLableData({ ...WhiteLableData, [e.target.name]: e.target.value })} required />
                            </div>
                            <div className="hospitalPassword">
                                <label htmlFor="Hospital Password">Hospital Password</label>
                                <input type="Password" name="password" id="hospitalPassword" onChange={(e) => SetWhiteLableData({ ...WhiteLableData, [e.target.name]: e.target.value })} required />
                            </div>
                            <div className="settingControlButtons">
                                <button type='submit' className='settingSubmit'>
                                    Save
                                </button>
                                <button className='settingCancel' type='reset'>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </>
    )
}

export default WhiteLabel