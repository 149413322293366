/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import DoctorSideBar from '../../Bars/DoctorSideBar'
import TopBar from '../../Bars/TopBar'
import Vitals from '../../PatientForm/Vitals'
import "../../../CSS/Profiles/Doctor/PatientForm.css"
import ArrowRight from "../../../Assets/SVG/Patient/ArrowRight.svg"
import ArrowLeft from "../../../Assets/SVG/Patient/ArrowLeft.svg"
import RiskFactor from '../../PatientForm/RiskFactor'
import FamilyInformation from '../../PatientForm/FamilyInformation'
import { useLocation, useNavigate } from 'react-router-dom'
import TopNavBar from './TopNavBar'
import MedicalProblems from '../../PatientForm/MedicalProblems'
import Diagnostics from '../../PatientForm/Diagnostics'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import Medicine from '../../PatientForm/Medicine'
import "../../../CSS/PatientForm/Diagnosis.css"
import Loader from '../../Loader/Loader'

const PatientForm = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [MedicineData, SetMedicineData] = useState([])
    const [doctorPageStatus, setDoctorPageStatus] = useState(false)
    const [ZeroPage, setZeroPage] = useState(false)
    const [FormPage, SetFormPage] = useState(localStorage.getItem("adminType") === "Doctor" ? 1 : 0)
    const MedicineSubmit = {
        appointmentId: location.state.id,
        patientId: location.state.patientId,
        medicine: {}
    }


    const [FormData, SetFormData] = useState({
        hospitalId: localStorage.getItem("hospitalId"),
        appointmentId: location.state.id,
        patientId: location.state.patientId,
        vitals: {},
        familyInfo: { kins: false },
        riskFactor: { haveRiskFactor: false },
        medicalProblem: { allergie: false },
        diagnostics: {},
        medicine: [],
        diagnosis: "",
        basicStatus: localStorage.getItem("adminType") === "Pa" ? true : false
    })

    const Forms = () => {
        if (FormPage === 0) {
            return <Vitals PatientData={{ FormData, SetFormData }} Others={"Vitals"} />
        } else if (FormPage === 1) {
            return <MedicalProblems PatientData={{ FormData, SetFormData }} Others={"Medical Problem"} />
        } else if (FormPage === 2) {
            return <Diagnostics PatientData={{ FormData, SetFormData }} Others={"Lab Test"} />
        } else if (FormPage === 3) {
            return <Medicine PatientData={{ FormData, SetFormData }} Others={"Medicine"} />
        }
    }

    const SubmitPatient = (e) => {
        if (localStorage.getItem('adminType') === 'Pa') {
            e.target.disabled = true
        }

        SetFormData({ ...FormData, basicStatus: true })
        MedicineSubmit.medicine = MedicineData
        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/otherInfo/add", JSON.parse(JSON.stringify(FormData)), {//add patient others info
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            if (localStorage.getItem('adminType') === 'Pa') {
                e.target.disabled = false
                navigate("/user/patientlist", { state: { reload: true } })
            }
            if (localStorage.getItem('adminType') === 'Doctor') {
                toast.success("Basic Details Update")
            }
        }).catch((err) => {
            toast.warning("Already Data Present")
        })

    }

    const Consulted = (e) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/appointment/status/change", { status: "Consulted", appointmentId: location.state.id }, {//change patient appointment status confirs to consulted
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            e.target.disabled = false
        }).catch((err) => {
            console.log(err.response.data)
        })
    }

    const [ServerRes, SetServerRes] = useState(null)

    const submitFromDoctor = (e) => {
        e.target.disabled = true
        if (FormData.basicStatus === false && doctorPageStatus) {
            SetFormData({ ...FormData, basicStatus: true })
        }
        axios.put(process.env.REACT_APP_BACKEND_URL + "/patients/otherInfo/add", FormData, {//set medicine name and it's doses 
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            e.target.disabled = false
            navigate('/user/doctor/download/pdf/option', { state: { appointmentId: location.state.id, patientId: location.state.patientId } })
            Consulted(e)
        }).catch((err) => {
            console.log(err.response.data)
        })

    }



    useEffect(() => {
        getAppointment()
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        if (localStorage.getItem('adminType') === 'Doctor') {
            if (FormPage < 1) {
                setDoctorPageStatus(true)
            }
            if (FormPage === 0 && doctorPageStatus) {

                document.getElementsByClassName("disablebutton")[0].disabled = false
                setZeroPage(true)
            }
        }
        if (doctorPageStatus && ZeroPage) {
            SubmitPatient()
            setZeroPage(false)
            setDoctorPageStatus(false)
        }
        // eslint-disable-next-line
    }, [FormPage, doctorPageStatus])


    const getAppointment = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/otherinfo/get", { appointmentId: location.state.id }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetServerRes(res)
            if (localStorage.getItem("adminType") === "Doctor") {

                if (res.data.length === 0 || res.data.basicStatus === false) {
                    toast.warning("Basic Details Not added !")
                    document.getElementsByClassName("disablebutton")[0].disabled = true

                }
            }
        }).catch((err) => {
            console.log(err.response.data)
        })
    }

    return (
        <>
            {
                ServerRes === null ?


                    <div className="loaderForm" style={{ position: 'absolute', width: "100%", height: "100%", background: "#80808063", zIndex: 99999 }}>
                        <Loader />
                    </div>
                    : ""
            }
            <ToastContainer />
            <div className="ParentRapper">
                <TopBar pageName={window.localStorage.getItem("adminType")} />

                <div className="pageDetails">
                    <DoctorSideBar />
                    <div className="page">
                        <TopNavBar />
                        <section className='doctorProfilePatientForm'>
                            <div className="doctorProfileAppointmentForm">
                                {
                                    Forms()
                                }
                            </div>
                            <div className="appointmentFormChangeBtns">
                                <button onClick={() => SetFormPage(FormPage === 0 ? 0 : FormPage - 1)} disabled={FormPage === 0 ? true : false}>
                                    <img src={ArrowLeft} alt="" />
                                    <span>Go Previous</span>
                                </button>



                                {window.localStorage.getItem("adminType").toLocaleLowerCase() === "doctor" ?
                                    <button className='disablebutton'
                                        onClick={(e) =>
                                            FormPage === 3 ?
                                                submitFromDoctor(e)
                                                : SetFormPage(FormPage + 1)}>

                                        {
                                            FormPage === 3 ? "Submit Data" : <span>

                                                Go Next
                                            </span>
                                        }

                                        {
                                            FormPage === 3 ?
                                                "" :
                                                <img src={ArrowRight} alt="" />

                                        }
                                    </button> : <button

                                        onClick={(e) =>
                                            FormPage === 0 ?
                                                SubmitPatient(e)
                                                : SetFormPage(FormPage + 1)

                                        }
                                    >

                                        {FormPage === 0 ?
                                            "Submit Data" : <span>

                                                Go Next
                                            </span>
                                        }

                                        {
                                            FormPage === 0 ?
                                                "" :
                                                <img src={ArrowRight} alt="" />
                                        }
                                    </button>
                                }

                            </div>

                        </section>

                    </div>
                </div >
            </div >



        </>
    )
}

export default PatientForm