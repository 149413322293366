/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import List from "../../../Assets/SVG/Doctor/ListI.svg"
import Person from "../../../Assets/SVG/Doctor/User.svg"
import Clock from "../../../Assets/SVG/Doctor/Timer.svg"
import Action from "../../../Assets/SVG/Doctor/Action.svg"
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import BlankAlert from '../../BlankComp/BlankAlert'
import Loader from '../../Loader/Loader'


const AppointmentAllList = ({ FilterDate }) => {
    const navigate = useNavigate()
    const [Appointments, SetAppointments] = useState(null)
    const [FilterAppointments, SetFilterAppointments] = useState(null)
    const [EmptyAppointment, SetEmptyAppointment] = useState("")

    useEffect(() => {
        if (FilterDate !== "" && typeof (FilterAppointments) !== "string") {
            const filter = Appointments && Appointments.filter((data) => {
                const date = new Date(data.date)
                const FilterD = new Date(FilterDate)
                date.setHours(0, 0, 0, 0)
                FilterD.setHours(0, 0, 0, 0)
                return FilterDate >= date && FilterDate <= date
            })
            SetFilterAppointments(filter)
        } else {
            SetFilterAppointments(Appointments)
        }
    }, [FilterDate])


    const GetAppointmentList = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/appointment/status/approved/get", { doctorId: window.localStorage.getItem("adminType") === "Doctor" ? localStorage.getItem("Id") : window.localStorage.getItem("doctor_id") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetAppointments(res.data)
            SetFilterAppointments(res.data)
            setReload(false)
        }).catch((err) => {
            console.log(err.response.data)
        })
    }

    const location = useLocation();
    const [reload,setReload] = useState(location.state?.reload)
    

    useEffect(() => {
        GetAppointmentList()
    }, [reload])



    const PationAppointmentForms = (_id, pid) => {

        navigate("/user/patientlist/patientForm", { state: { id: _id, patientId: pid } })
    }


    return (
        <>
            <div className="consultAdmitPatientList">


                <table className="docConSultedPatientList">
                    <thead>
                        <tr>
                            <th>
                                <img src={List} alt="" />
                                <span>Token No</span>
                            </th>
                            <th>
                                <img src={Person} alt="" />
                                <span>Name</span>
                            </th>
                            <th>
                                <img src={Clock} alt="" />
                                <span>Visiting Time</span>
                            </th>
                            <th>
                                <img src={Action} alt="" />
                                <span>Action</span>
                            </th>
                        </tr>
                    </thead>
                    <div className="patientConsultedListBody">


                        <tbody>


                            {FilterAppointments && typeof (FilterAppointments) !== "string" ? FilterAppointments.map((data, count) => {
                                return (

                                    <tr key={count + 1}>
                                        <td>{count + 1}</td>
                                        <td>{data.patientInfo.patientFirstName + "" + data.patientInfo.patientLastName}</td>
                                        <td>{data.time}</td>
                                        <td>
                                            <button onClick={() => PationAppointmentForms(data._id, data.patientId)}>Consult</button>
                                        </td>
                                    </tr>
                                )
                            }) : ""

                            }

                        </tbody>
                    </div>
                    {
                        FilterAppointments === null ?
                            <Loader /> : ""
                    }
                    {
                        FilterAppointments && FilterAppointments.length === 0 || typeof (FilterAppointments) === "string" ?
                            <BlankAlert msg="No accpeted appointments in this date" /> : ""
                    }
                </table>
            </div>
        </>
    )
}

export default AppointmentAllList