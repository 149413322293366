/* eslint-disable no-unused-vars */
import { React, useEffect, useState } from 'react'
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import PatientListCard from '../Profiles/Diagnostics/PatientListCard'
import { ReactComponent as SearchIcon } from "../../Assets/SVG/patientSVG/SearchBtn.svg"
import ExportImg from "../../Assets/SVG/patientSVG/Export.svg"

import FilterBtn from "../../Assets/SVG/Diagnostics/Filter.svg"
import * as XLSX from 'xlsx';
import axios from 'axios'
const AllDiagnostics = () => {

    //==========Fiture Use=======================
    const [ModalOpen, SetModalOpen] = useState(false)
    //===========================================

    const [Diagnostcs, SetDiagnostcs] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')

    const getDiagnostics = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/diagnostics/book/get", { hospitalId: localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetDiagnostcs(res.data)
        }).catch((err) => {

            console.log(err.response.data)
        })
    }

    useEffect(() => {
        getDiagnostics()
    }, [])


    const patientFilter = Diagnostcs ? Diagnostcs.filter(patient => {
        const quary = searchQuery.toLowerCase()
        return (
            patient.patientBasicInfo.patientFirstName.toLowerCase().includes(quary) ||
            patient.patientBasicInfo.patientLastName.toLowerCase().includes(quary) ||
            patient.patientBasicInfo.uhid.toLowerCase().includes(quary)
        )
    }) : []


    const exportToExcel = () => {
        const data = patientFilter.map((patient, index) => ({
            "SL No": index + 1,
            "First Name": patient.patientBasicInfo.patientFirstName,
            "Last Name": patient.patientBasicInfo.patientLastName,
            "UHID": patient.patientBasicInfo.uhid,
            "Date": patient.date,
            "time": patient.time,
            "Diagnostics Info": patient.diagnosticsInfo.join(", "),
            "Lab Test Status": patient.labTestStatus,
            "Status": patient.status
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Patients");

        XLSX.writeFile(workbook, `Patients_Report_${Date.now()}.xlsx`);
    };



    return (
        <div className="ParentRapper">
            <TopBar pageName="Setting" />

            <div className="pageDetails">
                <SideBar />
                <div className="page">

                    <section className="diagnosticsProfileSection flex-col">
                        <div className="flexRow justify-between">
                            <h3>Reports</h3>
                            <div className="flexRow flex-1 justify-end g-1">
                                <div className="searchSection">
                                    <input type="text" placeholder='Search...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                    <button className='searchSectionBtn'>
                                        <SearchIcon />
                                    </button>
                                </div>

                                <button type="button" className="ExportBtn" onClick={exportToExcel}>
                                    <img src={ExportImg} alt="" />
                                    <span>Export</span>
                                </button>


                            </div>
                        </div>
                        <div className='flex-1 scrollAbleDiv'>
                            <div className="flex-col g-2">
                                {
                                    patientFilter.map((data, count) => {
                                        return (
                                            <PatientListCard data={data} slno={count + 1} status={data.status} pName={data.patientBasicInfo.patientFirstName + " " + data.patientBasicInfo.patientLastName} pid={data.patientBasicInfo.uhid} Date={data.date} key={data._id} />

                                        )
                                    })
                                }


                            </div>
                        </div>
                    </section>


                </div>
            </div>
        </div>

    )
}

export default AllDiagnostics