import React from 'react'
import "../../CSS/Appointment/DoctorProfileCards.css"
import Email from "../../Assets/SVG/patientSVG/Envelop.svg"
import Phone from "../../Assets/SVG/patientSVG/Contact.svg"
import { useNavigate } from 'react-router-dom'

const DoctorProfileCards = ({ DoctorProfile, pid }) => {
    const navigate = useNavigate()
    const AppointBook = (e) => {
        navigate("/user/patient/appointment/bookappointment", { state: { data: e } })
    }


    return (
        <>
            <div className="DoctorProfileCardOuter">
                <div className="DoctorProfileCardInner">
                    <div className="doctorSpecialty">{DoctorProfile.specialization}</div>
                    <div className="doctorBasicDetails">
                        <h2>{DoctorProfile.name}</h2>
                        <p>{DoctorProfile.id}</p>
                    </div>
                    <div className="doctorContactDetails">
                        <div><img src={Email} alt="" /><span>{DoctorProfile.email}</span></div>
                        <div><img src={Phone} alt="" /><span>{DoctorProfile.phone}</span></div>
                    </div>


                </div>
                <button className="doctorSelectionBtn" onClick={() => AppointBook({ docId: DoctorProfile._id, pId: pid })}>
                    Book Appointment
                </button>
            </div>


        </>
    )
}

export default DoctorProfileCards