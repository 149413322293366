import React from 'react'
import { Link } from 'react-router-dom'


const NotFound = () => {
  return (
    <>
      <>
        <div className="pageNotFound" style={{ position: 'absolute', top: 0, left: 0, width: "100%", height: "100vh", display: "flex",flexDirection:"column", alignItems: "center", justifyContent: "center" }}>
          <h2 style={{ fontSize: "4.5rem", color: "gray" }}>Not Found</h2>
          <Link to="/">Back To Home</Link>
        </div>

      </>



    </>
  )
}

export default NotFound