import React, { useState } from 'react'
import "../../CSS/Appointment/BookAppointment.css"
import Calendar from 'react-calendar'
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import "../../CSS/CalenderCss.css"
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import backBtn from '../../Assets/SVG/back.svg'

function BookAppointment() {
    const location = useLocation()
    const navigate = useNavigate()
    const [disable,setDisable] = useState(false)
    const [Appointment, SetAppointment] = useState({
        date: "",
        patientId: location.state.data.pId,
        doctorId: location.state.data.docId,
        time: "",
        patientType: "",
        hospitalId: localStorage.getItem("hospitalId")
    })
    const ChangeData = (e) => {
        const originalDate = new Date(e);
        const year = originalDate.getFullYear();

        const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        SetAppointment({ ...Appointment, date: formattedDate })

    }


    const RequestAppointment = (e) => {
        e.preventDefault()
        setDisable(true)
        axios.post(process.env.REACT_APP_BACKEND_URL + "/appointment/add", JSON.parse(JSON.stringify(Appointment)), {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            toast.success(res.data)
            e.target.reset()
            setTimeout(() => {
                setDisable(false)
                navigate('/user/appointments')
            }, 3000);
        }).catch((err) => {
            setDisable(false)
            toast.error(err.reponse.data)
        })

    }

    return (
        <>
            <ToastContainer />
            <div className="ParentRapper">
                <TopBar pageName="Dashboard" />

                <div className="pageDetails">
                    <SideBar />
                    <div className="page">


                        <div className="appointBooingFormSection">
                            <div className="appointBooingFormHeader">
                                <h3 className="pageTitle">Schedule Appointment</h3>
                                <div className="pagePath">
                                    <img src={backBtn} alt="" onClick={() => window.history.back()} />
                                    Dashboard / patient-Profile /appointment
                                </div>
                            </div>

                            <form onSubmit={(e) => RequestAppointment(e)} className="appointmentBookingForm">
                                <div className="appointmentBookingFormPatientType">
                                    <h4>Patient-Type</h4>
                                    <div>

                                        <input type="radio" value={"In Patient"} name="patientType" onChange={(e) => SetAppointment({ ...Appointment, [e.target.name]: e.target.value })} id="patientType" required disabled={true} />
                                        <input type="radio" value={"Out Patient"} name="patientType" onChange={(e) => SetAppointment({ ...Appointment, [e.target.name]: e.target.value })} id="patientType" required />
                                    </div>
                                </div>
                                <div className="appointBookingMentDateAndTime">
                                    <div className="appointmentBookingDate">
                                        <p>Choose Date</p>
                                        <Calendar onChange={(e) => ChangeData(e)} minDate={new Date()}/>
                                    </div>

                                    <div className="appointmentBookingTime">
                                        <div className="appointmentBookingTimeInputSection">

                                            <input type="radio" name="time" onChange={(e) => SetAppointment({ ...Appointment, [e.target.name]: e.target.value })} value={"9:30 - 10:30"} className='appointmentBookingTimeInput' id="" required />
                                            <input type="radio" name="time" onChange={(e) => SetAppointment({ ...Appointment, [e.target.name]: e.target.value })} value={"10:30 - 11:30"} className='appointmentBookingTimeInput' id="" required />
                                            <input type="radio" name="time" onChange={(e) => SetAppointment({ ...Appointment, [e.target.name]: e.target.value })} value={"11:30 - 12:30"} className='appointmentBookingTimeInput' id="" required />
                                            <input type="radio" name="time" onChange={(e) => SetAppointment({ ...Appointment, [e.target.name]: e.target.value })} value={"12:30 - 13:30"} className='appointmentBookingTimeInput' id="" required />
                                            <input type="radio" name="time" onChange={(e) => SetAppointment({ ...Appointment, [e.target.name]: e.target.value })} value={"13:30 - 14:30"} className='appointmentBookingTimeInput' id="" required />
                                        </div>
                                        <button type='submit' className='appointmentBookingRequestBtn' disabled={disable}>
                                            Request
                                        </button>
                                    </div>




                                </div>



                            </form>


                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}

export default BookAppointment