import React from 'react'

const EMRProfileCard = ({ PatientDetails }) => {

    return (
        <>

            <div className="flexRow patientProfileSubCard relative align-center g-1" >
                <input type="radio" name="patientSelect" value={PatientDetails.data._id} className='emrSelector' onClick={(e) => PatientDetails.SetPatientDetails(e.target.value)} />
                <div className="flex patientSubprofileSelectorImg">
                    <span className='flex align-center justify-center'>
                        {PatientDetails.data.patientFirstName.slice(0, 2).toUpperCase()}
                    </span>
                </div>
                <div className="flex-col  profileCardPatientDetails">
                    <h4>{PatientDetails.data.patientFirstName +" "+ PatientDetails.data.patientLastName}</h4>
                    <span className='size10' style={{ color: "#272727" }}>
                        UHID:{PatientDetails.data.uhid}
                    </span>
                </div>
            </div>

        </>
    )
}

export default EMRProfileCard