import React from 'react'
import "../../CSS/Navbar/Navbar.css"

import NavLogo from "../../Assets/SVG/NavLogo.svg"
const TopBar = (prop) => {
    return (
        <>
            <div className="navTopSection">

                <div className="NavTop">
                    <img src={NavLogo} alt="" className='NavLogo' />
                </div>
                <div className="pageStatus">
                    <h3>{prop.pageName}</h3>
                </div>
            </div>
        </>
    )
}

export default TopBar