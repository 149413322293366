import React from 'react'
import "../../../../CSS/Profiles/SystemAdmin/Payroll/PayrollStructure.css"
import TopBar from '../../../Bars/TopBar'
import SideBar from '../../../Bars/SideBar'

const PayrollStructure = () => {
    return (
        <>

            <div className="ParentRapper">
                <TopBar pageName="Payroll" />
                <div className="pageDetails">
                    <SideBar />
                    <div className="page">


                        <section className='payroleStructure'>
                            <div className="flex-col"></div>
                            <div className="flex"></div>
                        </section>


                    </div>
                </div>
            </div>


        </>
    )
}

export default PayrollStructure