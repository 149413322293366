import React, { useEffect, useState } from 'react'
import "../../../CSS/Profiles/Patient/PatientProfile.css"
import TopBar from '../../Bars/TopBar'
import SideBar from '../../Bars/SideBar'
import PatientCard from '../../Patient/PatientCard'
import DashboardCards from '../../Dashboard/DashboardCards'
import Calenderimg from "../../../Assets/SVG/DashboardSvg/Calender.svg"
import MedicalIcon from "../../../Assets/SVG/DashboardSvg/Medical.svg"
import EMRimg from "../../../Assets/SVG/DashboardSvg/EMRSvg.svg"
import Waletimg from "../../../Assets/SVG/DashboardSvg/Walet.svg"
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import Loader from '../../Loader/Loader'
import backBtn from '../../../Assets/SVG/back.svg'

const PatientProfile = () => {
    const location = useLocation();

    const [ProfileData, SetProfileData] = useState(null)

    useEffect(() => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/get", { phone: location.state.phone, uhid: location.state.uhid }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetProfileData(res.data[0])
        }).catch((err) => {
            console.log(err.response.data)
        })

        // eslint-disable-next-line 
    }, [])
    return (
        <>
            <div className="ParentRapper">
                <TopBar pageName="Profile" />
                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <div className="pagePath">
                            <img src={backBtn} alt="" onClick={() => window.history.back()}/>
                            Dashboard / patient-Profile /
                        </div>
                        {ProfileData === null ? <Loader /> :
                            <>
                                <div className="patientProfile">
                                    <PatientCard PatientData={ProfileData && ProfileData} path={location.pathname} />
                                    <div className="PatientprofileLinkCards">

                                        <div className="patientProfileFetures">
                                            {
                                                localStorage.getItem("adminType").toLowerCase() === "hospitaladmin" || localStorage.getItem("adminType").toLowerCase() === "receptionist" ?

                                                    <>
                                                        <DashboardCards path="/user/patient/appointment" id={ProfileData && ProfileData._id} srcSvg={Calenderimg} Title="Appointment" />
                                                        <DashboardCards path="/user/patient/appointment/diagnosticbooking" id={ProfileData && ProfileData._id} srcSvg={MedicalIcon} Title="Diagnostics" />
                                                        <DashboardCards path="/user/patient/profile/PatientBilling" id={ProfileData && ProfileData._id} srcSvg={Waletimg} Title="Bill Details" />
                                                    </>

                                                    : ""
                                            }
                                            <DashboardCards path="/user/patient/profile/patientEMR" id={ProfileData && ProfileData._id} srcSvg={EMRimg} phoneno={ProfileData && ProfileData.phoneno} Title="EMR" />
                                        </div>
                                    </div>
                                </div>
                            </>}
                    </div>
                </div>

            </div>

        </>
    )
}

export default PatientProfile