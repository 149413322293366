import React from 'react'

const AppointmentStatus = ({ Image, Text, Color, Background }) => {
    return (
        <>
            <div className="viewPatientVisitedStatus" style={{ "--EMRPagePatientAppointmentBColor": Background, "--EMRPagePatientAppointmentColor": Color }}>

                <img src={Image} alt="" />
                <span>{Text}</span>
            </div>

        </>
    )
}

export default AppointmentStatus