/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'

function Logout() {
    useEffect(() => {
        var keys = Object.keys(localStorage);

        for (let i in keys) {
            LogRemove(keys[i])
        }
        window.location.href = "/"
        window.location.reload()
    }, [])
    const LogRemove = (itemName) => {
        localStorage.removeItem(itemName)
    }
}

export default Logout