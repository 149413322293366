import React, { useEffect, useState } from 'react'
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import "../../CSS/Appointment/DoctorList.css"
import SearchBtn from "../../Assets/SVG/patientSVG/SearchBtn.svg"
import DoctorProfileCards from './DoctorProfileCards'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import backBtn from '../../Assets/SVG/back.svg'

const DoctorList = () => {
    const location = useLocation();
    const [DoctorData, SetDoctorData] = useState(null)
    const [searchText, setSearchText] = useState('');
    useEffect(() => {

        if (location.state.dept === "all" && location.state !== null) {
            axios.post(process.env.REACT_APP_BACKEND_URL + "/doctor/getall", { hospitalId: localStorage.getItem("hospitalId") }, {
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then((res) => {
                SetDoctorData(res.data)
            }).catch((err) => {
                console.log(err.reponse.data)
            })
        } else if (location.state.dept === "single" && location.state !== null) {
            axios.post(process.env.REACT_APP_BACKEND_URL + "/doctor/get/dept", { dept: location.state.data, hospitalId: localStorage.getItem("hospitalId") }, {
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then((res) => {
                SetDoctorData(res.data)
            }).catch((err) => {
                console.log(err.reponse.data)
            })



        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filteredDoctors = DoctorData && DoctorData.filter((doctor) => {
        const searchLower = searchText.toLowerCase();
        return (
            doctor.name.toLowerCase().includes(searchLower) ||
            doctor.phone.includes(searchText) ||
            doctor.email.toLowerCase().includes(searchLower)
        );
    });

    return (
        <>
            <div className="ParentRapper">
                <TopBar pageName="Appointment" />

                <div className="pageDetails">
                    <SideBar />
                    <div className="page">

                        <div className="appointmentSelectionDocDepartment">
                            <div className="appintmentSelectionheader">
                                <div className="appointmentSelectionHeaderLeft">

                                    <h3 className="pageTitle">
                                        All Doctors
                                    </h3>
                                    <div className="pagePath">
                                        <img src={backBtn} alt="" onClick={() => window.history.back()}/>
                                        Dashboard / patient-Profile /appointment
                                    </div>
                                </div>


                                <div className="appointmentSelectionSearch">
                                    <input type="text" name='doctorname' placeholder='Search..' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                    <button><img src={SearchBtn} alt="" /></button>
                                </div>
                            </div>

                            <div className="appointSelectionDoctors">
                                <div className="appointmentSelectionDoctorCards">
                                    {
                                        filteredDoctors && filteredDoctors.map((profile) => {
                                            return (
                                                <DoctorProfileCards key={profile._id} pid={location.state.id} DoctorProfile={profile} />

                                            )
                                        })
                                    }


                                </div>
                            </div>





                        </div>




                    </div>
                </div>
            </div>


        </>
    )
}

export default DoctorList