import React, { useEffect, useState } from 'react'
import "../../../CSS/Profiles/Doctor/DoctorProfile.css"
import "../../../CSS/Profiles/Doctor/DoctorCalender.css"
import TopBar from '../../Bars/TopBar'
import DoctorSideBar from '../../Bars/DoctorSideBar'

import TopNavBar from './TopNavBar'
import MyCalendar from './AppointmentCalender'
import axios from 'axios'
const DoctorProfile = () => {
    const [Event, SetEvent] = useState(null)
    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + "/appointment/doctor/get", {
            headers: {
                token: window.localStorage.getItem("token")
            }
        }).then((res) => {
            SetEvent(res.data)
        }).catch((err) => {
            console.log(err.response.data)
        })
    }, [])

    return (
        <>


            <div className="ParentRapper">
                <TopBar pageName={window.localStorage.getItem("adminType")} />

                <div className="pageDetails">
                    <DoctorSideBar />
                    <div className="page">

                        <TopNavBar />

                        <div className="doctorCalenderScheduleViewer">
                            {
                                Event &&
                                <MyCalendar eventsData={Event} />
                            }

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DoctorProfile