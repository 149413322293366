import React from 'react'
import { ReactComponent as LoaderIMG } from "../../Assets/SVG/loader.svg"
const Loader = () => {
    return (
        <div className='blankAlertCom'>
            <LoaderIMG fill='var(--dashboard-card-border)' />
        </div>
    )
}

export default Loader