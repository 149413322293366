import React from 'react'
import { ReactComponent as AddMoreImg } from "../../Assets/SVG/patientSVG/AddMore.svg"
import CrossImg from "../../Assets/SVG/patientSVG/CrossCircle.svg"
import "../../CSS/PatientForm/MedicalProblems.css"
const Diagnostics = ({ Others, PatientData }) => {

    const AddAnItem = (e) => {
        let prevMedicalSelector = document.getElementsByClassName("prevMedicalSelector")[0]
        let ParrentDiv = document.createElement("div")
        let InputHide = document.createElement("img")
        InputHide.src = CrossImg
        InputHide.addEventListener('click', (c) => { hideTag(c) });
        let MedicalproblemExtra = document.createElement("input")
        MedicalproblemExtra.id = "extraInputCreate"
        ParrentDiv.append(MedicalproblemExtra, InputHide)
        prevMedicalSelector.insertBefore(ParrentDiv, e.target.parentNode);

        MedicalproblemExtra.addEventListener("keyup", (key) => {
            if (key.key === "Enter") {
                let CDiv = document.createElement("div")
                let CSpan = document.createElement("span")
                let CInput = document.createElement("input")
                let CImg = document.createElement("img")

                let InputFieldName = document.getElementById("extraInputCreate").value
                CImg.src = CrossImg
                CInput.type = "checkbox"
                CInput.name = InputFieldName
                CInput.checkValidity = InputFieldName ? true : false;
                CInput.value = InputFieldName
                CSpan.innerHTML = InputFieldName
                CDiv.append(CSpan, CInput, CImg)
                prevMedicalSelector.insertBefore(CDiv, e.target.parentNode.previousSibling);

                CInput.addEventListener("change", (e) => {
                    PatientData.SetFormData({ ...PatientData.FormData, diagnostics: { ...PatientData.FormData.diagnostics, [e.target.name]: e.target.checked } })
                })
                CImg.addEventListener('click', (e) => {
                    hideTag(e);
                });
                ParrentDiv.parentNode.removeChild(ParrentDiv)
            }
        })
    }
    const hideTag = (e) => {
        PatientData.SetFormData({ ...PatientData.FormData, diagnostics: { ...PatientData.FormData.diagnostics, [e.target.name]: "" } })
        e.target.parentNode.parentNode.removeChild(e.target.parentNode)
    }



    const AddNote = (e) => {
        let Notename = prompt("Enter Field Name")
        console.log(Notename)
        if (Notename !== "" && Notename !== null) {
            const NoteAdd = document.createElement("textarea")
            NoteAdd.name = Notename
            NoteAdd.addEventListener("input", (e) => {
                PatientData.SetFormData({ ...PatientData.FormData, diagnostics: { ...PatientData.FormData.diagnostics, [e.target.name]: e.target.value } })
            })
            e.target.parentNode.parentNode.parentNode.children[0].append(NoteAdd)

        } else {
            return false;
        }

    }
    return (
        <>
            <div className="medicalProblemsSection">
                <div className="formHeading">
                    {Others}
                </div>
                <form onSubmit={(e) => e.preventDefault()} className="medicalProblemsForm">

                    <div className="flex flex-wrap align-center g-1">
                        <label htmlFor="MedicalProblemSelector">Suggest Lab Report</label>
                        <div className="prevMedicalSelector">
                            <div>
                                <span>Diabetes</span>
                                <input type="checkbox" name='diabetes' value={"Diabetes"} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, diagnostics: { ...PatientData.FormData.diagnostics, [e.target.name]: e.target.checked } })} checked={PatientData.FormData.diagnostics.highBp} />
                                <img src={CrossImg} alt="" onClick={(e) => hideTag(e)} />
                            </div>
                            <div>
                                <span>Thairoid</span>
                                <input type="checkbox" name='thairoid' value={"Thairoid"} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, diagnostics: { ...PatientData.FormData.diagnostics, [e.target.name]: e.target.checked } })} checked={PatientData.FormData.diagnostics.thairoid} />
                                <img src={CrossImg} alt="" onClick={(e) => hideTag(e)} />
                            </div>
                            <div>
                                <span>ECG</span>
                                <input type="checkbox" name='ecg' value={"ECG"} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, diagnostics: { ...PatientData.FormData.diagnostics, [e.target.name]: e.target.checked } })} checked={PatientData.FormData.diagnostics.ecg} />
                                <img src={CrossImg} alt="" onClick={(e) => hideTag(e)} />
                            </div>
                            <button type='button' className='addMoreBtn' onClick={(e) => AddAnItem(e)}>
                                <AddMoreImg className='addBtnSvg' />
                                <span>AddMore</span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                    <div className="medicalproblemsNotes">
                        <div className="notesInput">
                            <textarea name="allergieNotes" id="allergieNotesTextarea" cols="30" rows="10" value={PatientData.FormData.diagnostics.allergieNotes} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, diagnostics: { ...PatientData.FormData.diagnostics, [e.target.name]: e.target.value } })}></textarea>
                        </div>
                        <div className="addExtranoteBtn">

                            <button type='button' className='addMoreBtn' onClick={(e) => AddNote(e)}>
                                <AddMoreImg className='addBtnSvg' />
                                <span>AddMore</span>
                                <span></span>
                            </button>






                        </div>
                    </div>



















                </form >

            </div >



        </>
    )
}

export default Diagnostics