import React from 'react'

function DoctorPA({ FormData }) {
    return (
        <>

            <>
                <div className="flex-col g-05">
                    <label htmlFor="phone">
                        Doctor's Email
                    </label>
                    <input type="email" name='doctorEmail' placeholder='Which doctor PA' value={FormData.FormData.doctorEmail} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value,hospitalId: localStorage.getItem("hospitalId") })} required />
                </div>
                <div className="flex-col g-05">
                    <label htmlFor="email">
                        Enter Doctor PA Email
                    </label>
                    <input type="email" name='email' placeholder='Enter doctor PA email' value={FormData.FormData.email} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
                </div>

                <div className="flex-col g-05">
                    <label htmlFor="name">
                        Doctor PA Name
                    </label>
                    <input type="text" name='name' placeholder='Enter doctor PA name' value={FormData.FormData.name} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
                </div>
                <div className="flex-col g-05">
                    <label htmlFor="phone">
                        Doctor PA Phone No
                    </label>
                    <input type="number" name='phone' placeholder='Enter doctor PA phone no' value={FormData.FormData.phone} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
                </div>



                <div className="flex-col g-05">
                    <label htmlFor="speciality">
                        Doctor PA Profile Password
                    </label>
                    <input type="password" name='password' placeholder='Enter doctor PA profile Password' value={FormData.FormData.password} onChange={(e) => FormData.SetFormData({ ...FormData.FormData, [e.target.name]: e.target.value })} required />
                </div>

            </>


        </>
    )
}

export default DoctorPA