import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const PatientDirectLogin = () => {
    const navigate = useNavigate()
    const [PatientLoginData, SetPatientLoginData] = useState({
        uhid: "",
        phone: ""
    })
    const PatientLogin = (e) => {
        e.preventDefault();


        // send a request for get patient data
        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/get", JSON.parse(JSON.stringify(PatientLoginData)), {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            if (res.status === 200) {
                navigate("/user/patient/profile", { state: res.data[0] });
            }
        }).catch((err) => {
            toast.error(err.response.data)
        })

    }

    const LoginValidate = (e) => {
        SetPatientLoginData({ ...PatientLoginData, [e.target.name]: e.target.value })
    }
    return (
        <>
            <ToastContainer />

            <form className="congratulationForm" onSubmit={(e) => PatientLogin(e)}>
                <h3 className="formHeading">Search Patient</h3>
                <div className="patientLoginUserInput PatientUIDPatientLogin">
                    <label htmlFor="PatientId">Patient UHID</label>
                    <input type="text" name='uhid' placeholder='Patient Id' onChange={(e) => LoginValidate(e)} value={PatientLoginData.uhid} id='UID' />
                </div>
                <div className="otherOptionPreviewer">
                    <span></span>
                    <span>OR</span>
                    <span></span>
                </div>
                <div className="patientLoginUserInput">
                    <label htmlFor="Number">Phone Number</label>
                    <input type="number" name='phone' onInput={(e) => LoginValidate(e)} value={PatientLoginData.phone} placeholder='Patient Phone No' />
                </div>
                <button className='patientLoginBtn' type='submit'>
                    Search
                </button>

            </form>

        </>
    )
}

export default PatientDirectLogin