import { React } from 'react'

import Paid from "../../../Assets/SVG/Billing/Paid.svg"
import Partial from "../../../Assets/SVG/Billing/Partial.svg"
import NotPaid from "../../../Assets/SVG/Billing/YetToPay.svg"
const BillingStatus = ({ amount, paid }) => {
    return (
        <>
            {
                Number(amount) - Number(paid) === Number(amount) ?

                    <td className='billingStatusViewer' style={{ "--billingStatusBackColorVari": "#FFEAEA", "--billingStatusColorVari": "#B83131" }} >
                        <img src={NotPaid} alt="" />
                        <span> Yet To Paid</span>
                    </td>
                    : ""
            }
            {
                Number(amount) === Number(paid) ?

                    <td className='billingStatusViewer' style={{ "--billingStatusBackColorVari": "#EDFFEA", "--billingStatusColorVari": "#165E3D" }} >
                        <img src={Paid} alt="" />
                        <span>Paid</span>
                    </td>
                    : ""
            }
            {
                Number(amount) - Number(paid) > 0 && Number(amount) - Number(paid) < Number(amount) ?

                    <td className='billingStatusViewer' style={{ "--billingStatusBackColorVari": "#FFF6E9", "--billingStatusColorVari": "#B5850B" }} >
                        <img src={Partial} alt="" />
                        <span>Partial </span>
                    </td>
                    : ""
            }

        </>
    )
}

export default BillingStatus