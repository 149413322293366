/* eslint-disable no-unused-vars */
import { React, useEffect, useState } from 'react'

import List from "../../../Assets/SVG/Doctor/ListI.svg"
import "../../../CSS/Profiles/Nurse/PatientList.css"
import Person from "../../../Assets/SVG/Doctor/User.svg"
import Clock from "../../../Assets/SVG/Doctor/Timer.svg"
import Action from "../../../Assets/SVG/Doctor/Action.svg"
import { Link } from 'react-router-dom'
import axios from 'axios'
const PatientList = ({ FilterDate }) => {
    const [InPatients, SetInpatients] = useState(null)
    const [FilterInPatients, SetFilterInpatients] = useState(null)
    useEffect(() => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/appointment/inpatient/get", { hospitalId: localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetInpatients(res.data)
            SetFilterInpatients(res.data)
        }).catch((err) => {
            console.log(err.response.data)
        })
    }, [])

    useEffect(() => {
        const Filter = InPatients && InPatients.filter((data) => {
            return new Date(data.date)

        })
        // eslint-disable-next-line
    }, [FilterDate])



    return (
        <>
            <div className="consultAdmitPatientList">
                <table className="docConSultedPatientList">
                    <thead>
                        <tr>
                            <th>
                                <img src={List} alt="" />
                                <span>Token No</span>
                            </th>
                            <th>
                                <img src={Person} alt="" />
                                <span>Name</span>
                            </th>
                            <th>
                                <img src={Clock} alt="" />
                                <span>Visiting Time</span>
                            </th>
                            <th>
                                <img src={Action} alt="" />
                                <span>Action</span>
                            </th>
                        </tr>
                    </thead>
                    <div className="patientConsultedListBody">


                        <tbody>



                            {
                                InPatients && InPatients.map((data, count) => {
                                    return (
                                        <tr>
                                            <td>{count + 1}</td>
                                            <td>
                                                {
                                                    data.patientInfo.patientFirstName + " " + data.patientInfo.patientLastName
                                                }
                                            </td>
                                            <td>{data.time}</td>
                                            <td className='flex-col justify-between g-05'>
                                                <Link to={"/user/patientlist/patientForm"}
                                                    //here id is require . when you send request on this url with out any id this component though a error(so send a Patient id in this format {id: data._id,patientId:data.patientInfo._id})

                                                    state={{ id: data._id, patientId: data.patientInfo._id }}
                                                    className='patientListActionBtn' value={"details"}>Add Details</Link>
                                                <button className='patientListActionBtn' value={"uploadPres"}>Upload Pres.</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }



                        </tbody>
                    </div>
                </table>
            </div>

        </>
    )
}

export default PatientList