/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import "../../CSS/EMR/AllEMR.css"
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import FilterImg from '../../Assets/SVG/EMR/Filter.svg'
import EMRProfileCard from '../EMR/EMRProfileCard'
import PatientBillingComp from '../Patient/Billing/PatientBillingComp'
import axios from 'axios'
const AllBilling = () => {

    const [PatientProfile, SetPatientProfile] = useState(null)
    const [PatientDetails, SetPatientDetails] = useState(null)
    const [searchQuery, setSearchQuary] = useState('')



    const getPatients = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/patients/getall", { "hospitalId": localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetPatientProfile(res.data)
        }).catch((err) => {
            console.log(err.response.data)
        })
    }


    useEffect(() => {
        getPatients()
    }, [])


    const patientFilter = PatientProfile ? PatientProfile.filter(patient => {
        const query = searchQuery.toLowerCase()
        return (
            patient.patientFirstName.toLowerCase().includes(query) ||
            patient.patientLastName.toLowerCase().includes(query) ||
            patient.uhid.toLowerCase().includes(query)
        )
    }) : []


    return (
        <>


            <div className="ParentRapper">
                <TopBar pageName="Billing" />

                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        <section className="allEmr flex-col g-1 pb-1">
                            <div className="pageTitle">
                                Dashboard / patient-billing /
                            </div>
                            <div className="flexRow h-100 g-2 overflow-hide">
                                <div className="allEmrProfileSection">
                                    <div className="flex-col g-1">
                                        <div className="allEmrProfileSectionHeader flexRow justify-between ">
                                            <h3 style={{ "color": "#2C3857" }}>Patients</h3>
                                            <select name="dateShorter" id="dateShorter">
                                                <option value="">Search Option</option>
                                                <option value="Name">Patient Name</option>
                                                <option value="UHID">Patient UHID</option>
                                            </select>
                                        </div>
                                        <div className='searchPatientEmrInput'>
                                            <input type="text" placeholder='Search...' value={searchQuery} onChange={(e) => setSearchQuary(e.target.value)} />
                                            <img src={FilterImg} alt="" />
                                        </div>
                                    </div>
                                    <div className="flex-1 scrollAbleDiv emrProfileCards">


                                        <div className="flex-col g-1">
                                            {patientFilter.map((data) => {
                                                return (
                                                    <EMRProfileCard PatientDetails={{ data, SetPatientDetails }} key={data._id} />
                                                )
                                            })}
                                        </div>
                                    </div>




                                </div>
                                <div className="allEmrSection flex-1">

                                    <PatientBillingComp id={PatientDetails} />



                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>



        </>

    )
}

export default AllBilling