import { React } from 'react'
import "../../CSS/Navbar/Navbar.css"
import { NavLink } from 'react-router-dom'
import { ReactComponent as DashboardImg } from "../../Assets/SVG/SideBarIcons/Dashboard.svg"
import { ReactComponent as SettingImg } from "../../Assets/SVG/SideBarIcons/Setting.svg"
import { ReactComponent as ProfileImg } from "../../Assets/SVG/SideBarIcons/Profile.svg"
import { ReactComponent as LogoutImg } from "../../Assets/SVG/SideBarIcons/Logout.svg"
import { ReactComponent as MedicalPage } from "../../Assets/SVG/SideBarIcons/MedicalBed.svg"
import { ReactComponent as EMRImg } from "../../Assets/SVG/SideBar/EMRSvg.svg"
import { ReactComponent as AppointmentsImg } from "../../Assets/SVG/SideBar/Calender.svg"
import { ReactComponent as BillingImg } from "../../Assets/SVG/SideBar/Walet.svg"
import { ReactComponent as Diagnostics } from "../../Assets/SVG/SideBar/Medical.svg"



const SideBar = () => {
    return (
        <>
            <div className="navBody">
                <div className="NavItems">
                    <div className="items">
                        <div className="navLinkList">
                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "hospitalAdmin".toLocaleLowerCase() || window.localStorage.getItem("adminType").toLocaleLowerCase() === "receptionist" ?
                                    <NavLink to="/user/dashboard" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        <DashboardImg />
                                    </NavLink> : ""
                            }

                            {/* =====================--------Doctor DashBoard--------==================================== */}

                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "doctor".toLocaleLowerCase() ?
                                    <NavLink to="/user/doctor/calender" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        <DashboardImg />
                                    </NavLink> : ""
                            }

                            {/* =====================------------Nurse DashBoard--------==================================== */}

                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "nurse".toLocaleLowerCase() ?
                                    <NavLink to="/user/nurse/inpatientList" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        <DashboardImg />
                                    </NavLink> : ""
                            }

                            {/* =====================------------Pa DashBoard--------==================================== */}

                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "pa".toLocaleLowerCase() ?
                                    <NavLink to="/user/appointmentlist" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        <DashboardImg />
                                    </NavLink> : ""
                            }

                            {/* =====================------------Diagnostics DashBoard--------==================================== */}

                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "Diagnostics".toLocaleLowerCase() ?
                                    <NavLink to="/user/lab/diagnostics" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        <DashboardImg />
                                    </NavLink> : ""
                            }

                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "hospitalAdmin".toLocaleLowerCase() || window.localStorage.getItem("adminType").toLocaleLowerCase() === "receptionist" ?
                                    <NavLink to="/user/patient" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        <MedicalPage />
                                    </NavLink> : ""
                            }



                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "hospitalAdmin".toLocaleLowerCase() || window.localStorage.getItem("adminType").toLocaleLowerCase() === "receptionist".toLocaleLowerCase() ?
                                    <NavLink to="/user/diagnostics/all" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        <Diagnostics />
                                    </NavLink>
                                    : ""
                            }

                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "hospitalAdmin".toLocaleLowerCase() || window.localStorage.getItem("adminType").toLocaleLowerCase() === "receptionist".toLocaleLowerCase() ?
                                    <NavLink to="/user/appointments" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        < AppointmentsImg />
                                    </NavLink>
                                    : ""
                            }







                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "hospitalAdmin".toLocaleLowerCase() || window.localStorage.getItem("adminType").toLocaleLowerCase() === "receptionist".toLocaleLowerCase() ?
                                    <NavLink to="/user/billing/all" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        < BillingImg />
                                    </NavLink>
                                    : ""}



                            {window.localStorage.getItem("adminType").toLocaleLowerCase() !== "hospitalAdmin".toLocaleLowerCase() ?
                                <NavLink to="/user/profile/details" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                    < ProfileImg />
                                </NavLink>
                                : ""}


                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "hospitalAdmin".toLocaleLowerCase() || window.localStorage.getItem("adminType").toLocaleLowerCase() === "receptionist".toLocaleLowerCase() ?
                                    <NavLink to="/user/emr/all" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        < EMRImg className='emricon' />
                                    </NavLink>
                                    : ""}


                            {
                                window.localStorage.getItem("adminType").toLocaleLowerCase() === "hospitalAdmin".toLocaleLowerCase() ?
                                    <NavLink to="/user/settings/adminprofile" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                        < SettingImg />
                                    </NavLink>
                                    : ""
                            }
                            <NavLink to="/user/logout" className={({ isActive }) => (isActive ? 'active' : 'inactive')} id='logout'>
                                < LogoutImg />
                            </NavLink>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideBar