/* eslint-disable no-unused-vars */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as SettingImg } from "../../Assets/SVG/SideBarIcons/Setting.svg"
import { ReactComponent as ProfileImg } from "../../Assets/SVG/SideBarIcons/Profile.svg"
import { ReactComponent as LogoutImg } from "../../Assets/SVG/SideBarIcons/Logout.svg"
import { ReactComponent as DashboardImg } from "../../Assets/SVG/SideBarIcons/Dashboard.svg"

const DoctorSideBar = () => {
    let path;
    const adnimType = localStorage.getItem('adminType').toLocaleLowerCase()
    switch (adnimType) {
        case "doctor":
            path = "/user/doctor/calender"
            break;
        case "nurse":
            path = "/user/nurse/inpatientList"
            break;
        case "pa":
            path = "/user/appointmentlist"
            break;

        default:
            break;
    }
    return (
        <>
            <div className="navBody">
                <div className="NavItems">
                    <div className="items">
                        <div className="navLinkList">


                            <NavLink to={path} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                < DashboardImg />
                            </NavLink>

                            <NavLink to="/user/profile/details" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                < ProfileImg />
                            </NavLink>

                            <NavLink to="/user/logout" id='logoutBtn' className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                                < LogoutImg />
                            </NavLink>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DoctorSideBar