import React, { useState } from 'react'
import { Modal, useMantineTheme } from "@mantine/core";
import '../../CSS/modals/ViewDiagnostics.css'
import '../../CSS/modals/editPatient.css'
import axios from 'axios';

const EditPatient = ({ openModel, setOpenModel, patientInfo, setPatientInfo }) => {

    const BaseUrl = process.env.REACT_APP_BACKEND_URL

    const theme = useMantineTheme();
    const [errorMsg, setErrorMsg] = useState({ status: false, message: "" })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPatientInfo({
            ...patientInfo,
            [name]: value
        });
        setErrorMsg({ status: false, message: "" })
    }

    const funcEditPatient = () => {
        axios.put(`${BaseUrl}/patients/edit`, patientInfo, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then(res => {
            if (res.data.code === 202) {
                setErrorMsg({ status: true, message: res.data.message });
            } else {
                setOpenModel(false)
            }
        }).catch(err => {
            console.log(err);
        })
    }
    return (

        <div>
            <Modal
                overlayProps={{
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                    opacity: 0.55,
                    blur: 4,
                }}
                size="50%"
                opened={openModel}
                onClose={() => setOpenModel(false)}
            >
                <div className='ParentModalWrapper'>
                    <div className='heading'>
                        <h1>Edit Patient info</h1>
                    </div>
                    <div>
                        <div className='errorMsg'>
                            {errorMsg.status && <p>{errorMsg.message}</p>}
                        </div>
                        <div className='InputFieldContainer'>
                            <label htmlFor="first name">First Name</label>
                            <input type="text" placeholder="First Name" name="patientFirstName" className="InfoInput" autocomplete="off" value={patientInfo.patientFirstName} onChange={handleChange} />
                        </div>
                        <div className='InputFieldContainer'>
                            <label htmlFor="first name">Last Name</label>
                            <input type="text" placeholder="Last Name" name="patientLastName" className="InfoInput" autocomplete="off" value={patientInfo.patientLastName} onChange={handleChange} />
                        </div>
                        <div className='InputFieldContainer'>
                            <label htmlFor="first name">Phone Number</label>
                            <input type="number" placeholder="Phone Number" name="phoneno" className="InfoInput" autocomplete="off" value={patientInfo.phoneno} onChange={handleChange} />
                        </div>
                        <div className='InputFieldContainer'>
                            <label htmlFor="first name">Email Id</label>
                            <input type="email" placeholder="Email Id" name="patientEmail" className="InfoInput" autocomplete="off" value={patientInfo.patientEmail} onChange={handleChange} />
                        </div>
                        <div className='InputFieldContainer'>
                            <label htmlFor="first name">Age</label>
                            <input type="number" placeholder="Age" name="patientAge" className="InfoInput" autocomplete="off" value={patientInfo.patientAge} onChange={handleChange} />
                        </div>
                        <div className='btnContainer'>
                            <button onClick={() => { funcEditPatient() }}>Update</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default EditPatient
