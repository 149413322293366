/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import TopBar from '../../Bars/TopBar'
import SideBar from '../../Bars/SideBar'
import "../../../CSS/Profiles/SystemAdmin/SystemAdmin.css"
import Logout from "../../../Assets/SVG/Settings/Logout.svg"
// import { ReactComponent as EditImg } from "../../../Assets/SVG/Settings/Edit.svg"
import { Link } from 'react-router-dom'
import axios from 'axios'
import Loader from '../../Loader/Loader'
const Profile = () => {
    const [AdminData, SetAdminData] = useState(null)
    const [Path, SetPath] = useState(null)
    useEffect(() => {
        switch (localStorage.getItem("adminType").toLowerCase()) {
            case "receptionist":
                SetPath("/receptionist/profile/get");
                break;
            case "nurse":
                SetPath("/nurse/profile/get");
                break;
            case "pa":
                SetPath("/pa/profile/get");
                break;
            case "doctor":
                SetPath("/doctor/profile/get");
                break;
            case "diagnostics":
                SetPath("/diagnostics/profile/get");
                break;
            default:
                SetPath(null);
                break;
        }
    }, [])

    console.log(AdminData);

    useEffect(() => {
        Path && axios.post(process.env.REACT_APP_BACKEND_URL + Path, { id: window.localStorage.getItem("Id") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetAdminData(res.data)
        }).catch((err) => {
            console.log(err.response.data)
        })
    }, [Path])



    return (
        <>

            <div className="ParentRapper">
                <TopBar pageName="Profile" />
                <div className="pageDetails">
                    <SideBar />
                    <div className="page">
                        {
                            AdminData &&
                            <div className="adminProfileSetting">
                                <div className="flexCol">
                                    <div className="profilePreview">
                                        <div className="profilePicAndIdName">
                                            <div className="circuler w-5r h-5r flex align-center justify-center " style={{ border: "2px solid #222070", padding: "3px" }}>
                                                <span className='circuler w-100 h-100 flex align-center justify-center -white ' style={{ background: "#222070", color: 'white' }}>
                                                    {
                                                        AdminData && AdminData.name?AdminData.name.slice(0, 2).toUpperCase():"DI"
                                                    }
                                                </span>
                                            </div>
                                            <div className='flex-col'>
                                                {
                                                    localStorage.getItem("adminType") === "Diagnostics" ?
                                                        <h2 value={localStorage.getItem("adminType").toUpperCase()}>Diagnostics Profile</h2>
                                                        :
                                                        <h2 value={localStorage.getItem('adminType').toUpperCase()}>{AdminData.name}</h2>
                                                }


                                                <span>ID: {AdminData._id.slice(-5)}</span>
                                            </div>
                                        </div>
                                        <div className="logoutBtn">
                                            <Link to={"/user/logout"}>
                                                <img src={Logout} alt="" />
                                                <span>Logout</span>
                                            </Link>
                                        </div>
                                    </div>



                                    <div className="adminBasicDetails g-1 flex-col">
                                        <div className="flexRow justify-between">
                                            <h3>
                                                Personal Information
                                            </h3>
                                            {/* <button className="editbtn" disabled>
                                                <span>Edit</span>
                                                <EditImg stroke='#292D32' />
                                            </button> */}
                                        </div>

                                        <div className="grid">
                                            {
                                                localStorage.getItem("adminType") !== "Diagnostics" ?
                                                    <>


                                                        <div className="flex-col g-05">
                                                            <label htmlFor="" className='adminDetailsFormLabel'>
                                                                Title
                                                            </label>
                                                            <span>
                                                                Mr.
                                                            </span>
                                                        </div>
                                                        <div className="flex-col g-05">
                                                            <label htmlFor="" className='adminDetailsFormLabel'>
                                                                Name
                                                            </label>
                                                            <span>
                                                                {AdminData.name}
                                                            </span>
                                                        </div>
                                                    </> : ""
                                            }
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    Phone No.
                                                </label>
                                                <span>
                                                    {AdminData.phone}
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    Email Address
                                                </label>
                                                <span>
                                                    {AdminData.email != "" || AdminData.email != undefined || AdminData.email != null ? AdminData.email : "N/A"}

                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    Date of Birth                                            </label>
                                                <span>
                                                    {/* {AdminData.dob} */}
                                                    {AdminData.DOB == null || AdminData.DOB == undefined ? "NA" : AdminData.DOB}
                                                </span>
                                            </div>

                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    Licence</label>
                                                <span>
                                                    {
                                                        AdminData.licenseNo != null ? AdminData.licenseNo : "N/A"
                                                    }
                                                </span>
                                            </div>
                                            <div className="flex-col g-05">
                                                <label htmlFor="" className='adminDetailsFormLabel'>
                                                    Specialization</label>
                                                <span>
                                                    {
                                                        AdminData.specialization != null ? AdminData.specialization : "N/A"
                                                    }
                                                </span>
                                            </div>
                                            {
                                                localStorage.getItem("adminType") === "Pa" ?
                                                    <>
                                                        <div className="flex-col g-05">
                                                            <label htmlFor="" className='adminDetailsFormLabel'>
                                                                Doctor Email</label>
                                                            <span>
                                                                {
                                                                    AdminData.doctorEmail != null ? AdminData.doctorEmail : "N/A"
                                                                }
                                                            </span>
                                                        </div>
                                                    </>
                                                    : ""
                                            }

                                        </div>


                                    </div>

                                </div>
                            </div>


                        }
                        {
                            AdminData === null ? <Loader /> : ""
                        }
                    </div>
                </div>
            </div>



        </>
    )
}

export default Profile