import { React, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import TopBar from '../Bars/TopBar'
import SideBar from '../Bars/SideBar'
import Calendar from 'react-calendar'
import axios from 'axios'
import backBtn from '../../Assets/SVG/back.svg'
import { ToastContainer, toast } from 'react-toastify'


const DiagnosticsAppointment = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [Appointment, SetAppointment] = useState({
        date: "",
        time: "",
        hospitalId: localStorage.getItem("hospitalId"),
        patientId: location.state.BookDiagnostics.patientId,
        diagnosticsInfo: location.state.BookDiagnostics.diagnosticsInfo
    })
    const ChangeData = (e) => {
        const originalDate = new Date(e);
        const year = originalDate.getFullYear();

        const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(originalDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        SetAppointment({ ...Appointment, date: formattedDate })

    }
    const setTimeout = (e) => {
        SetAppointment({ ...Appointment, time: e.target.value })

    }
    const RequestAppointment = (e) => {
        e.preventDefault()
        axios.post(process.env.REACT_APP_BACKEND_URL + "/diagnostics/book", Appointment, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            e.target.reset();
            toast.success("Diagnostics Booking Successfully");
            window.setTimeout(() => {
                navigate('/user/diagnostics/all');
            }, 3000);
        }).catch((err) => {
            const errorMessage = err.response?.data || "Error booking diagnostics";
            toast.error(errorMessage);
            console.log(err);
        });
    }
    return (
        <>
            <ToastContainer />
            <div className="ParentRapper">
                <TopBar pageName="Dashboard" />
                <div className="pageDetails">
                    <SideBar />
                    <div className="page">


                        <div className="appointBooingFormSection">
                            <div className="appointBooingFormHeader">
                                <h3 className="pageTitle">Schedule Appointment</h3>
                                <div className="pagePath">
                                    <img src={backBtn} alt="" onClick={() => window.history.back()} />
                                    Dashboard / patient-Profile /appointment
                                </div>
                            </div>

                            <form onSubmit={(e) => RequestAppointment(e)} className="appointmentBookingForm">
                                <div className="flex-col">
                                    <h3>Select Your Diagnostics Date And Time</h3>
                                </div>
                                <div className="appointBookingMentDateAndTime">
                                    <div className="appointmentBookingDate">
                                        <p>Choose Date</p>
                                        <Calendar onChange={(e) => ChangeData(e)}  minDate={new Date()}/>
                                    </div>

                                    <div className="appointmentBookingTime">
                                        <div className="appointmentBookingTimeInputSection">

                                            <input type="radio" name="time" value={"9:30 - 10:30"} onChange={(e) => setTimeout(e)} className='appointmentBookingTimeInput' id="" required />
                                            <input type="radio" name="time" value={"10:30 - 11:30"} onChange={(e) => setTimeout(e)} className='appointmentBookingTimeInput' id="" required />
                                            <input type="radio" name="time" value={"11:30 - 12:30"} onChange={(e) => setTimeout(e)} className='appointmentBookingTimeInput' id="" required />
                                            <input type="radio" name="time" value={"12:30 - 13:30"} onChange={(e) => setTimeout(e)} className='appointmentBookingTimeInput' id="" required />
                                            <input type="radio" name="time" value={"13:30 - 14:30"} onChange={(e) => setTimeout(e)} className='appointmentBookingTimeInput' id="" required />
                                        </div>
                                        <button type='submit' className='appointmentBookingRequestBtn'>
                                            Request
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DiagnosticsAppointment