import "../../CSS/PatientForm/MedicalProblems.css"
import { ReactComponent as AddMoreImg } from "../../Assets/SVG/patientSVG/AddMore.svg"
import CrossImg from "../../Assets/SVG/patientSVG/CrossCircle.svg"

const MedicalProblems = ({ Others, PatientData }) => {


    const AddAnItem = (e) => {
        let prevMedicalSelector = document.getElementsByClassName("prevMedicalSelector")[0]
        let ParrentDiv = document.createElement("div")
        let InputHide = document.createElement("img")
        InputHide.src = CrossImg
        InputHide.addEventListener('click', (c) => { hideTag(c) });
        let MedicalproblemExtra = document.createElement("input")
        MedicalproblemExtra.id = "extraInputCreate"
        ParrentDiv.append(MedicalproblemExtra, InputHide)
        prevMedicalSelector.insertBefore(ParrentDiv, e.target.parentNode);

        MedicalproblemExtra.addEventListener("keyup", (key) => {
            if (key.key === "Enter") {
                let CDiv = document.createElement("div")
                let CSpan = document.createElement("span")
                let CInput = document.createElement("input")
                let CImg = document.createElement("img")

                let InputFieldName = document.getElementById("extraInputCreate").value
                CImg.src = CrossImg
                CInput.type = "checkbox"
                CInput.name = InputFieldName
                CInput.checkValidity = InputFieldName ? true : false;
                CInput.value = InputFieldName
                CSpan.innerHTML = InputFieldName
                CDiv.append(CSpan, CInput, CImg)
                prevMedicalSelector.insertBefore(CDiv, e.target.parentNode.previousSibling);

                CInput.addEventListener("change", (e) => {
                    PatientData.SetFormData({ ...PatientData.FormData, medicalProblem: { ...PatientData.FormData.medicalProblem, [e.target.name]: e.target.checked } })
                })
                CImg.addEventListener('click', (e) => {
                    hideTag(e);
                });
                ParrentDiv.parentNode.removeChild(ParrentDiv)
            }
        })
    }
    // hide medical problem tags
    const hideTag = (e) => {
        PatientData.SetFormData({ ...PatientData.FormData, medicalProblem: { ...PatientData.FormData.medicalProblem, [e.target.name]: "" } })

        e.target.parentNode.parentNode.removeChild(e.target.parentNode)
    }

    // add new textarea
    const AddNote = (e) => {
        let Notename = prompt("Enter Field Name")
        if (Notename !== "" && Notename !== null) {
            const NoteAdd = document.createElement("textarea")
            NoteAdd.name = Notename
            NoteAdd.addEventListener("input", (e) => {
                PatientData.SetFormData({ ...PatientData.FormData, medicalProblem: { ...PatientData.FormData.medicalProblem, [e.target.name]: e.target.value } })
            })
            e.target.parentNode.parentNode.parentNode.children[0].append(NoteAdd)

        } else {
            return false;
        }

    }
    
    return (
        <>
            <div className="flexRow g-2 overflow-hidden h-100">

                <div className="medicalProblemsSection">
                    <div className="formHeading">
                        {Others}
                    </div>
                    <form onSubmit={(e) => e.preventDefault()} className="medicalProblemsForm">
                        <div className="flexCol">
                            <label htmlFor="MedicalProblemSelector">Select Past Medical Problems</label>
                            <div className="prevMedicalSelector" id="test">
                                <div>
                                    <span>High Pressure</span>
                                    <input type="checkbox" name='highBp' value={"High Pressure"} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, medicalProblem: { ...PatientData.FormData.medicalProblem, [e.target.name]: e.target.checked } })} checked={PatientData.FormData.medicalProblem.highBp} />
                                    <img src={CrossImg} alt="" onClick={(e) => hideTag(e)} />
                                </div>
                                <div>
                                    <span>Hyperlipidemia</span>
                                    <input type="checkbox" name='hyperlipidemia' value={"Hyperlipidemia"} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, medicalProblem: { ...PatientData.FormData.medicalProblem, [e.target.name]: e.target.checked } })} checked={PatientData.FormData.medicalProblem.hyperlipidemia} />
                                    <img src={CrossImg} alt="" onClick={(e) => hideTag(e)} />
                                </div>
                                <div>
                                    <span>Diarrhea</span>
                                    <input type="checkbox" name='diarrhea' value={"Diarrhea"} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, medicalProblem: { ...PatientData.FormData.medicalProblem, [e.target.name]: e.target.checked } })} checked={PatientData.FormData.medicalProblem.diarrhea} />
                                    <img src={CrossImg} alt="" onClick={(e) => hideTag(e)} />
                                </div>





                                <button type='button' className='addMoreBtn' onClick={(e) => AddAnItem(e)}>
                                    <AddMoreImg className='addBtnSvg' />
                                    <span>AddMore</span>
                                    <span></span>
                                </button>
                            </div>


                            <div className="medicalproblemsNotes">
                                <div className="notesInput">
                                    <textarea name="medicalProblemNotesTextarea" id="notesTextarea" cols="30" rows="10" value={PatientData.FormData.medicalProblem.medicalProblemNotesTextarea} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, medicalProblem: { ...PatientData.FormData.medicalProblem, [e.target.name]: e.target.value } })}></textarea>
                                </div>
                                <div className="addExtranoteBtn">
                                    <button type='button' className='addMoreBtn' onClick={(e) => AddNote(e)}>
                                        <AddMoreImg className='addBtnSvg' />
                                        <span>AddMore</span>
                                        <span></span>

                                    </button>

                                </div>
                            </div>

                            <div className="allergies">
                                <label htmlFor="allergies">Do patient have any allergies</label>
                                <div className="allergiesCheckBtn">
                                    <input type="radio" name="allergie" value="Yes" onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, medicalProblem: { ...PatientData.FormData.medicalProblem, [e.target.name]: true } })} checked={PatientData.FormData.medicalProblem.allergie ? true : false} />
                                    <input type="radio" name="allergie" value="No" onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, medicalProblem: { ...PatientData.FormData.medicalProblem, [e.target.name]: false } })} checked={PatientData.FormData.medicalProblem.allergie ? false : true} />

                                </div>
                            </div>

                            {
                                PatientData.FormData.medicalProblem.allergie ?
                                    <div className="medicalproblemsNotes">
                                        <div className="notesInput">
                                            <textarea name="allergieNotes" id="allergieNotesTextarea" cols="30" rows="10" value={PatientData.FormData.medicalProblem.allergieNotes} onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, medicalProblem: { ...PatientData.FormData.medicalProblem, [e.target.name]: e.target.value } })}></textarea>
                                        </div>
                                        <div className="addExtranoteBtn">

                                            <button type='button' className='addMoreBtn' onClick={(e) => AddNote(e)}>

                                                <AddMoreImg className='addBtnSvg' />
                                                <span>AddMore</span>
                                                <span></span>

                                            </button>






                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </form >

                </div >
 {/*---------=========== diagnosis part start from here===========----- */}
                <div className="flex-col h-100 w-100">
                    <h3 className='width-fit'>Diagnosis</h3>

                    <div className="diagnosisBody">

                        <div className="scroll w-100 h-100 pb-2">

                            <div className="diagnosisContents flex-1 w-100 fit-height">
                                <div className="diagnosisTextBoxes w-100">

                                    <textarea name="diagnosis" onChange={(e) => PatientData.SetFormData({ ...PatientData.FormData, [e.target.name]: e.target.value })} value={PatientData.FormData.diagnosis} id="" className='w-100' cols="50" rows="50" placeholder='Enter Diagnosis '></textarea>

                                </div>
                            </div>
                        </div>


                    </div>
                </div >

            </div>
        </>
    )
}

export default MedicalProblems