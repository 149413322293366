import React, { useState, useEffect } from 'react'
import axios from 'axios'
import "../../../CSS/Diagnostics/Lab.css"
import TopBar from '../../Bars/TopBar'
import DoctorSideBar from '../../Bars/DoctorSideBar'
import TopNavBar from '../Doctor/TopNavBar'
import { ReactComponent as SearchIcon } from "../../../Assets/SVG/patientSVG/SearchBtn.svg"
import ExportImg from "../../../Assets/SVG/patientSVG/Export.svg"
import FilterBtn from "../../../Assets/SVG/Diagnostics/Filter.svg"
import ReactModal from 'react-modal'
import Calendar from 'react-calendar'
import PatientListCard from './PatientListCard'
import Loader from '../../Loader/Loader'
import BlankAlert from '../../BlankComp/BlankAlert'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Lab = () => {
    const [ModalOpen, SetModalOpen] = useState(false)
    const [Diagnostcs, SetDiagnostcs] = useState(null)

    useEffect(() => {
        getDiagnostics()
    }, [])
    const getDiagnostics = () => {
        axios.post(process.env.REACT_APP_BACKEND_URL + "/diagnostics/book/get", { hospitalId: localStorage.getItem("hospitalId") }, {
            headers: {
                token: localStorage.getItem("token")
            }
        }).then((res) => {
            SetDiagnostcs(res.data)
        }).catch((err) => {

            console.log(err.response.data)
        })
    }
    return (
        <>
            <ToastContainer />
            <ReactModal isOpen={ModalOpen} >
                <button type="button" onClick={() => SetModalOpen(false)} style={{ position: 'absolute', top: "1rem", right: "2rem", fontSize: "2rem", cursor: "pointer", height: "2rem", width: "2rem", display: "flex", alignItems: "center", justifyContent: "center", background: "transparent", border: "none", outline: "none" }}>&times;</button>
                <div className="flex-col w-100 align-center g-1 ">

                    <label htmlFor="">Choose Date for filter</label>
                    <div className="modalCalender">
                        <Calendar />
                    </div>
                    <button className='applyFilterBtn'>
                        Apply Filter
                    </button>
                </div>
            </ReactModal>
            <div className="ParentRapper">
                <TopBar pageName={localStorage.getItem("adminType")} />

                <div className="pageDetails">
                    <DoctorSideBar />
                    <div className="page">
                        <TopNavBar />

                        <section className="diagnosticsProfileSection flex-col">
                            <div className="flexRow justify-between">
                                <h3>Reports</h3>
                                <div className="flexRow flex-1 justify-end g-1">
                                    <div className="searchSection">
                                        <input type="text" placeholder='Search...' />
                                        <button className='searchSectionBtn'>
                                            <SearchIcon />
                                        </button>
                                    </div>

                                    <button type="button" className="ExportBtn" >
                                        <img src={ExportImg} alt="" />
                                        <span>Export</span>
                                    </button>

                                    <button type="button" className="dateFilterModalBtn" onClick={() => SetModalOpen(true)} >
                                        <span>Filter By Date</span>
                                        <img src={FilterBtn} alt="" />

                                    </button>


                                </div>
                            </div>
                            <div className='flex-1 scrollAbleDiv'>
                                <div className="flex-col g-2">
                                    {
                                        Diagnostcs && Diagnostcs.filter((fdata) => {
                                            return fdata.status !== "pending" && fdata.status !== "Reject"
                                        }).map((data, count) => {
                                            return (
                                                <PatientListCard slno={count + 1} status={data.status} pName={data.patientBasicInfo.patientFirstName + " " + data.patientBasicInfo.patientLastName} pid={data.patientBasicInfo.uhid} Date={data.date} data={data} key={count} toast={toast}/>

                                            )
                                        })
                                    }

                                </div>
                                {Diagnostcs === null ? <Loader /> : ""}
                                {Diagnostcs && Diagnostcs.length === 0 ? <BlankAlert msg={"You are not confirm any appointment"} /> : ""}
                            </div>

                        </section>


                    </div>
                </div>
            </div>


        </>
    )
}

export default Lab