/* eslint-disable no-mixed-operators */
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Components/Pages/Login/Login';
import LoginAuth from './Components/Context/Auth/LoginAuth';
import UserAuth from './Components/Context/Auth/UserAuth';
import Dashboard from './Components/Pages/Dashboard/Dashboard';
import User from './Components/Pages/Authentication/UserDetails/User.jsx';
import Patient from './Components/Pages/Patient/Patient.jsx';
import BasicDetails from './Components/Pages/PatientForm/BasicDetails.jsx';
import PatientLogin from './Components/Pages/Patient/PatientLogin.jsx';
import PatientProfile from './Components/Pages/Profiles/Patient/PatientProfile.jsx';
import AppointmentSelection from './Components/Pages/Appointment/AppointmentSelection.jsx';
import DoctorList from './Components/Pages/Appointment/DoctorList.jsx';
import { AppointmentState } from "./Components/Pages/Context/Appointment.jsx"
import BookAppointment from './Components/Pages/Appointment/BookAppointment.jsx';
import DiagnosticsBooking from './Components/Pages/Appointment/Diagnostics/DiagnosticsBooking.jsx';
import PatientBilling from './Components/Pages/Patient/Billing/PatientBilling.jsx';
import AddBilling from './Components/Pages/Patient/Billing/AddBilling.jsx';
import EMR from './Components/Pages/Patient/EMR/EMR.jsx';
import Doctor from './Components/Pages/Authentication/Doctor/Doctor.jsx';
import DoctorProfile from './Components/Pages/Profiles/Doctor/DoctorProfile.jsx';
import DocProfilePatientList from './Components/Pages/Profiles/Doctor/DocProfilePatientList.jsx';
import SettingOutlet from './Components/Pages/Setting/SettingOutlet.jsx';
import WhiteLabel from './Components/Pages/Profiles/SystemAdmin/WhiteLabel.jsx';
import SystemAdmin from './Components/Pages/Profiles/SystemAdmin/SystemAdmin.jsx';
import AdminAccountCreation from './Components/Pages/Profiles/SystemAdmin/AdminAccountCreation.jsx';
import AccountLists from './Components/Pages/Profiles/SystemAdmin/AccountLists.jsx';
import AppointmentList from './Components/Pages/Profiles/Doctor/AppointmentList.jsx';
import Availability from './Components/Pages/Profiles/Doctor/Availability.jsx';
import PatientForm from './Components/Pages/Profiles/Doctor/PatientForm.jsx';
import SpecialistList from './Components/Pages/Appointment/SpecialistList.jsx';
import Logout from './Components/Pages/Bars/Logout.jsx';
import ViewAllAppointments from './Components/Pages/Profiles/Doctor/ViewAllAppointments.jsx';
import AllAppointments from './Components/Pages/Appointments/AllAppointments.jsx';
import NursePatientList from './Components/Pages/Profiles/Nurse/NursePatientList.jsx';
import AllEMR from './Components/Pages/EMR/AllEMR.jsx';
import Lab from './Components/Pages/Profiles/Diagnostics/Lab.jsx';
import AllBilling from './Components/Pages/Billing/AllBilling.jsx';
import AllDiagnostics from './Components/Pages/Diagnostics/AllDiagnostics.jsx';
import PdfViewer from './Components/Pages/PdfGenarator/PdfViewer.jsx';
import DiagnosticsAppointment from './Components/Pages/Appointment/DiagnosticAppointment.jsx';
import Profile from './Components/Pages/Profiles/Patient/Profile.jsx';
import PayrollStructure from './Components/Pages/Profiles/SystemAdmin/Payroll/PayrollStructure.jsx';
import CreateHospital from './Components/Pages/Profiles/SuperAdmin/CreateHospital.jsx';
import NotFound from './Components/Pages/PageError/NotFound.jsx';
import DownloadPdfOption from './Components/Pages/Profiles/Doctor/DownloadPdfOption.jsx';
import BillingPdf from './Components/Pages/PdfGenarator/BillingPdf.jsx';
const App = () => {

  return (

    <LoginAuth >
      <UserAuth>
        <AppointmentState>
          <BrowserRouter>
            <Routes>

              <Route index element={<Login />} />
              <Route path='/pdf' element={<PdfViewer />} />
              <Route path='/pdf/billing' element={<BillingPdf />} />
              <Route path='*' element={<NotFound />} />

              <Route path="/user" element={<User />} >
                <Route path="dashboard" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? localStorage.getItem('hospitalId') !== 'NA' ? <Dashboard /> : <Navigate to={"/user/settings/"} /> : <Navigate to='/' />} />

                <Route path="patient" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <Patient /> : <Navigate to='/' />} />

                <Route path="emr/all" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <AllEMR /> : <Navigate to='/' />} />
                <Route path="diagnostics/all" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <AllDiagnostics /> : <Navigate to='/' />} />

                <Route path="patient/patientBasicInfo" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <BasicDetails /> : <Navigate to='/' />} />

                <Route path="patient/profile" element={localStorage.getItem('adminType') === 'Doctor' || localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <PatientProfile /> : <Navigate to='/' />} />
                <Route path="profile/details" element={localStorage.getItem('adminType') === 'Doctor' || localStorage.getItem('adminType') === 'Pa' || localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' || localStorage.getItem('adminType') === 'Nurse' || localStorage.getItem('adminType') === 'Diagnostics' && localStorage.getItem('token') ? <Profile /> : <Navigate to='/' />} />

                <Route path="patientForm" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <PatientForm /> : <Navigate to='/' />} />

                <Route path="patientLogin" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <PatientLogin /> : <Navigate to='/' />} />

                <Route path="patient/appointment" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <AppointmentSelection /> : <Navigate to='/' />} />

                <Route path="patient/appointment/doctorlist" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <DoctorList /> : <Navigate to='/' />} />
                <Route path="patient/appointment/specialist" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <SpecialistList /> : <Navigate to='/' />} />
                <Route path="patient/appointment/bookappointment" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <BookAppointment /> : <Navigate to='/' />} />

                <Route path="patient/disgnostics/booking" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <DiagnosticsAppointment /> : <Navigate to='/' />} />


                <Route path="patient/appointment/diagnosticbooking" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <DiagnosticsBooking /> : <Navigate to='/' />} />
                <Route path="patient/profile/PatientBilling" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <PatientBilling /> : <Navigate to='/' />} />
                <Route path="patient/profile/billing/addbilling" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <AddBilling /> : <Navigate to='/' />} />
                <Route path="billing/all" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <AllBilling /> : <Navigate to='/' />} />
                <Route path="patient/profile/patientEMR" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' || localStorage.getItem('adminType') === 'Doctor' && localStorage.getItem('token') ? <EMR /> : <Navigate to='/' />} />
                <Route path="appointments" element={localStorage.getItem('adminType') === 'Receptionist' || localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? <AllAppointments /> : <Navigate to='/' />} />
                <Route path="logout" element={localStorage.getItem('token') ? < Logout /> : <Navigate to="/" />} />

                <Route path='lab/diagnostics' element={<Lab />} />

                <Route path='settings' element={<SettingOutlet />}>
                  <Route path='' element={localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? localStorage.getItem('hospitalId') === 'NA' ? <WhiteLabel /> : <Navigate to='/user/settings/adminprofile' /> : <Navigate to='/user/settings/adminprofile' />} />

                  <Route path='adminprofile' element={localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? localStorage.getItem('hospitalId') !== 'NA' ? <SystemAdmin /> : <Navigate to={"/user/settings"} /> : <Navigate to='/' />} />

                  <Route path='profilecreation' element={localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? localStorage.getItem('hospitalId') !== 'NA' ? <AdminAccountCreation /> : <Navigate to={"/user/settings"} /> : <Navigate to='/' />} />

                  <Route path='accountslist' element={localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? localStorage.getItem('hospitalId') !== 'NA' ? <AccountLists /> : <Navigate to={"/user/settings"} /> : <Navigate to='/' />} />
                  <Route path='payroll' element={localStorage.getItem('adminType') === 'hospitalAdmin' && localStorage.getItem('token') ? localStorage.getItem('hospitalId') !== 'NA' ? <PayrollStructure /> : <Navigate to={"/user/settings"} /> : <Navigate to='/' />} />

                </Route>

                <Route path='availibility' element={(localStorage.getItem('adminType') === 'Doctor' || localStorage.getItem('adminType') === 'Nurse' || localStorage.getItem('adminType') === 'Pa' || localStorage.getItem('adminType') === 'Diagnostics') && localStorage.getItem('token') ? <Availability /> : <Navigate to='/' />} />
                <Route path='patientlist/patientForm' element={(localStorage.getItem('adminType') === 'Doctor' || localStorage.getItem('adminType') === 'Pa' || localStorage.getItem('adminType') === 'Nurse') && localStorage.getItem('token') ? <PatientForm /> : <Navigate to='/' />} />
                <Route path='appointmentlist' element={(localStorage.getItem('adminType') === 'Doctor' || localStorage.getItem('adminType') === 'Nurse' || localStorage.getItem('adminType') === 'Pa' || localStorage.getItem('adminType') === 'Diagnostics') && localStorage.getItem('token') ? <AppointmentList /> : <Navigate to='/' />} />
                <Route path='nurse/inpatientList' element={(localStorage.getItem('adminType') === 'Nurse') && localStorage.getItem('token') ? <NursePatientList /> : <Navigate to='/' />} />

                <Route path='patientlist' element={(localStorage.getItem('adminType') === 'Doctor' || localStorage.getItem('adminType') === 'Pa') && localStorage.getItem('token') ? <DocProfilePatientList /> : <Navigate to='/' />} />



                <Route path='doctor' element={<Doctor />}>
                  <Route path='calender' element={(localStorage.getItem('adminType') === 'Doctor' || localStorage.getItem('adminType') === 'Pa') && localStorage.getItem('token') ? <DoctorProfile /> : <Navigate to='/' />} />
                  {/* doctor,Pa ,nurse, */}
                  <Route path='allappointments' element={(localStorage.getItem('adminType') === 'Doctor') && localStorage.getItem('token') ? <ViewAllAppointments /> : <Navigate to='/' />} />
                  {/* Download Prescription */}
                  <Route path='download/pdf/option' element={(localStorage.getItem('adminType') === 'Doctor') && localStorage.getItem('token') ? <DownloadPdfOption /> : <Navigate to='/' />} />
                </Route>
              </Route>



              <Route path='/admin' element={<CreateHospital />} />
            </Routes>
          </BrowserRouter>
        </AppointmentState>
      </UserAuth>
    </LoginAuth>
  );
}

export default App;
