/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import "../../CSS/Login/Login.css"
import LoginImg from "../../Assets/Images/LoginImage.jpg"
import AngleRight from "../../Assets/SVG/Login/ArrowRight.svg"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'

const Login = () => {
    const url = process.env.REACT_APP_BACKEND_URL;
    const [LoginCount, SetLoginCount] = useState(0)


    const navigate = useNavigate()
    const [LoginInput, SetLoginInput] = useState({
        Role: "",
        email: "",
        password: ""
    });
    const [LoginChecked, SetLoginChecked] = useState(LoginInput.Role === "pa" ? 5 : 4)
    const LoginInputSet = (e) => {
        SetLoginInput({ ...LoginInput, [e.target.name]: e.target.value })
    }

    const requestPath = () => {
        let path;
        if (LoginInput.Role !== "hospitalAdmin") {
            path = "/" + LoginInput.Role + "/login"
        } else if (LoginInput.Role === "hospitalAdmin") {
            path = "/login/admin/hospitaladmin"
        } else {
            path = ""
            toast.error("Select a valid role");
        }
        return path
    }

    const LoginChecker = (e) => {
        e.preventDefault()
        const requesturl = requestPath();
        if (requesturl !== "") {
            axios.post(url + requesturl, LoginInput).then((res) => {
                const objKey = Object.keys(res.data)
                const objVal = Object.values(res.data)


                for (let i in objKey) {
                    localStorage.setItem(objKey[i], objVal[i])
                }

                RedirectPath(res.data.adminType)
            }).catch((err) => {
                toast.error(err.response.data)
            })
        }
    }


    const StorageSave = (itemname, value) => {
        localStorage.setItem(itemname, value)
    }

    const RedirectPath = (adminType) => {
        switch (adminType) {
            case 'hospitalAdmin':
                if (localStorage.getItem("hospitalId") === "NA") {
                    localStorage.setItem('redirectPath', '/user/settings/');
                } else {
                    localStorage.setItem('redirectPath', '/user/dashboard/');
                }
                break;
            case 'Doctor':
                localStorage.setItem('redirectPath', '/user/doctor/calender');
                break;
            case 'Pa':
                localStorage.setItem('redirectPath', '/user/appointmentlist');

                break;
            case 'Nurse':
                localStorage.setItem('redirectPath', '/user/nurse/inpatientList');
                break;
            case 'Receptionist':
                localStorage.setItem('redirectPath', '/user/dashboard');
                break;
            case 'Diagnostics':
                localStorage.setItem('redirectPath', '/user/lab/diagnostics');
                break;
            default:
                break;
        }
        window.location.href = localStorage.getItem("redirectPath")
    }

    useEffect(() => {
        autoLogin()
        // eslint-disable-next-line
    }, [])


    const autoLogin = () => {
        if (localStorage.getItem("token") && localStorage.getItem("adminType")) {
            RedirectPath(localStorage.getItem("adminType"))
        } else {
            return false
        }
    }
    return (
        <>
            <ToastContainer />

            <form className="loginPage" onSubmit={(e) => LoginChecker(e)}>
                <div className="loginImg" style={{ "--loginImgPath": `url(${LoginImg})` }}>
                    <img src={LoginImg} alt="" />

                </div>
                <div className="loginContent">
                    <h3 className="loginHeading">
                        Alba Hospitals <br />Management System
                    </h3>
                    <div className="loginForm">
                        <div className="formInputs">
                            <select name="Role" className='LoginInputElm' id="logintype" placeholder='Enter Your Role' value={LoginInput.Role} onInput={LoginInputSet} required>
                                <option value="">Select A Role</option>
                                <option value="hospitalAdmin">Hospital Admin</option>
                                <option value="receptionist">Receptionist</option>
                                <option value="doctor">Doctor</option>
                                <option value="nurse">Nurse</option>
                                <option value="pa">Doctor PA</option>
                                <option value="diagnostics">Diagnostics</option>
                            </select>
                            <input type="email" className='LoginInputElm' name="email" id="username" placeholder='Username' value={LoginInput.Username} onInput={LoginInputSet} required />
                            <input type="password" className='LoginInputElm' name="password" id="password" placeholder='Password' value={LoginInput.Password} onInput={LoginInputSet} required />
                        </div>
                        <button type='submit' className="loginBtn">
                            <p>Login to Your Account</p>
                            <img src={AngleRight} alt="" className='displayNone' />
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Login